import React, { useState, useEffect } from "react";
import BInput from "../Inputs/BInput";
import DatePicker from "../Inputs/DatePicker";
import DropInput from "../Inputs/DropInput";
import DInput from "../Inputs/DInput";
import Primary from "../Buttons/Primary";
import Submit from "../Buttons/Submit";
import { Link, useHistory, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { faPlane } from "@fortawesome/free-solid-svg-icons";
import { makeStyles } from "@material-ui/core/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useDispatch, useSelector } from "react-redux";
import {
  addFlightForm,
  selectFlightForm,
} from "../../slices/NavSlice";
import { BottomPattern, GreenLogo } from "../../assets/images";
import Tiketika from "../Axios/AxiosConfig";
import DateTabs from "../Main/DateTabx";
import Regular from "../Text/Regular";
import { airports, popularAirports } from "../fx/airports";
import { Autocomplete, Grid, ListSubheader, TextField } from "@mui/material";
import NumberInput from "../Inputs/NumberInput";

const useStyles = makeStyles((theme) => ({
  whiteText: { 
    color: '#FFF'
  },
  fitWidth: { 
    minWidth: '-webkit-fill-available'
  },
  subHeader: {
    fontWeight: '600!important',
    background: '#2BA147!important',
    color: '#FFF!important',
  },
}));

function FlightForm() {
  // // Once airports are loaded, refresh
  // window.addEventListener('airportsLoaded', function() {
  //   window.location.reload();
  // });

  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const browserLocation = useLocation();

  const flight = useSelector(selectFlightForm);
  const small = useMediaQuery(theme.breakpoints.down("sm"));
  
  // Form variables
  const today = new Date();
  const [tripType, setTripType] = useState(2)
  const [availableOrigins, setAvailableOrigins] = useState(popularAirports)
  const [availableDestinations, setAvailableDestinations] = useState(popularAirports)
  const tripTypeButton = { position: 'absolute', marginTop: '-3rem' }

  const [origin, setOrigin] = useState(flight ? flight.origin : "");
  const [destination, setDestination] = useState(flight ? flight.destination : "");
  const [date, setDate] = useState(flight.date ? flight.date : today.toISOString().slice(0, 10));
  const [returnDate, setReturnDate] = useState(flight.returnDate ? flight.returnDate : null);
  const [generalError, setGeneralError] = useState("");
  const [cabinClass, setCabinClass] = useState(flight.cabinClass ? flight.cabinClass : "Y");
  
  const [adults, setAdults] = useState(flight.adults ? flight.adults : 1);
  const [children, setChildren] = useState(flight.children ? flight.children : 0);
  const [infants, setInfants] = useState(flight.infants ? flight.infants : 0);

  const handleIncrement = (setValue) => {
    setValue((prevValue) => prevValue + 1);
  };

  const handleDecrement = (setValue) => {
    setValue((prevValue) => Math.max(prevValue - 1, 0));
  };

  async function postFlightFormData() {
    try {
                  
      // const response = await Tiketika.post("/search/?type=flight", {
      //   flight,
      // });

      // const content = response.data;
      // console.log(content)
      // if(content) {
      //   //Open the configured URL that is returned in a new tab

      // }

    } catch (error) {
      console.error(error);
    }
  }

  const handleRequest = () => {
    console.log(flight)
    postFlightFormData()

    let baseURL = ''
    // Check if the dates are before today
    let parsedDate = moment(date);
    let departureDate = ''
    if (parsedDate.isBefore(moment(), 'day')) {
      setDate(moment().format('YYYY-MM-DD'));
      departureDate = moment().format("DD-MMM-YYYY");
    }
    else{
      departureDate = parsedDate.format("DD-MMM-YYYY");
    }

    let parsedReturnDate = moment(returnDate);
    let otherDate = ''
    if(parsedReturnDate){
      if (parsedReturnDate.isBefore(moment(), 'day')) {
        setReturnDate(moment().format('YYYY-MM-DD'));
        otherDate = moment().format("DD-MMM-YYYY");
      }
      else{
        otherDate = parsedReturnDate.format("DD-MMM-YYYY");
      }
    }

    let typeKey = 'OW'
    let returnURLSegment = ''
    if(parseInt(tripType) === 2 && otherDate){
      typeKey = 'IRT';
      returnURLSegment = `&dep2=${destination.iata}&ret2=${origin.iata}&dtt2=${otherDate}&cl2=${cabinClass}`
    }

    //PERFORM LOGIC CHECKS AND ASSEMBLE THE LINK
    if(origin.iata && destination.iata){
      baseURL = `https://bookings.tiketika.co.tz/flight/result?dep1=${origin.iata}&ret1=${destination.iata}&dtt1=${departureDate}&cl1=${cabinClass}${returnURLSegment}&triptype=${tripType}&adult=${adults}&child=${children}&infant=${infants}&key=${typeKey}`
      openURLInNewTab(baseURL)
    }
  }

  function openURLInNewTab(url) {
    // Ensure the URL is valid
    const sanitizedURL = encodeURI(url);
  
    // Open the URL in a new tab and switch to it
    const newTab = window.open(sanitizedURL, '_blank', 'noopener,noreferrer');
    if(newTab){ newTab.focus() }
  }
  
  useEffect(() => {
    if (origin && destination && date && tripType && cabinClass && adults && origin !== destination) {
      dispatch(addFlightForm({ origin, destination, date, tripType, returnDate, cabinClass, adults, children, infants }));
    } 
    else if (origin && destination && date && origin === destination) {
      setGeneralError("Please enter correct info!");
    }
  }, [origin, destination, date, tripType, returnDate, cabinClass, adults, children, infants]);


  useEffect(() => {
    setTimeout(() => {
      setGeneralError("");
    }, 5000);
  }, [generalError]);

  //declare lugha
  var lugha = JSON.parse(window.sessionStorage.getItem("lugha"));

  return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        {browserLocation.pathname === "/" ? (
          <div className="flex justify-center align-center pt-5 pb-5 mb-10">
            <div className="flex flex-col px-4 py-20 bg-[#333] bg-opacity-90 w-[80%] align-center">
              <form action="#" class="my-2">
                
                <div style={small ? {} : tripTypeButton}>
                  <FormControl sx={{ m: 1, minWidth: 100 }}>
                    <Select
                      id="tripType"
                      value={tripType}
                      onChange={(e) => setTripType(e.target.value)}
                      style={{ background: '#5F5F5F' }}
                      IconComponent={() => <ArrowDropDownIcon style={{ color: '#FFF' }} />} 
                      inputProps={{ sx: { padding: '6px', background: '#5F5F5F' } }}
                      MenuProps={{
                        PaperProps: {
                          style: {background: '#5F5F5F'},
                        },
                      }}
                    >
                      <MenuItem key={'1'} value={1}><Regular text={'One-way Trip'} /></MenuItem>
                      <MenuItem key={'2'} value={2}><Regular text={'Round Trip'} /></MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div class="flex w-full justify-around flex-col md:flex-row">
                  
                  <div className="w-full mr-2">
                    <Grid container>
                      <Grid item xs="12" sm="3">
                        <FormControl sx={{ m: 1, minWidth: '-webkit-fill-available' }}>
                          <Autocomplete
                            id="origin"
                            value={origin}
                            onChange={(event, newValue) => setOrigin(newValue)}
                            options={availableOrigins}
                            getOptionLabel={(option) => `${option.airport}, ${option.city}, ${option.country}`}
                            className={classes.whiteText}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={lugha.flightOrigin}
                                variant="outlined"
                                style={{ color: '#FFF', border: '1px solid #FFF' }}
                                InputLabelProps={{ style: { color: '#FFFFFF', background: 'rgba(51,51,51,.8)', padding: '0 2px' } }}
                              />
                            )}
                            renderOption={(props, option) => (
                              <MenuItem {...props}>
                                <div>
                                  {option.airport} 
                                  <br /> 
                                  <small>{`${option.city}, ${option.country}`}</small>
                                </div>
                              </MenuItem>
                            )}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs="12" sm="3">
                        <FormControl sx={{ m: 1, minWidth: '-webkit-fill-available' }}>
                          <Autocomplete
                            id="destination"
                            value={destination}
                            onChange={(event, newValue) => setDestination(newValue)}
                            options={availableDestinations}
                            getOptionLabel={(option) => `${option.airport}, ${option.city}, ${option.country}`}
                            className={classes.whiteText}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={lugha.flightDestination}
                                variant="outlined"
                                style={{ color: '#FFF', border: '1px solid #FFF', minWidth: 'fit-content' }}
                                InputLabelProps={{ style: { color: '#FFFFFF', background: 'rgba(51,51,51,.8)', padding: '0 2px' } }}
                              />
                            )}
                            renderOption={(props, option) => (
                              <MenuItem {...props}>
                                <div>
                                  {option.airport} 
                                  <br /> 
                                  <small>{`${option.city}, ${option.country}`}</small>
                                </div>
                              </MenuItem>
                            )}
                            IconComponent={() => <ArrowDropDownIcon style={{ color: '#FFF' }} />}
                          />
                        </FormControl>
                      </Grid>
                      
                      <Grid item xs="12" sm="3">
                        <FormControl sx={{ m: 1, minWidth: '-webkit-fill-available' }}>
                          <DatePicker
                            placeholder={lugha.busDeparture}
                            value={date}
                            // color={'#1F1F1F'}
                            // labelColor={'#5F5F5F'}
                            color={'#FFF'}
                            labelColor={'#FFF'}
                            onchange={(value) =>
                              setDate(moment(value).format("YYYY-MM-DD"))
                            }
                          />
                        </FormControl>
                      </Grid>
                      
                      <Grid item xs="12" sm="3">
                        <FormControl sx={{ m: 1, minWidth: '-webkit-fill-available', display: parseInt(tripType) === 2 ? 'block' : 'none' }}>
                          <DatePicker
                            placeholder={lugha.busReturn}
                            value={returnDate}
                            color={'#FFF'}
                            labelColor={'#FFF'}
                            onchange={(value) =>
                              setReturnDate(moment(value).format("YYYY-MM-DD"))
                            }
                          />
                        </FormControl>
                                          
                        <FormControl sx={{ m: 1, minWidth: '-webkit-fill-available' }}>
                          <InputLabel htmlFor="grouped-select" style={{ color: '#FFF', background: 'rgba(51,51,51,.8)' }}>{lugha.passengersAndClasses}</InputLabel>
                          <Select 
                            id="grouped-select" 
                            style={{ color: '#FFF', border: '1px solid #FFF', minWidth: '-webkit-fill-available' }}
                            onChange={(e, value) => setCabinClass(value.props.value)}
                            defaultValue={cabinClass}
                            value={cabinClass}
                            label={lugha.passengersAndClasses}
                          >
                            <ListSubheader className={classes.subHeader}>{lugha.flightClasses}</ListSubheader>
                              <MenuItem value="Y"> Economy</MenuItem>
                              <MenuItem value="W"> Premium Economy</MenuItem>
                              <MenuItem value="C"> Business Class</MenuItem>
                              <MenuItem value="F"> First Class</MenuItem>
                            <ListSubheader className={classes.subHeader}>{lugha.flightPassengers}</ListSubheader>
                            <MenuItem>                
                              <NumberInput
                                label="Adults (12+ Years)"
                                value={adults}
                                onIncrement={() => handleIncrement(setAdults)}
                                onDecrement={() => handleDecrement(setAdults)}
                              />
                            </MenuItem>
                            <MenuItem>                
                              <NumberInput
                                label="Children (2 - 11 Years)"
                                value={children}
                                onIncrement={() => handleIncrement(setChildren)}
                                onDecrement={() => handleDecrement(setChildren)}
                              />
                            </MenuItem>
                            <MenuItem>                
                              <NumberInput
                                label="Infants (Under 2 Years)"
                                value={infants}
                                onIncrement={() => handleIncrement(setInfants)}
                                onDecrement={() => handleDecrement(setInfants)}
                              />
                            </MenuItem>
                          </Select>
                        </FormControl>
                        <div className={classes.whiteText}>
                          {parseInt(adults) > 0 && 
                            <small><strong>Adults:</strong> {adults} </small>
                          }
                          {parseInt(children) > 0 && 
                            <small>&emsp; <strong>Child:</strong> {children} </small>
                          }
                          {parseInt(infants) > 0 && 
                            <small>&emsp; <strong>Infant:</strong> {infants} </small>
                          }
                        </div>
                        
                        <FormControl sx={{ m: 1, minWidth: '-webkit-fill-available' }}>
                          {origin && destination &&
                            origin !== destination && (
                              <Submit
                                text={lugha.search}
                                iconName={faPlane}
                                action={() => handleRequest()}
                              />
                            )}
                        </FormControl>
                      </Grid>
                    </Grid>
                    
                  </div>
                </div>
              </form>
            </div>
          </div>
        ) 
        : 
        // ####### THIS SECTION IS IRRELEVANT DUE TO THE REDIRECTION TO FLIGHTS #######
        (
          <div class="relative flex flex-col items-center justify-center w-full bg-[#333] pt-16 pb-8">
            <form action="#" class="my-2">
              
              <div style={small ? {} : tripTypeButton}>
                <FormControl sx={{ m: 1, minWidth: '-webkit-fill-available' }}>
                  <Select
                    id="tripType"
                    value={tripType}
                    onChange={(e) => setTripType(e.target.value)}
                    style={{ background: '#5F5F5F' }}
                    IconComponent={() => <ArrowDropDownIcon style={{ color: '#FFF' }} />} 
                    inputProps={{ sx: { padding: '6px', background: '#5F5F5F' } }}
                    MenuProps={{
                      PaperProps: {
                        style: {background: '#5F5F5F'},
                      },
                    }}
                  >
                    <MenuItem key={'1'} value={'1'}><Regular text={'One-way Trip'} /></MenuItem>
                    <MenuItem key={'2'} value={'2'}><Regular text={'Round Trip'} /></MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div class="flex w-full justify-around flex-col md:flex-row">
                
                <div className="w-full mr-2">
                  
                  <FormControl sx={{ m: 1, minWidth: '-webkit-fill-available' }}>
                    <Autocomplete
                      id="origin"
                      value={origin}
                      onChange={(event, newValue) => setOrigin(newValue)}
                      options={availableOrigins}
                      getOptionLabel={(option) => option.airport}
                      className={classes.whiteText}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={lugha.flightOrigin}
                          variant="outlined"
                          style={{ color: '#FFF', border: '1px solid #FFF' }}
                          InputLabelProps={{ style: { color: '#FFFFFF', background: 'rgba(51,51,51,.8)', padding: '0 2px' } }}
                        />
                      )}
                      renderOption={(props, option) => (
                        <MenuItem {...props}>
                          <div>{option.airport}</div>
                        </MenuItem>
                      )}
                      IconComponent={() => <ArrowDropDownIcon style={{ color: '#FFF' }} />}
                    />
                  </FormControl>

                  <FormControl sx={{ m: 1, minWidth: '-webkit-fill-available' }}>
                    <Autocomplete
                      id="destination"
                      value={destination}
                      onChange={(event, newValue) => setDestination(newValue)}
                      options={availableDestinations}
                      getOptionLabel={(option) => option.airport}
                      className={classes.whiteText}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={lugha.flightDestination}
                          variant="outlined"
                          style={{ color: '#FFF', border: '1px solid #FFF' }}
                          InputLabelProps={{ style: { color: '#FFFFFF', background: 'rgba(51,51,51,.8)', padding: '0 2px' } }}
                        />
                      )}
                      renderOption={(props, option) => (
                        <MenuItem {...props}>
                          <div>{option.airport}</div>
                        </MenuItem>
                      )}
                      IconComponent={() => <ArrowDropDownIcon style={{ color: '#FFF' }} />}
                    />
                  </FormControl>

                  <DatePicker
                    placeholder={lugha.busDeparture}
                    value={date}
                    // color={'#1F1F1F'}
                    // labelColor={'#5F5F5F'}
                    color={'#FFF'}
                    labelColor={'#FFF'}
                    onchange={(value) =>
                      setDate(moment(value).format("YYYY-MM-DD"))
                    }
                  />
                  {tripType === 'Round' && 
                    <DatePicker
                      placeholder={lugha.busReturn}
                      value={returnDate}
                      color={'#FFF'}
                      labelColor={'#FFF'}
                      onchange={(value) =>
                        setReturnDate(moment(value).format("YYYY-MM-DD"))
                      }
                    />
                  }
                  <div style={{ display: 'inline-flex' }}>
                    <Submit
                      text={lugha.search}
                      iconName={faPlane}
                      pathTo="/buses"
                      action={() => postFlightFormData()}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        )
        // ####### THIS SECTION IS IRRELEVANT DUE TO THE REDIRECTION TO FLIGHTS #######
        }
      </LocalizationProvider>
  );
}

export default FlightForm;
