import React, {useState, useEffect} from 'react'
import Bold from '../Text/Bold';
import Secondary from '../Buttons/Secondary';
import Primary from '../Buttons/Primary';
import Semi from '../Text/Semi';
import { Link } from 'react-router-dom';
import DropInput from '../Inputs/DropInput';

import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { selectTransitRestaurant, setTransitRestaurant } from '../../slices/NavSlice';
import { NumberFormatter } from '../fx/fx';

const useStyles = makeStyles((theme) => ({

    sidePanel: {
        position: 'fixed',
        bottom: 0,
        right: 0,
        backgroundColor: '#fff',
        width: '50%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
        zIndex: 5,
        boxShadow: "0px 1px 9px rgba(0, 0, 0, 0.07)",
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        },

    },

    menu : {
        display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '30vw', marginBottom: 5, 
        [theme.breakpoints.down('sm')]: {
            width: '70vw',
        },
    },

}));

function RestaurantSidePanel({ heading, text, action, items, closeAction }) {
    const dispatch = useDispatch();
    const classes = useStyles();
    //declare lugha
    const lugha = JSON.parse(window.sessionStorage.getItem("lugha"));
    const transitRestaurant = useSelector(selectTransitRestaurant)
    const [selectedMeals, setSelectedMeals] = useState([]); // Initialize selectedRooms
    
    const handleMealCountChange = (item, count) => {
        if (count === 0) {
        // Remove the room object if count is 0
        setSelectedMeals((prevSelectedMeals) =>
            prevSelectedMeals.filter((menu) => menu.menuItemID !== item.menuItemID)
        );
        } 
        else {
            // Update the selectedMeals array with the new count
            setSelectedMeals((prevSelectedMeals) => {
                const updatedSelectedMeals = [...prevSelectedMeals];
                const mealIndex = updatedSelectedMeals.findIndex(
                    (meal) => meal.menuItemID === item.menuItemID
                );
        
                if (mealIndex !== -1) {
                    updatedSelectedMeals[mealIndex] = {
                        ...updatedSelectedMeals[mealIndex],
                        count,
                    };
                } 
                else {
                    updatedSelectedMeals.push({
                        count,
                        menuID: item.menuID,
                        menuItemID: item.menuItemID,
                        price: item.price,
                        restaurantID: item.restaurantID,
                    });
                }
        
                return updatedSelectedMeals;
            });
        }
    };

    useEffect(() => {
        dispatch(setTransitRestaurant(selectedMeals))
    }, [selectedMeals])
    
  
    // Calculate the total price
    const totalPrice = selectedMeals.reduce((total, meal) => total + meal.count * parseInt(meal.price), 0);
    
    console.log(items)

    return (
        <div className={classes.sidePanel}>
            {/* Rouded button with gray background */}
            <div onClick={closeAction} style={{ cursor: 'pointer', position: 'absolute', zIndex: 10, right: '3%', top: '8%', borderRadius: 50, backgroundColor: '#f5f5f5', width: 50, height: 50, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Semi text='X' />
            </div>

            <div>
                <Semi text={lugha.add} /><br></br>
                <Bold text={heading} fontSize={20} color={'#2BA147'} /><br></br>
                {/* <Semi text={'to your booking'} /> */}
            </div>

            <div>
                <Bold text={lugha.total} fontSize={22} /><br></br>
                <Semi text={'Tsh. ' + NumberFormatter(totalPrice)} fontSize={20} />
            </div>

            <div>

                {
                    items.map((item, index) => {
                        return (
                            <>
                                <div key={index} className={classes.menu}>
                                    <div>
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'space-between', width: '20vw', }}>
                                            <Bold text={item.menuItemName} fontSize={15} />
                                            <Semi text={item.menuItemDescription} fontSize={12} />
                                        </div>
                                    </div>
                                    <div><Semi text={'Tsh. ' + NumberFormatter(item.price)} fontSize={14} /></div>
                                    {/* an input for the number of menu items count */}
                                    <div>
                                        <input
                                            id={index}
                                            onChange={(e) => {
                                                handleMealCountChange(item, parseInt(e.target.value));
                                            }}
                                            type='number'
                                            style={{ width: 50, height: 30, borderRadius: 5, border: '1px solid #aaa ', outline: 'none', textAlign: 'center', fontSize: 14, }}
                                        />
                                    </div>

                                </div>
                                {/* separator div */}
                                <div style={{ height: 1, width: '100%', backgroundColor: '#aaa', margin: '0 10px' }}></div>
                            </>
                            
                        )
                    })
                }

            </div>

            <div onClick={closeAction} style={{ borderRadius: 50, backgroundColor: '#f5f5f5', width: '100%', }}>
                    <Primary text={text} />
                {/* <Link to={action}>
                </Link> */}
            </div>
        </div>
    )
}

export default RestaurantSidePanel