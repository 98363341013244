/* eslint-disable jsx-a11y/img-redundant-alt */
// a component to render a bus listing in the main page
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Primary from "../Buttons/Primary";
import { IconButton } from "@material-ui/core";
import Bold from "../Text/Bold";
import Semi from "../Text/Semi";
import Light from "../Text/Light";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStar,
  faLocation,
  faClock,
  faComment,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import Submit from "../Buttons/Submit";

import { useDispatch, useSelector } from "react-redux";
import {
  selectChosenBus,
  setChosenBus,
  selectBusForm,
  selectAvailableEntertainment,
} from "../../slices/NavSlice";
import { NumberFormatter } from "../fx/fx";
import { Grid, Paper } from "@mui/material";
import Accordion from '@mui/joy/Accordion';
import AccordionDetails from '@mui/joy/AccordionDetails';
import AccordionGroup from '@mui/joy/AccordionGroup';
import AccordionSummary from '@mui/joy/AccordionSummary';
import Regular from "../Text/Regular";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "80%",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 20,
    boxShadow: "0px 1px 9px rgba(0, 0, 0, 0.07)",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
    margin: 10,
    padding: 15,
  },
  details: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: 20,
    [theme.breakpoints.down("sm")]: {
      padding: 10,
    },
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    height: 150,
    width: 200,
    paddingRight: 10,
    borderRadius: 10,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: 0,
      height: 150,
      borderRadius: 10,
    },
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },

  priceDetails: {
    padding: 20,
    backgroundColor: "#f5f5f5",
    textAlign: "center",
    width: "20%",
    borderRadius: 30,
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      margin: 10,
    },
  },

  bookNow: {
    paddingRight: 50,
    [theme.breakpoints.down("sm")]: {
      paddingRight: 0,
    },
  },
}));

function EntertainmentFilter() {
  //declare lugha
  const lugha = JSON.parse(window.sessionStorage.getItem("lugha"));

  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const availableEntertainment = useSelector(selectAvailableEntertainment);
  // console.log(availableEntertainment)
  const checkBoxStyles = {
    color: '#2BA147',
    '&.Mui-checked': {
      color: '#2BA147',
    },
  }
  // const [date, setDate] = useState();
  // const [plate, setPlate] = useState();
  // const [busId, setBusId] = useState();

  return (
    <div>
      {availableEntertainment && (
        
        <Paper>
          <br />
          <Grid container>
            <Grid item xs={1} sm={1} />
            <Grid item xs={5} sm={5} style={{textAlign: 'left'}}>
              <Bold text={'Filter'} />
            </Grid>
            <Grid item xs={5} sm={5} style={{textAlign: 'right'}}>
              <Light text={'Reset'} fontSize={'0.8rem'} color={'#2BA147'} style={{ cursor: 'pointer' }}  />
            </Grid>
            <Grid item xs={1} sm={1} />
          </Grid>

          <AccordionGroup>
            <Accordion expanded>
              <AccordionSummary><Semi text={"Place"} color={'#000'} /></AccordionSummary>
              <AccordionDetails>
                <FormGroup>
                  {/* <FormControlLabel control={<Checkbox sx={checkBoxStyles} />} label={<Regular text={"Arcade"} color={'#000'} />} /> */}
                  <FormControlLabel control={<Checkbox sx={checkBoxStyles} />} label={<Regular text={"Bar/ Lounge"} color={'#000'} />} />
                  <FormControlLabel control={<Checkbox sx={checkBoxStyles} />} label={<Regular text={"Club"} color={'#000'} />} />
                  <FormControlLabel control={<Checkbox sx={checkBoxStyles} />} label={<Regular text={"Library"} color={'#000'} />} />
                  <FormControlLabel control={<Checkbox sx={checkBoxStyles} />} label={<Regular text={"Restaurant"} color={'#000'} />} />
                </FormGroup>
              </AccordionDetails>
            </Accordion>
          </AccordionGroup>
        </Paper>
      )}
    </div>
  );
}

export default EntertainmentFilter;
