import React, { useEffect, useState } from "react";
import AxiosInstance from "./Axios/AxiosConfig";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Primary from "./Buttons/Primary";
import Button from "@mui/material/Button";
import { makeStyles } from "@material-ui/core/styles";
import QRCode from "react-qr-code";
import { NumberFormatter } from "./fx/fx";
import { useParams } from "react-router-dom";
import moment from "moment";
import Bold from "./Text/Bold";
import { Divider } from "@mui/material";
import FInput from "./Inputs/FInput";



const useStyles = makeStyles((theme) => ({
  basic: {
    padding: 20,
    borderRadius: 15,
    width: "85%",
    border: "none",
    outline: "none",
    margin: 5,
    fontFamily: "Euclid-Bold",
    boxShadow: "-1px 2px 2px rgba(0, 0, 0, 0.57)",
    fontSize: 20,
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "55vw",
    },
  },
  box: {
    width: 400,
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
    },
  },
  openedBusContainer: {
    position: "relative",
    marginTop: "15px", 
    left: "9%",
    [theme.breakpoints.down("sm")]: {
      left: 0,
    },
  },
  hiddenSM: {
    display: "block",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FAFAFA",
    boxShadow: 24,
    padding: '2rem',
    borderRadius: 5,
    [theme.breakpoints.down("sm")]: {
      width: "80vw",
    },
  },
}));


const Tickets = () => {
  
  const classes = useStyles();
  const lugha = JSON.parse(window.sessionStorage.getItem("lugha"))

  const { reference: urlReference } = useParams();
  const [support, setSupport] = useState(false);
  const [reference, setReference] = useState();
  const [result, setResult] = useState('');
  const [booking, setBooking] = useState('');
  
  const handleSupport = () => setSupport(true);
  const handleSupportClose = () => setSupport(false);

  const handleReference = (event) => {
    const { value } = event.target;
    setReference(value);
    // if(value){
    // }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    getReference();
    handleSupportClose();
  };

  const Flight = [
    {
      logo: "tahmeed.webp",
      origin: "Dar es Salaam",
      pickUp: "Magufuli Bus Terminal",
      departureDate: "Dec 12, 2023",
      departureTime: "06:00",
      arrivalDate: "Dec 12, 2023",
      arrivalTime: "15:30",
      tripDuration: "9h 5 min",
      destination: "Arusha Stendi",
      dropOff: "Mwanga Mjini",
      reference: "928000000",
      fare: "30000",
      cabin: "Economy",
      seat: "11A",
      style: { height: "51px", margin: "22px 12px", },
      label: "rgb(19,96,31)",
      status: "0",
    },
    {
      logo: "abood.webp",
      origin: "Tanga",
      pickUp: "Tanga Stendi",
      departureDate: "Dec 14, 2023",
      departureTime: "09:00",
      arrivalDate: "Dec 14, 2023",
      arrivalTime: "14:45",
      tripDuration: "5h 45 min",
      destination: "Dar es Salaam",
      dropOff: "Magufuli Bus Terminal",
      reference: "928010101",
      fare: "32000",
      cabin: "Luxury",
      seat: "6B",
      style: {
        height: "51px",
        margin: "22px 12px",
      },
      label: "rgb(19,96,31)",
      status: "1",
    },
  ];

  const Cell = (props) => {
    const { index, value } = props
    const { origin, pickUp, destination, dropOff, departureDate, departureTime, 
            arrivalDate, arrivalTime, tripDuration, reference, fare, cabin, seat, logo, status } = value
    const [active, handleActive] = useState(false);
    
    return (
      <div
        className="relative m-5 h-[120px] w-[340px] md:w-[840px] cursor-pointer rounded-md "
        style={{
          height: active ? `360px` : `120px`,
          transition: "0.9s",
        }}
        onClick={() => {
          handleActive(!active);
        }}
      >
        <div id="firstDisplay">
          <div id="busDetail">
            <div id="detailLabel" style={{ fontWeight: "bold", color: "rgb(19,96,31)" }}>
              {lugha.from}
            </div>
              {origin}
            <div id="detailLabel" className={classes.hiddenSM}>{pickUp}</div>
          </div>

          <div id="busDetail" className={classes.openedBusContainer}>
            <div id="animContainer">
              <div id="anim">
                <div id="circle" />
                <div id="circle" />
                <div id="circle" />
              </div>
            </div>
            <div id="animContainer" style={{ left: "80px" }}>
              <div id="anim">
                <div id="circle" />
                <div id="circle" />
                <div id="circle" />
              </div>
            </div>
            <img
              style={{ position: "absolute", width: "40px", left: "38px" }}
              src="https://34.199.255.58/assets/images/951578.png"
              alt="BUS"
            />
          </div>


          <div id="busDetail">
            <div id="detailLabel" style={{ fontWeight: "bold", color: "rgb(19,96,31)" }}>
              {lugha.to}
            </div>
            {destination}
            <div id="detailLabel" className={classes.hiddenSM}>{dropOff}</div>
          </div>
        </div>
        <div
          id="first"
          style={{
            transform: active
              ? `rotate3d(1, 0, 0, -180deg)`
              : `rotate3d(1, 0, 0, 0deg)`,
            transitionDelay: active ? "0s" : "0.3s",
          }}
        >
          <div id="firstTop" className=" w-[340px] md:w-[840px]">
            <img style={{ height: "51px", margin: "22px 12px", }} src={'https://34.199.255.58/img/logos/' + logo} alt="" />
            <div id="timecontainer">
              <div id="detailDate">
                {origin}
                <div id="detailTime">{departureTime.slice(0,5)}</div>
                {departureDate}
              </div>
              <img
                style={{width: "40px", height: "26px", margin: "22px 16px 0 16px",}}
                src="https://34.199.255.58/assets/images/951578.png"
                alt="BUS"
              />
              <div id="detailDate">
                {destination}
                <div id="detailTime">{arrivalTime.slice(0,5)}</div>
                {arrivalDate}
              </div>
            </div>
          </div>
          <div id="firstBehind" className="w-[340px] md:w-[840px]">
            <div id="firstBehindDisplay">
              <div id="firstBehindRow">
                <div id="detail">
                  {departureTime.slice(0,5)} - {arrivalTime.slice(0,5)}
                  <div id="detailLabel">Bus Time</div>
                </div>
                <div id="detail">
                  Tsh.  {NumberFormatter(fare)}
                  <div id="detailLabel">{lugha.fare}</div>
                </div>
              </div>
              <div id="firstBehindRow">
                <div id="detail">
                  {tripDuration}
                  <div id="detailLabel">{lugha.time}</div>
                </div>
                <div id="detail">
                  {cabin}
                  <div id="detailLabel">{lugha.class}</div>
                </div>
              </div>
              <div id="firstBehindRow">
                <div id="detail">
                  {departureTime}
                  <div id="detailLabel">{lugha.boarding}</div>
                </div>
                <div id="detail">
                  {seat}
                  <div id="detailLabel">{lugha.seats}</div>
                </div>
              </div>
            </div>
            <div
              id="second"
              className="w-[340px] md:w-[840px] r-2"
              style={{
                transform: active
                  ? `rotate3d(1, 0, 0, -180deg)`
                  : `rotate3d(1, 0, 0, 0deg)`,
                transitionDelay: active ? "0.2s" : "0.2s",
              }}
            >
              <div id="secondTop" className="w-[340px] md:w-[840px]" />
              <div
                id="secondBehind"
                className="w-[340px] md:w-[840px] border-2"
              >
                <div id="secondBehindDisplay" className="py-3 px-5 flex flex-col items-center">

                  <div id="price" className="items-center">
                    <QRCode
                      title="Scan this to see your ticket status"
                      value={'https://ticket.34.199.255.58/?id=' + reference}
                      bgColor={"#FFF"}
                      fgColor={"#13601F"}
                      size={90}
                    />
                    <div id="detailLabel">{reference}</div>
                    
                    <div
                      className="my-3 rounded border px-[80px] py-[4px] text-lg font-bold"
                      style={{
                        color: parseInt(status) === 1 ? '#2BA147' : '#B60B0B',
                        border: parseInt(status) === 1 ? '1px solid #2BA147' : '1px solid #B60B0B',
                      }}
                    >
                      {parseInt(status) === 1 ? "PAID" : 'UNPAID'}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const getReference = () => {

    AxiosInstance.post('/reference/', { reference, headers:  { "Access-Control-Allow-Origin": "*" } }) //, data
    .then(res => {
      if(res.data.booking){
        let booking = res.data.booking
            
        let date = moment(booking.departureDate).format('MMM Do, YYYY');
        let data = {
          ...booking,
          departureDate: date,
          arrivalDate: date,
          fare: parseInt(booking.farePerSeat) * booking.seat.split(',').length,
        };

        setBooking(data);
        console.log(data)
      }
      else{
        //setGeneralError(res.data.message)
      }
    })
    .catch(err => {
      console.log(err);
    })
  }


  useEffect(() => {
    setReference(urlReference);
  }, [urlReference]);

  useEffect(() => {
    if(!booking){
      getReference()
    }

  }, [reference])
  

  return (
    <div className="flex justify-center items-center flex-col">
      <Button onClick={handleSupport}>
        <Primary text={lugha.ticketSearch} />
      </Button>

      {booking && 
        <Cell value={booking} />
      }

      <Divider style={{ margin: '1rem 0', borderBottom: '1px solid #333' }} />

      <Bold text={'Sample Tickets'} />

      {Flight.map((value, i) => (
        <Cell key={i} index={i} value={value} />
      ))}
      
      {/* Support */}
      <div id="basicModal">
        <Modal
          open={support}
          onClose={handleSupportClose}
          aria-labelledby="Ticket ID"
          aria-describedby="Ingiza tiketi namba"
        >
          <Box className={classes.modal}>
            <form onSubmit={handleSubmit} style={{ flexDirection: "row" }}>
              <Bold text={lugha.ticketMessage} />

              <FInput
                type={"text"}
                name="reference"
                width={'100%'}
                onChange={handleReference}
                placeholder={lugha.reference}
                required
              />

              <div>
                <Button type="submit">
                  <Primary text={lugha.search} />
                </Button>
              </div>
            </form>
            {reference ? ('Submitted Ticket ID: ' + reference) : ''}
            {result ? result : ''}
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default Tickets;
