// a component to render a bus listing in the main page
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from "@material-ui/core/styles";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Primary from "../Buttons/Primary";
import Secondary from "../Buttons/Secondary";
import Bold from "../Text/Bold";
import Semi from "../Text/Semi";
import Light from "../Text/Light";
import Modal from "@mui/material/Modal";
import ActionFooter from "./ActionFooter";
import Checkout from "./Checkout";

import { useDispatch, useSelector } from "react-redux";
import {
  setHomeBg,
  selectCheckoutDetails,
  setCheckoutDetails,
  selectAvailableEvents,
  selectEventForm,
} from "../../slices/NavSlice";
import { NumberFormatter } from "../fx/fx";
import moment from "moment/moment";
import ClockIcon from '@mui/icons-material/AccessTime';
import MapIcon from '@mui/icons-material/Map';
import Submit from "../Buttons/Submit";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "60%",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 20,
    boxShadow: "0px 1px 9px rgba(0, 0, 0, 0.07)",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "80%",
    },
    margin: 15,
    padding: 15,
  },
  rootMenu: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    width: "30%",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 20,
    flexDirection: "column",
    boxShadow: "0px 1px 9px rgba(0, 0, 0, 0.07)",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "80%",
      transform: "translate(-53%, -50%)",
    },
    padding: 15,
  },
  details: {
    width: "80%",
    margin: 10,
    padding: 5,
    textAlign: 'left',
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: 0,
    },
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    height: 150,
    width: 200,
    paddingRight: 10,
    borderRadius: 10,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: 0,
      height: 150,
      borderRadius: 10,
    },
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
}));

function EventListing() {
  //declare lugha
  const lugha = JSON.parse(window.sessionStorage.getItem("lugha"));
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const availableEvents = useSelector(selectAvailableEvents);

  const [showTiers, setShowTiers] = useState();
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const eventGlobalState = useSelector(selectEventForm);
  const checkoutDetails = useSelector(selectCheckoutDetails);

  // state to store total menu price for items in a particular event
  const [totalEventPrice, setTotalEventPrice] = useState(0);
  const [itemsCount, setItemsCount] = useState([]);

  // checkout toggle
  const [checkoutForm, setCheckoutForm] = useState(false);

  // function to send checkout details and rest form details to api
  const sendCheckoutDetails = (fullName, phoneNumber, email) => {
    const eventDetails = eventGlobalState;
    const checkOutItems = itemsCount;
    const headers = {
      "Access-Control-Allow-Origin": "*",
    };
  }

  // useEffect total price for all items in itemsCount
  useEffect(() => {
    let total = 0;
    itemsCount.forEach((item) => {
      console.log(item)
      total += parseInt(item.tier.price) * parseInt(item.quantity);
    });
    setTotalEventPrice(total);
  }, [itemsCount]);

  const CartCount = ({ tier, eventID }) => {
    var count = 0;
    itemsCount.map((item) => {
      if (item.tier.tierID === tier.tierID && item.tier.eventID === eventID) {
        count++;
        return count;
      }
    });

    return <Semi text={count} />;
  };

  // delete item from itemsCount array
  const deleteItem = (tier, eventID) => {
    const newItemsCount = itemsCount.filter((item) => {
      return item.tier.tierID == tier.tierID && item.eventID == eventID;
    });

    const index = itemsCount.indexOf(newItemsCount[0]);
    itemsCount.splice(index, 1);
    setItemsCount([...itemsCount]);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 25,
        }}
      >
        <Grid container spacing={2}>
          {availableEvents && availableEvents.length ?
            availableEvents.map((event, id) => {
              const {eventID, event: eventName, tiers, squarePath, region, start} = event
              var date = moment(start, 'DD/MM/YYYY').format('MMMM Do, YYYY')

              var description = event.description
              if(lugha.lugha === 'swahili'){
                description = event.maelezo
              }

              return (
                <Grid item xs={12} sm={6}>
                  <>
                    <Card >
                      <CardMedia
                        sx={{ height: 140 }}
                        image={"https://tiketika.online/img/events/" + squarePath}
                        title="Event Poster"
                      />
                      <CardContent>
                        <Bold text={eventName} fontSize={'1.2rem'} />

                        <div className={classes.details}>
                          <MapIcon style={{color: '#2BA147'}} /><Light text={region} fontSize={'.8rem'} /> <br />
                          <ClockIcon style={{color: '#2BA147'}} /><Light text={date} fontSize={'.8rem'} /> <br />
                        </div>

                        <Typography variant="body2">
                          {description}
                        </Typography>
                      </CardContent>
                      <CardActions style={{ justifyContent: "right", }}>
                          <Submit
                            text={<><Semi text={lugha.viewTickets} /></>} //<ListIcon /> 
                            size={'small'}
                            inverse
                            id={eventID}
                            action={() => {
                              // setShowPackages(eventID);
                            }}
                            pathTo={"/event-details/" + eventID}
                          />
                      </CardActions>
                    </Card>

                    {parseInt(showTiers) === parseInt(eventID) && (
                      <Modal
                        open={true}
                        closeAfterTransition={true}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Card
                          key={eventID + "tier"}
                          className={classes.rootMenu}
                          id={eventID + "tier"}
                        >
                          {/* Total menu price */}
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                              alignItems: "center",
                              padding: 10,
                            }}
                          >
                            <Bold text={lugha.total} fontSize={'1.2rem'} />
                            <Semi text={"Tsh. " + NumberFormatter(totalEventPrice)} />
                          </div>
                          {/* List All the menu items */}
                          {tiers.map((tier, i) => {
                            var price = tier.price;
                            // var description = tier.description;
                            // var maelezo = tier.maelezo;

                            return (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    paddingLeft: 40,
                                    paddingRight: 70,
                                    width: "100%",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                        alignItems: "flex-start",
                                        marginLeft: 10,
                                      }}
                                    >
                                      <Bold text={tier.tier} />
                                      <Semi text={lugha.price +': Tsh. '+ NumberFormatter(price)} />
                                    </div>
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "flex-end",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Primary
                                      text={"-"}
                                      action={() => {
                                        deleteItem(tier, eventID);
                                      }}
                                    />

                                    <CartCount tier={tier} eventID={eventID} />

                                    <Primary
                                      text={"+"}
                                      action={() => {
                                        // add item to items array

                                        var newItem = {
                                          tier: tier,
                                          quantity: 1,
                                          // eventID: eventID,
                                          // price: price,
                                        };

                                        setItemsCount([...itemsCount, newItem]);
                                      }}
                                    />
                                  </div>
                                </div>
                                <div style={styles.separator}></div>
                              </>
                            );
                          })}
                          <Secondary
                            text="Close"
                            action={() => {
                              setShowTiers(0);
                            }}
                          />
                        </Card>
                      </Modal>
                    )}
                  </>
                </Grid>
              );
            })
          : 
            (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  height: 300,
                }}
              >
                <Bold text="No Events Available" fontSize={20} />
              </div>
            )
          }
        </Grid>
      </div>

      {itemsCount.length > 0 && (
        <ActionFooter
          text={lugha.checkout}
          clickAction={() => setCheckoutForm(true)}
        />
      )}

      {checkoutForm && (
        <Checkout
          total={totalEventPrice}
          text={lugha.payNow}
          type='event'
          currency='Tsh'
          closeAction={() => {
            // dispatch(setCheckoutDetails([]));
            setCheckoutForm(false);
          }}
          items={itemsCount}
          details={[
            {
              type: "text",
              placeholder: "Full Name",
              name: "fullname",
            },
            {
              type: "phone",
              placeholder: "Phone Number",
              name: "phone",
            },
            {
              type: "email",
              placeholder: "Email",
              name: "email",
            },
          ]}
          action={(e) => {
            e.preventDefault();

            var fullName;
            var phoneNumber;
            var email;

            for (let i = 0; i < checkoutDetails.length; i++) {
              fullName = checkoutDetails[i].hasOwnProperty("fullname")
                ? checkoutDetails[i].fullname
                : "";
              phoneNumber = checkoutDetails[i].hasOwnProperty("phone")
                ? checkoutDetails[i].phone
                : "";
              email = checkoutDetails[i].hasOwnProperty("email")
                ? checkoutDetails[i].email
                : "";
            }

            sendCheckoutDetails(fullName, phoneNumber, email);
          }}
        />
      )}
    </>
  );
}

export default EventListing;

const styles = {
  card: {
    width: 300,
    margin: 80,
    boxShadow: "0px 1px 9px rgba(0, 0, 0, 0.07)",
    display: "flex",
    justifyContent: "center",
  },
  eventDetails: {
    textAlign: "left",
    alignItems: "left",
    flex: 2,
  },
  separator: {
    width: "100%",
    height: 1,
    backgroundColor: "#e0e0e0",
    margin: 10,
  },
  priceDetails: {
    padding: 20,
    backgroundColor: "#f5f5f5",
    textAlign: "center",
    width: "20%",
    borderRadius: 30,
  },

  anem: {
    display: "flex",
    alignItems: "center",
    margin: 5,
  },

  price: {
    fontSize: 30,
    fontWeight: "bold",
    color: "#2BA147",
  },
};
