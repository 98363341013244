import React, { useState, useEffect } from "react";
import Nav from "../components/Main/Nav";
import { useParams } from "react-router-dom";
import EventInfo from "../components/Main/EventInfo";
import FooterNav from "../components/Main/FooterNav";

function EventView() {

  const { eventID } = useParams()
  return (
    <div style={styles.container}>
      {/* Navigation */}
      <Nav colored={true} />
      <EventInfo id={eventID} />  
      <FooterNav />
    </div>
  );
}

export default EventView;

const styles = {
  container: {
    // backgroundColor: 'red',
  },

  tabs: {
    position: "absolute",
    top: 85,
  },
};
