import React from 'react'

function Bold({text, color, fontSize, padding, textShadow, onclick, align}) {
  return (
    <span 
        style={{
            padding: padding ? padding : '5 0',
            margin: 2,
            fontFamily: "Euclid-Bold",
            fontSize: fontSize ? fontSize : 15, 
            color: color,
            textShadow: textShadow ? textShadow : '',
            cursor: 'pointer',
            textAlign: align ? align : 'center',
        }}
        className="tracking-wider"
        onClick={onclick}
    > 
        {text} 
    </span>
  )
}

export default Bold