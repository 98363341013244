import React from "react";
import Bold from "./../Text/Bold";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import IconButton from "@mui/material/IconButton";
import SurfIcon from "@mui/icons-material/Kitesurfing";

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

function TrendingTours() {
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  var lugha = JSON.parse(sessionStorage.getItem("lugha"));

  // an array of images
  const images = [
    // {
    //     url: 'https://tiketika.online/img/landmarks/Harare-Lake.png',
    //     title: 'DAR - MWANZA',
    //     price: 'TZS 56,000',
    //     width: '100%',
    //     rows: matches ? 2 : 4,
    //     cols: matches ? 4 : 1,
    // },

    {
      url: "https://tiketika.online/img/tours/rock-the-yacht-1.png",
      title: "Island Hopping",
      price: "TZS 236,000",
      width: "100%",
      rows: matches ? 2 : 4,
      cols: matches ? 4 : 1,
    },
    {
      url: "https://tiketika.online/img/tours/uhuru-peak-1.png",
      title: "Reach the Peak",
      price: "TZS 435,000",
      width: "100%",
      cols: matches ? 4 : 1,
      rows: 2,
    },
    {
      url: "https://tiketika.online/img/tours/serengeti-1.png",
      title: "Big 5 Safari",
      price: "TZS 355,000",
      width: "100%",
      cols: matches ? 4 : 1,
      rows: 2,
    },
    {
      url: "https://tiketika.online/img/tours/kisasa-waterfall-lushoto-1.png",
      title: "Mountain Hiking",
      price: "TZS 114,500",
      width: "100%",
      rows: matches ? 2 : 4,
      cols: matches ? 2 : 1,
    },
    {
      url: "https://tiketika.online/img/tours/magoroto-1.png",
      title: "Magoroto Hideaway",
      price: "TZS 315,000",
      width: "100%",
      rows: 2,
      cols: matches ? 4 : 2,
    },
  ];

  return (
    <>
      <div className="text-center text-xl py-8">
        <Bold
          fontSize={matches ? 26 : 36}
          text={lugha.tourPopular}
          color={"#333"}
        ></Bold>
      </div>

      <ImageList
        sx={{
          width: "70%",
          margin: "auto",
        }}
        variant="quilted"
        cols={4}
        rowHeight={121}
        className="mb-4 max-w-7xl items-center"
      >
        {images.map((item) => (
          <ImageListItem
            key={item.url}
            cols={item.cols || 1}
            rows={item.rows || 1}
            sx={{
              "& .MuiImageListItem-img": {
                cursor: "pointer",
                opacity: 1,
                transition: "opacity 1s",
              },
              "& .MuiImageListItem-img:hover": {
                cursor: "pointer",
                opacity: 0.4,
              },
            }}
          >
            <img
              {...srcset(item.url, 121, item.rows, item.cols)}
              alt={item.title}
              loading="lazy"
            />
            <ImageListItemBar
              sx={{
                "& .MuiImageListItemBar-title": {
                  fontFamily: "Euclid-Bold",
                  fontSize: matches ? 16 : 20,
                },
                "& 	.MuiImageListItemBar-subtitle": {
                  fontFamily: "Euclid-Regular",
                },
              }}
              title={item.title}
              subtitle={lugha.from + " " + item.price}
              actionIcon={
                <IconButton
                  sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                  aria-label={`info about ${item.title}`}
                >
                  <SurfIcon />
                </IconButton>
              }
            />
          </ImageListItem>
        ))}
      </ImageList>
    </>
  );
}

export default TrendingTours;

{
  /* <img src={images[3].url} alt={images[3].title} style={{ width: '100%' }} /> */
}
