import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import BusForm from "./../Forms/BusForm";
import Bold from "../Text/Bold";
import BusListing from "./BusListing";
import Semi from "../Text/Semi";

import { useSelector, useDispatch } from "react-redux";
import { selectBusForm } from "../../slices/NavSlice";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Grid } from "@mui/material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function DateTabs() {
  const dispatch = useDispatch();
  
  const lugha = JSON.parse(window.sessionStorage.getItem("lugha"))

  const busGlobalState = useSelector(selectBusForm);

  const dates = [];

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  for (let index = 0; index < 4; index++) {
    var date = busGlobalState ? new Date(busGlobalState.date) : new Date();

    date.setDate(date.getDate() + index);
    const options = { weekday: "short" };
    //Language
    let timeZone = 'sw-TZ'
    if(lugha.lugha === 'english'){ timeZone = 'en-US' }

    var day = new Intl.DateTimeFormat(timeZone, options).format(date);
    var month = date.toLocaleString("default", { month: "short" });
    var dayNum = date.getDate();

    var dateString = day + ", " + dayNum + " " + month;

    dates[index] = dateString;
  }

  const [value, setValue] = React.useState(0);
  const [queryDate, setQueryDate] = React.useState(dates[0]);

  React.useEffect(() => {
    // console.log(queryDate);
  }, [value]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100vw" }}>
      {matches ? (
        <></>
      ) : (
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="Service Tabs"
            centered
          >
            {dates.map((item, i) => {
              return (
                <Tab
                  key={i}
                  label={item}
                  {...a11yProps(i)}
                  onClick={() => setQueryDate(dates[i])}
                  sx={{
                    fontFamily: "Euclid-Bold",
                    paddingLeft: 5,
                    paddingRight: 5,
                    paddingTop: 2,
                  }}
                />
              );
            })}
          </Tabs>
        </Box>
      )}

      <div>
        {/* <div style={{ marginTop: 5 }}>
          <Semi text={lugha.availableBuses + " " + queryDate} color="gray" />
        </div> */}

        <TabPanel value={value} index={value}>
          <BusListing departureDate={queryDate} />
        </TabPanel>
      </div>
    </Box>
  );
}
