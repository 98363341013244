import { createSlice } from "@reduxjs/toolkit";

export const NavSlice = createSlice({
  name: "nav",
  initialState: {
    bookedSeat: [],
    entertainmentPackages: [],
    eventPackages: [],
    tourPackages: [],
    hotelRooms: [],
    busForm: [],
    restForm: [],
    hotelForm: [],
    eventForm: [],
    entertainmentForm: [],
    tourForm: [],
    flightForm: [],
    availableBuses: [],
    availableRests: [],
    availableHotels: [],
    availableEvents: [],
    availableEntertainment: [],
    availableTours: [],
    availableFlights: [],
    chosenBus: null,
    pickUpDropOff: null,
    transitHotel: null,
    transitRestaurant: null,
    homeBg: 0,
    checkoutDetails: [],
  },
  reducers: {
    addBookedSeat: (state, action) => {
      let seatIndex = state.bookedSeat.indexOf(action.payload);
      if (seatIndex === -1) state.bookedSeat.push(action.payload);
    },

    removeBookedSeat: (state, action) => {
      state.bookedSeat.splice(state.bookedSeat.indexOf(action.payload), 1);
    },

    setBookedSeat: (state, action) => {
      state.bookedSeat = action.payload;
    },

    setEntertainmentPackages: (state, action) => {
      state.entertainmentPackages = action.payload;
    },

    setEventPackages: (state, action) => {
      state.eventPackages = action.payload;
    },

    setTourPackages: (state, action) => {
      state.tourPackages = action.payload;
    },

    setHotelRooms: (state, action) => {
      state.hotelRooms = action.payload;
    },

    addBusForm: (state, action) => {
      state.busForm = action.payload;
    },

    removeBusForm: (state, action) => {
      state.busForm.pop(action.payload);
    },

    addRestForm: (state, action) => {
      state.restForm = action.payload;
    },

    removeRestForm: (state, action) => {
      state.restForm.pop(action.payload);
    },

    addHotelForm: (state, action) => {
      state.hotelForm = action.payload;
    },

    removeHotelForm: (state, action) => {
      state.hotelForm.pop(action.payload);
    },

    addEventForm: (state, action) => {
      state.eventForm = action.payload;
    },

    removeEventForm: (state, action) => {
      state.eventForm.pop(action.payload);
    },

    addEntertainmentForm: (state, action) => {
      state.entertainmentForm = action.payload;
    },

    removeEntertainmentForm: (state, action) => {
      state.entertainmentForm.pop(action.payload);
    },

    addTourForm: (state, action) => {
      state.tourForm = action.payload;
    },

    removeTourForm: (state, action) => {
      state.tourForm.pop(action.payload);
    },

    addFlightForm: (state, action) => {
      state.flightForm = action.payload;
    },

    removeFlightForm: (state, action) => {
      state.flightForm.pop(action.payload);
    },

    setAvailableBuses: (state, action) => {
      state.availableBuses = action.payload;
    },

    setAvailableRests: (state, action) => {
      state.availableRests = action.payload;
    },

    setAvailableHotels: (state, action) => {
      state.availableHotels = action.payload;
    },

    setAvailableEvents: (state, action) => {
      state.availableEvents = action.payload;
    },

    setAvailableEntertainment: (state, action) => {
      state.availableEntertainment = action.payload;
    },

    setAvailableTours: (state, action) => {
      state.availableTours = action.payload;
    },

    setAvailableFlights: (state, action) => {
      state.availableFlights = action.payload;
    },

    setChosenBus: (state, action) => {
      state.chosenBus = action.payload;
    },

    setPickUpDropOff: (state, action) => {
      state.pickUpDropOff = action.payload;
    },

    setTransitHotel: (state, action) => {
      state.transitHotel = action.payload;
    },

    setTransitRestaurant: (state, action) => {
      state.transitRestaurant = action.payload;
    },

    setHomeBg: (state, action) => {
      state.homeBg = action.payload;
    },

    setCheckoutDetails: (state, action) => {
      state.checkoutDetails = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addBookedSeat,
  removeBookedSeat,
  addBusForm,
  removeBusForm,
  addRestForm,
  removeRestForm,
  addHotelForm,
  removeHotelForm,
  addEventForm,
  removeEventForm,
  addEntertainmentForm,
  removeEntertainmentForm,
  addTourForm,
  removeTourForm,
  addFlightForm,
  removeFlightForm,
  setAvailableBuses,
  setAvailableRests,
  setAvailableHotels,
  setAvailableEvents,
  setAvailableEntertainment,
  setAvailableTours,
  setAvailableFlights,
  setChosenBus,
  setPickUpDropOff,
  setTransitHotel,
  setTransitRestaurant,
  setBookedSeat,
  setEntertainmentPackages,
  setEventPackages,
  setTourPackages,
  setHotelRooms,
  setHomeBg,
  setCheckoutDetails,
} = NavSlice.actions;

export const selectBookedSeat = (state) => state.nav.bookedSeat;
export const selectEntertainmentPackages = (state) => state.nav.entertainmentPackages;
export const selectEventPackages = (state) => state.nav.eventPackages;
export const selectTourPackages = (state) => state.nav.tourPackages;
export const selectHotelRooms = (state) => state.nav.hotelRooms;
export const selectBusForm = (state) => state.nav.busForm;
export const selectRestForm = (state) => state.nav.restForm;
export const selectHotelForm = (state) => state.nav.hotelForm;
export const selectEventForm = (state) => state.nav.eventForm;
export const selectEntertainmentForm = (state) => state.nav.entertainmentForm;
export const selectTourForm = (state) => state.nav.tourForm;
export const selectFlightForm = (state) => state.nav.flightForm;
export const selectAvailableBuses = (state) => state.nav.availableBuses;
export const selectAvailableRests = (state) => state.nav.availableRests;
export const selectAvailableHotels = (state) => state.nav.availableHotels;
export const selectAvailableEvents = (state) => state.nav.availableEvents;
export const selectAvailableEntertainment = (state) => state.nav.availableEntertainment;
export const selectAvailableTours = (state) => state.nav.availableTours;
export const selectAvailableFlights = (state) => state.nav.availableFlights;
export const selectChosenBus = (state) => state.nav.chosenBus;
export const selectPickUpDropOff = (state) => state.nav.pickUpDropOff;
export const selectTransitHotel = (state) => state.nav.transitHotel;
export const selectTransitRestaurant = (state) => state.nav.transitRestaurant;
export const selectHomeBg = (state) => state.nav.homeBg;
export const selectCheckoutDetails = (state) => state.nav.checkoutDetails;

export default NavSlice.reducer;
