import React, { useState, useEffect } from "react";
import Nav from "../components/Main/Nav";
import { useParams } from "react-router-dom";
import TourInfo from "../components/Main/TourInfo";
import FooterNav from "../components/Main/FooterNav";

function TourView() {

  const { tourID } = useParams()
  return (
    <div style={styles.container}>
      {/* Navigation */}
      <Nav colored={true} />
      <TourInfo id={tourID} />  
      <FooterNav />
    </div>
  );
}

export default TourView;

const styles = {
  container: {
    // backgroundColor: 'red',
  },

  tabs: {
    position: "absolute",
    top: 85,
  },
};
