
const NumberFormatter = (number) => {
    return new Intl.NumberFormat("en-US").format(number)
}

function WordCapitalizer(str) {
  let quick = str.toLocaleLowerCase()
  return quick.replace(/\b\w/g, match => match.toUpperCase());
}

const ConvertUnholyObjectToArray = (inputObject) => {
  const resultArray = [];

  Object.keys(inputObject).forEach((key) => {
    const [fieldName, suffix] = key.split('-');

    // Check if the key has a valid suffix (e.g., '-1', '-2', etc.)
    if (suffix && !isNaN(suffix)) {
      const index = parseInt(suffix) - 1;

      // Ensure the resultArray has enough elements to accommodate the current index
      while (resultArray.length <= index) {
        resultArray.push({});
      }

      // Add the key-value pair to the corresponding object in the resultArray
      resultArray[index][fieldName] = inputObject[key];
    }
  });

  return resultArray;
};

export { NumberFormatter, WordCapitalizer, ConvertUnholyObjectToArray }