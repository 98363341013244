// a component to render a bus listing in the main page
import React, { useEffect, useState } from "react";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from "@material-ui/core/styles";
import InstagramIcon from '@mui/icons-material/Instagram';
import { useTheme } from "@material-ui/core/styles";
import Primary from "../Buttons/Primary";
import Secondary from "../Buttons/Secondary";
import Bold from "../Text/Bold";
import Semi from "../Text/Semi";
import Regular from "../Text/Regular";
import CircularProgress from '@mui/material/CircularProgress';
import ActionFooter from "./ActionFooter";
import Checkout from "./Checkout";

import { useDispatch, useSelector } from "react-redux";
import {
  selectCheckoutDetails,
  selectEventPackages,
} from "../../slices/NavSlice";
import { NumberFormatter } from "../fx/fx";
import { Dialog, DialogActions, DialogContent, Paper } from "@material-ui/core";
import { Divider, Skeleton } from "@mui/material";
import Submit from "../Buttons/Submit";
import Tiketika from "../Axios/AxiosConfig";
import parse from 'html-react-parser';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "60%",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 20,
    boxShadow: "0px 1px 9px rgba(0, 0, 0, 0.07)",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "80%",
    },
    margin: 15,
    padding: 15,
  },
  rootMenu: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    width: "80%",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 20,
    flexDirection: "column",
    boxShadow: "0px 1px 9px rgba(0, 0, 0, 0.07)",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "80%",
      transform: "translate(-53%, -50%)",
    },
    padding: 15,
  },
  details: {
    width: "60%",
    margin: 10,
    padding: 5,
    borderRadius: 5,
    backgroundColor: "#CBFFB5",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: 0,
    },
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    height: 150,
    width: 200,
    paddingRight: 10,
    borderRadius: 10,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: 0,
      height: 150,
      borderRadius: 10,
    },
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
}));

function EventInfo({id}) {
  
  //declare lugha
  const lugha = JSON.parse(window.sessionStorage.getItem("lugha"));  
  
  const [openResponseModal, setOpenResponseModal] = useState(false);
  const [message, setMessage] = useState(false);
  const [status, setStatus] = useState(false);
  const [event, setEvent] = useState();
  const [tiers, setTiers] = useState();
  
  // state to store total menu price for items in a particular venue
  const [totalEventPrice, setTotalEventPrice] = useState(0);
  const [itemsCount, setItemsCount] = useState([]);
  const [checkoutForm, setCheckoutForm] = useState(false);

  const handleOpenResponseModal = () => setOpenResponseModal(true);
  const handleCloseResponseModal = () => {
    setMessage(false);
    setOpenResponseModal(false);
  }

  const checkoutDetails = useSelector(selectCheckoutDetails);
  const eventGlobalState = useSelector(selectEventPackages);

  async function getTukio() {
    try {
      const response = await Tiketika.get("/event/?id=" + id);
      if (response.data) {
        console.log(response.data);
        setTiers(response.data.tiers)
        setEvent(response.data.event)
      }
    } catch (error) {
      console.log("error asxsa", error);
    }
  }

  // Trigger save and USSD push for payment
  async function submitDetailsProcessPayment() {
    const { name, phone, email, payPhone } = checkoutDetails
    
    if(itemsCount){
      let items = consolidateTiers(itemsCount)
      let amount = totalEventPrice
      if(payPhone){
        handleOpenResponseModal()
        
        try {
          const response = await Tiketika.post('/event/', { name, phone, email, payPhone, items, amount, headers:  { "Access-Control-Allow-Origin": "*" } });
          if (response.data) {
            console.log(response.data);
            const { message, status } = response.data
            if(message && status){
              setMessage(message)
              setStatus(status)
            }
          }
        }
        catch (error) {
          console.log("Error", error);
        }
      }
      else{
        setMessage('Please enter the phone number for payment')
        handleOpenResponseModal()
      }
    }
    else{
      setMessage('Please select tickets')
      handleOpenResponseModal()
    }
  }

  
  // function to send checkout details and form details to api
  const CompleteCheckout = () => {    
    //Trigger payment via AzamPay's GW
    submitDetailsProcessPayment()
  }
  
  useEffect(() => {
    getTukio()
  },[id])

  // useEffect total price for all items in itemsCount
  useEffect(() => {
    let total = 0;
    itemsCount.forEach((item) => {
      total += parseInt(item.tier.price) * parseInt(item.quantity);
    });
    setTotalEventPrice(total);
  }, [itemsCount]);

  const CartCount = ({ item, eventID }) => {
    var count = 0;
    itemsCount.map((thing) => {
      if (thing.tier.tierID === item.tierID && thing.tier.eventID === eventID) {
        count++;
        return count;
      }
    });

    return <Semi text={count} />;
  };

  // delete item from itemsCount array
  const deleteItem = (item, eventID) => {
    console.log(item)
    console.log(itemsCount)
    const newItemsCount = itemsCount.filter((thing) => {
      return thing.tier.tierID == item.tierID && thing.eventID == eventID;
    });

    const index = itemsCount.indexOf(newItemsCount[0]);
    itemsCount.splice(index, 1);
    setItemsCount([...itemsCount]);
  };

  const consolidateTiers = (items) => {
    let inputArray = items.map(item => ({ tier: { tierID: item.tier.tierID, price: item.tier.price, }, quantity: item.quantity }));

    return inputArray.reduce((accumulator, current) => {
      const existingPackage = accumulator.find(item => item.tier.tierID === current.tier.tierID);
  
      if (existingPackage) {
        existingPackage.quantity += current.quantity;
      } else {
        accumulator.push(current);
      }
  
      return accumulator;
    }, []);
  }

  return (
    <>
      <div
        style={{
          // ...centerize,
          // justifyContent: "space-between",
          marginBottom: 25,
          // paddingTop: '2rem',
        }}
      >
    
        {/* Response Dialog */}
        <Dialog
          open={openResponseModal}
          onClose={handleCloseResponseModal}
          aria-labelledby={'Booking Response'}
          aria-describedby={'Booking Response'}
          maxWidth="sm"
        >
          <DialogContent>
            <Grid container spacing={1}>
              {message !== false ? 
              <Semi text={message} fontSize={'1.2rem'} color={status === '00' ? "#333" : "#333"} />
              : 
              <CircularProgress />              
              }
              
            </Grid>
            <DialogActions>
              {message !== false &&
                <Secondary text="Close" action={() => { handleCloseResponseModal() }} />
              }
            </DialogActions>
          </DialogContent>
        </Dialog>

        {event ? 
        <Grid container spacing={2} className="p-10">
          <Grid item xs={12} sm={7}>
            {/* Description and details */}
            <Paper elevation={4} style={{ padding: '10px 20px', textAlign: 'left' }}>
              <div className="mb-1 pb-2 pt-1">
                <Bold text={event.event} fontSize={'1.2rem'} color={"#2BA147"} />
                <Divider className="pt-1 mb-1" />
              </div>
              
              <Regular
                text={lugha.lugha === 'swahili' ? parse(event.maelezo) : parse(event.description)}
                fontSize={'1rem'}
                color={"#333"}
                align={'left'}
              />
              <div className="bottom mt-4">
                <Grid container>
                  <Grid item xs={12} sm={3}>
                    <Bold
                      text={'Follow'}
                      fontSize={'0.8rem'}
                      color={"#333"}
                      align={'left'}
                    />
                    <br />

                    <a href={event.instagram && event.instagram} target="_blank" rel="noopener noreferrer">
                      <InstagramIcon />
                    </a>
                  </Grid>

                  {/* Seatmap Button */}
                  {/* <Grid item xs={12} sm={5}>                    
                    <Submit
                      text={lugha.view +' '+ lugha.seatMap}
                      action={() => console.log('ehe')}
                      inverse
                      size={'small'}
                    />
                  </Grid> */}
                  
                  {/* Blank */}
                  <Grid item xs={12} sm={4} />

                </Grid>
              </div>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={5}>
            {/* List of Tables available for reservation */}
            <Paper elevation={4} style={{ padding: '20px' }}>
              {tiers &&
                <>
                
                  {tiers.map((item, i) => {
                    const { eventID, price, tier } = item
                    var description = item.description;
                    var type = item.packageType;

                    if(lugha.lugha === 'swahili'){
                      description = item.maelezo;
                      type = item.ainaYaBurudani
                    }

                    return (
                      <>
                        {i === 0 ? 
                          <div className="mb-1 pb-2">
                            <Bold color={'#2BA147'} fontSize={'1.2rem'} text={event.eventType} />
                            <Divider />
                          </div>
                        :
                        ''
                        }
                        

                        <Grid container>
                          <Grid item xs={6} md={6}>
                            <div>
                              <Bold text={tier} fontSize={'1.4rem'} /><br />
                              <Semi text={'Tsh. '+ NumberFormatter(price)} />
                              {/* <Semi text={lugha.price +': Tsh. '+ NumberFormatter(price)} /> */}
                            </div>
                          </Grid>

                          <Grid item xs={6} md={6}>
                            {/* Cart Counter */}
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                                alignItems: "center",
                              }}
                            >
                              <Primary
                                text={"-"}
                                action={() => {
                                  deleteItem(item, eventID);
                                }}
                              />

                              <CartCount item={item} eventID={eventID} />

                              <Primary
                                text={"+"}
                                action={() => {
                                  // add item to items array

                                  var newItem = {
                                    tier: item,
                                    quantity: 1,
                                    // eventID: eventID,
                                    // price: price,
                                  };

                                  setItemsCount([...itemsCount, newItem]);
                                }}
                              />
                            </div>
                          </Grid>
                          
                          <Grid item xs={12} md={12} style={{ textAlign: 'left' }}>
                              <Regular text={description} color={'#000'} />
                          </Grid>

                        <div style={styles.separator}></div>
                        </Grid>
                      </>
                    );
                  })}
                  </> 
              }
              
            </Paper>
          </Grid>
        </Grid>
        :
        <Grid container spacing={2} className="p-10">
          <Grid item xs={12} sm={7}>
            <Paper elevation={4} style={{ padding: '0 20px' }}>
              <Skeleton width={200} height={50} animation={'wave'} />
              <Skeleton width={'auto'} height={300} animation={'wave'} />
            </Paper>
          </Grid>

          <Grid item xs={12} sm={5}>
            <Paper elevation={4} style={{ padding: '10px 40px' }}>
              <Skeleton width={'auto'} height={80} animation={'wave'} />
              <Skeleton width={'auto'} height={80} animation={'wave'} />
              <Skeleton width={'auto'} height={80} animation={'wave'} />
            </Paper>
          </Grid>
        </Grid>
        }
      </div>

      {itemsCount.length > 0 && (
        <ActionFooter
          text={lugha.checkout}
          clickAction={() => setCheckoutForm(true)}
        />
      )}

      {checkoutForm && (
        <Checkout
          total={totalEventPrice}
          text={lugha.payNow}
          type='event'
          currency='Tsh'
          closeAction={() => {
            // dispatch(setCheckoutDetails([]));
            setCheckoutForm(false);
          }}
          items={itemsCount}
          details={[
            {
              type: "text",
              placeholder: "Full Name",
              name: "fullname",
            },
            {
              type: "phone",
              placeholder: "Phone Number",
              name: "phone",
            },
            {
              type: "email",
              placeholder: "Email",
              name: "email",
            },
          ]}
          action={(e) => {
            e.preventDefault();
            CompleteCheckout();
          }}
        />
      )}
    </>
  );
}

export default EventInfo;

const styles = {
  card: {
    width: 300,
    margin: 80,
    boxShadow: "0px 1px 9px rgba(0, 0, 0, 0.07)",
    display: "flex",
    justifyContent: "center",
  },
  eventDetails: {
    textAlign: "left",
    alignItems: "left",
    flex: 2,
  },
  separator: {
    width: "100%",
    height: 1,
    backgroundColor: "#e0e0e0",
    margin: 10,
  },
  priceDetails: {
    padding: 20,
    backgroundColor: "#f5f5f5",
    textAlign: "center",
    width: "20%",
    borderRadius: 30,
  },

  anem: {
    display: "flex",
    alignItems: "center",
    margin: 5,
  },

  price: {
    fontSize: 30,
    fontWeight: "bold",
    color: "#2BA147",
  },
};
