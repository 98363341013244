import React, { useState, useEffect } from "react";
import Nav from "../components/Main/Nav";
import EntertainmentForm from "../components/Forms/EntertainmentForm";
import EntertainmentOptions from "../components/Main/EntertainmentOptions";
import FooterNav from "../components/Main/FooterNav";

function EntertainmentListings() {
  return (
    <div style={styles.container}>
      {/* Navigation */}
      <Nav colored={true} />

      <div className="flex flex-col justify-center items-center">
        <EntertainmentForm />
        <EntertainmentOptions />
        <FooterNav />
      </div>
    </div>
  );
}

export default EntertainmentListings;

const styles = {
  container: {
    // backgroundColor: 'red',
  },

  tabs: {
    position: "absolute",
    top: 85,
  },
};
