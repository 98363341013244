import React from "react";
import Bold from "../Text/Bold";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import IconButton from "@mui/material/IconButton";
import TicketIcon from "@mui/icons-material/ConfirmationNumber";

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

function PopularRoutesGrid() {
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  var lugha = JSON.parse(sessionStorage.getItem("lugha"));

  // an array of routes
  const routes = [
    {
      url: "https://tiketika.online/img/landmarks/Harare-Lake.webp",
      title: "DAR - MWANZA",
      price: "TZS 56,000",
      width: "100%",
      rows: matches ? 2 : 4,
      cols: matches ? 4 : 1,
    },

    {
      url: "https://tiketika.online/img/landmarks/Lusaka-Waterfalls.webp",
      title: "MWANZA - MBEYA",
      price: "TZS 32,000",
      width: "100%",
      rows: 2,
      cols: matches ? 4 : 3,
    },

    {
      url: "https://tiketika.online/img/landmarks/Mbeya-Airport.webp",
      title: "DAR - MBEYA",
      price: "TZS 58,000",
      width: "100%",
      rows: 2,
      cols: matches ? 4 : 1,
    },

    {
      url: "https://tiketika.online/img/landmarks/arusha-town-monument-1.webp",
      title: "DAR - ARUSHA",
      price: "TZS 32,000",
      width: "100%",
      cols: matches ? 4 : 2,
      rows: 2,
    },
  ];

  return (
    <>
      {/* <div style={{ textAlign: "center", fontSize: 20, paddingTop: "18%" }}> */}
      <div className="text-center text-xl py-8">
        <Bold
          fontSize={matches ? 26 : 36}
          text={lugha.busPopular}
          color={"#fff"}
        ></Bold>
      </div>

      <ImageList
        sx={{
          width: "70%",
          margin: "auto",
        }}
        variant="quilted"
        cols={4}
        rowHeight={121}
        className="mb-4 max-w-7xl items-center"
      >
        {routes.map((item) => (
          <ImageListItem
            key={item.url}
            cols={item.cols || 1}
            rows={item.rows || 1}
            sx={{
              "& .MuiImageListItem-img": {
                cursor: "pointer",
                opacity: 1,
                transition: "opacity 1s",
              },
              "& .MuiImageListItem-img:hover": {
                cursor: "pointer",
                opacity: 0.4,
              },
            }}
          >
            <img
              {...srcset(item.url, 121, item.rows, item.cols)}
              alt={item.title}
              loading="lazy"
            />
            <ImageListItemBar
              sx={{
                "& .MuiImageListItemBar-title": {
                  fontFamily: "Euclid-Bold",
                  fontSize: matches ? 16 : 20,
                  cursor: "pointer",
                },
                "& 	.MuiImageListItemBar-subtitle": {
                  fontFamily: "Euclid-Regular",
                  cursor: "pointer",
                },
              }}
              title={item.title}
              subtitle={lugha.from + " " + item.price}
              actionIcon={
                <IconButton
                  sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                  aria-label={`Trips from ${item.title} for just ${item.price}`}
                >
                  <TicketIcon />
                </IconButton>
              }
            />
          </ImageListItem>
        ))}
      </ImageList>
    </>
  );
}

export default PopularRoutesGrid;

{
  /* <img src={routes[3].url} alt={routes[3].title} style={{ width: '100%' }} /> */
}
