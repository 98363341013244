import React, { useState, useEffect } from "react";
import BInput from "../Inputs/BInput";
import GInput from "../Inputs/GInput";
import HInput from "../Inputs/HInput";
import Primary from "../Buttons/Primary";
import Submit from "../Buttons/Submit";
import DatePicker from "../Inputs/DatePicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useHistory, useLocation } from "react-router-dom";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";

import { useSelector, useDispatch } from "react-redux";
import {
  addHotelForm,
  selectHotelForm,
  setHomeBg,
  setAvailableHotels,
} from "../../slices/NavSlice";
import { spacers } from "../Spacers/Spacers";
import { BottomPattern, GreenLogo } from "../../assets/images";
import Tiketika from "../Axios/AxiosConfig";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "5%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginBottom: "5%",
    },
  },

  formHome: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-end",
    marginTop: "27%",
    marginRight: "15%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginBottom: "5%",
    },
  },

  formHomeS: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    //marginTop: '92%',
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginBottom: "5%",
      paddingTop: "16%",
    },
  },
}));

function HotelForm() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const browserLocation = useLocation();

  const hotelGlobalState = useSelector(selectHotelForm);

  const [checkOutFocus, setCheckOutFocus] = useState("text");
  const [dateFocus, setDateFocus] = useState("text");

  const [LocationOptions, setLocationOptions] = useState([
    { locationID: 1, location: "Arusha" },
    { locationID: 2, location: "Dar es Salaam" },
    { locationID: 3, location: "Dodoma" },
  ]);

  const genre = [
    { genreID: 1, englishTitle: "Action", swahiliTitle: "Mapigano" },
    { genreID: 2, englishTitle: "Adventure", swahiliTitle: "Aventura" },
    { genreID: 3, englishTitle: "Animation", swahiliTitle: "Uhuishaji" },
    { genreID: 4, englishTitle: "Comedy", swahiliTitle: "Vichekesho" },
    { genreID: 5, englishTitle: "Horror", swahiliTitle: "Kutisha" },
    { genreID: 6, englishTitle: "Romance", swahiliTitle: "Upendo" },
  ];

  // Form variables
  const [location, setLocation] = useState(
    hotelGlobalState ? hotelGlobalState.location : ""
  );
  const [date, setDate] = useState(
    hotelGlobalState ? hotelGlobalState.date : ""
  );
  const [checkout, setCheckout] = useState(
    hotelGlobalState ? hotelGlobalState.checkout : ""
  );

  const [locationError, setLocationError] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [timeError, setTimeError] = useState(false);

  const [generalError, setGeneralError] = useState(false);

  async function postHotelFormData() {
    try {
      const response = await Tiketika.post("/search/?type=hotel", {
        location,
        checkInDate: date,
        checkOutDate: checkout,
      });

      const content = response.data;

      if (content.hotels === null) {
        dispatch(setAvailableHotels(null));
        setGeneralError("No hotels found, check your dates and location");
      } else {
        dispatch(setAvailableHotels(content.hotels));
      }
    } catch (err) {
      console.log("error sdvsd", err);
    }
  }

  useEffect(() => {
    if (location && date && checkout) {
      dispatch(addHotelForm({ location, date, checkout }));
      postHotelFormData();
    }
  }, [location, date, checkout]);

  useEffect(() => {
    setTimeout(() => {
      setGeneralError("");
    }, 5000);
  }, [generalError]);

  const errorStyles = {
    width: "80vw",
    height: "50px",
    left: matches ? "" : "6rem",
    position: "fixed",
    borderRadius: "15px",
    padding: "4px 0",
    bottom: "1rem",
    backgroundColor: "#D84646",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "-2px 6px 15px 0px rgba(51,51,51,0.43)",
    "-webkit-box-shadow": "-2px 6px 15px 0px rgba(51,51,51,0.43)",
    "-moz-box-shadow": "-2px 6px 15px 0px rgba(51,51,51,0.43)",
    zIndex: "1000",
  };

  //declare lugha
  var lugha = JSON.parse(window.sessionStorage.getItem("lugha"));

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        {/* conditional layout for home screen */}
        {browserLocation.pathname === "/" ? (
          <div className="container mx-auto px-4">
            <div className="relative flex flex-col md:flex-row rounded-xl lg:rounded-[30px] bg-[#201f21] max-w-screen-lg self-center m-auto mt-40 mb-24 lg:px-8 px-4 lg:w-[95%] xl:w-full">
              <div className="w-full h-full absolute px-4 left-0 ">
                <div className="relative w-full h-full">
                  <div className="absolute w-full bottom-0">
                    <img
                      alt="Night"
                      src={BottomPattern}
                      class="inset-0 h-full w-full object-contain hidden lg:flex"
                    />
                  </div>
                  <div className="absolute w-full top-0 ">
                    <img
                      alt="Night"
                      src={BottomPattern}
                      class="inset-0 h-full w-full object-contain -scale-y-100 -scale-x-100 lg:hidden"
                    />
                  </div>
                </div>
              </div>

              <img
                alt="Night"
                src={GreenLogo}
                class="inset-0 h-[125%] w-[60%] xl:h-[130%] xl:w-[60%] object-contain absolute -top-20  xl:-top-[100px] -left-28 xl:-left-40 hidden lg:flex"
              />

              <div className="w-full hidden lg:flex"></div>
              <div className="w-full z-[99]">
                <form action="#" class="my-2  py-8">
                  <div class="flex w-full border-2 my-4 rounded-md py-2 px-4 border-[#2F9F48] flex-col md:flex-row md:justify-between">
                    <div className="w-full mr-3">
                      <HInput
                        placeholder={lugha.cinemaLocation}
                        options={LocationOptions}
                        onchange={(e) => setLocation(e.target.value)}
                        value={location}
                        colored={true}
                      />
                    </div>
                  </div>
                  <div class="col-span-6 flex w-full border-2 my-4 rounded-md py-2 px-4 border-[#2F9F48] flex-col md:flex-row md:justify-between">
                    <div className="w-full mr-3 flex flex-col justify-start">
                      <DatePicker
                        placeholder={lugha.cinemaDate}
                        onchange={(value) =>
                          setDate(moment(value).format("YYYY-MM-DD"))
                        }
                        value={date}
                      />
                    </div>

                    <div className="w-full mr-3">
                      <GInput
                        placeholder={lugha.cinemaGenre}
                        options={genre}
                        onchange={(e) => setLocation(e.target.value)}
                        value={location}
                        colored={true}
                      />
                    </div>
                  </div>
                  <div class="sm:flex sm:items-center sm:gap-4 sm:justify-end z-[9999999] w-full">
                    {location && date && checkout && (
                      <Submit
                        text={lugha.search}
                        iconName={faMagnifyingGlass}
                        pathTo="/hotels"
                        action={() => {
                          dispatch(setHomeBg(0));
                          postHotelFormData();
                        }}
                      />
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        ) : (
          <div className={classes.form}>
            {/* Error div */}
            {generalError && <div style={errorStyles}>{generalError}</div>}

            <HInput
              placeholder={lugha.cinemaLocation}
              options={LocationOptions}
              onchange={(e) => setLocation(e.target.value)}
              value={location}
            />

            <GInput
              placeholder={lugha.cinemaGenre}
              options={genre}
              onchange={(e) => setLocation(e.target.value)}
              value={location}
              colored={true}
            />

            <BInput
              type={dateFocus}
              placeholder={lugha.cinemaDate}
              onfocus={() => {
                setDateFocus("date");
              }}
              onchange={(e) => setDate(e.target.value)}
              value={date}
            />

            {location && date && checkout && (
              <Submit
                text={lugha.search}
                iconName={faMagnifyingGlass}
                pathTo="/hotels"
                action={() => {
                  dispatch(setHomeBg(0));
                  postHotelFormData();
                }}
              />
            )}
          </div>
        )}
      </LocalizationProvider>
    </>
  );
}

export default HotelForm;

const styles = {
  display: "flex",
  flexDirection: "row",
  width: "100vw",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "5%",
};
