import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import BusForm from "./../Forms/BusForm";
import FlightForm from "./../Forms/FlightForm";
import EventForm from "./../Forms/EventForm";
import EntertainmentForm from "./../Forms/EntertainmentForm";
import CinemaForm from "./../Forms/CinemaForm";
import TourForm from "./../Forms/TourForm";
import HotelForm from "./../Forms/HotelForm";
import PopularBusRoutesGrid from "./PopularBusRoutesGrid";
import PopularBusRoutesCarousel from "./PopularBusRoutesCarousel";
import TrendingEvents from "./TrendingEvents";
import TrendingEntertainment from "./TrendingEntertainment";
import TrendingMovies from "./TrendingMovies";
import TrendingTours from "./TrendingTours";
import FooterNav from "./FooterNav";
import AdSpace from "../Advertising/AdSpace"

import { useDispatch } from "react-redux";
import { selectHomeBg, setHomeBg } from "./../../slices/NavSlice";
import PopularEntertainmentCarousel from "./PopularEntertainmentCarousel";
import Bold from "../Text/Bold";
import { Grid } from "@mui/material";
import Intro from "../Text/Intro";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({ colored, tab, switchTab, longForm, mobile }) {
  const [lugha, setLugha] = useState(
    JSON.parse(window.sessionStorage.getItem("lugha"))
  );
  const dispatch = useDispatch();
  var top = "";
  if (longForm) {
    top = "-73%";
  } else {
    top = "-90%";
  }

  const tabStyles = {
    backgroundColor: "#333",
  };

  const tabHeadings = {
    fontFamily: "Euclid-Bold",
    color: colored ? "#64d375" : "white",
    opacity: 1,
    backgroundColor: "rgba(43, 161, 71, 1)",
    borderRadius: '20px',
    marginRight: '10px'
  };

  const selectedTabHeading = {
    backgroundColor: "rgba(19, 96, 31, 1)",
  };

  var watchLanguage = sessionStorage.getItem("language");
  useEffect(() => {
    //declare lugha
    setLugha(JSON.parse(window.sessionStorage.getItem("lugha")));
  
  }, [watchLanguage]);

  return (
    <Box>
      <div className="flex items-center md:pt-20">
        <div className="max-w-sm sm:max-w-xl md:max-w-max" style={!mobile ? { position: 'relative', left: '10%' } : {}}>
          
          <Tabs
            textColor="inherit"
            indicatorColor="inherit"
            value={tab}
            onChange={(e, value) => switchTab(value)}
            aria-label="Service Tabs"
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
          >
            <Tab label={lugha.navBus.toLocaleUpperCase()} {...a11yProps(0)} sx={tab === 0 ? { ...tabHeadings, ...selectedTabHeading } : tabHeadings} />
            <Tab label={lugha.navFlight.toLocaleUpperCase()} {...a11yProps(1)} sx={tab === 1 ? { ...tabHeadings, ...selectedTabHeading } : tabHeadings} />
            <Tab label={lugha.navEntertainment.toLocaleUpperCase()} {...a11yProps(2)} sx={tab === 2 ? { ...tabHeadings, ...selectedTabHeading } : tabHeadings} />
            <Tab label={lugha.navTour.toLocaleUpperCase()} {...a11yProps(3)} sx={tab === 3 ? { ...tabHeadings, ...selectedTabHeading } : tabHeadings} />
            <Tab label={lugha.navHotel.toLocaleUpperCase()} {...a11yProps(4)} sx={tab === 4 ? { ...tabHeadings, ...selectedTabHeading } : tabHeadings} />
            <Tab label={lugha.navEvent.toLocaleUpperCase()} {...a11yProps(5)} sx={tab === 5 ? { ...tabHeadings, ...selectedTabHeading } : tabHeadings} />
            {/* <Tab label={lugha.navRestaurant.toLocaleUpperCase()} {...a11yProps(6)} sx={tab === 6 ? { ...tabHeadings, ...selectedTabHeading } : tabHeadings}/> */}
            {/* <Tab label={lugha.navTrain.toLocaleUpperCase()} {...a11yProps(7)} sx={tab === 7 ? { ...tabHeadings, ...selectedTabHeading } : tabHeadings} /> */}
            {/* <Tab label={lugha.navFerry.toLocaleUpperCase()} {...a11yProps(8)} sx={tab === 8 ? { ...tabHeadings, ...selectedTabHeading } : tabHeadings} /> */}
            {/* <Tab label={lugha.navCinema.toLocaleUpperCase()} {...a11yProps(9)} sx={tab === 9 ? { ...tabHeadings, ...selectedTabHeading } : tabHeadings} /> */}
          </Tabs>
        </div>
        {/* <Tabs
          textColor="inherit"
          indicatorColor="inherit"
          value={tab}
          onChange={(e) => switchTab(e.target.value)}
          aria-label="Service Tabs"
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          <Tab label={lugha.navBus} {...a11yProps(0)} sx={tabHeadings} />
            <Tab label={lugha.navEvent} {...a11yProps(1)} sx={tabHeadings} />
            <Tab label={lugha.navEntertainment} {...a11yProps(2)} sx={tabHeadings} />
            <Tab label={lugha.navTour} {...a11yProps(3)} sx={tabHeadings} />
            <Tab label={lugha.navCinema} {...a11yProps(4)} sx={tabHeadings} />
            <Tab label={lugha.navRestaurant} {...a11yProps(5)} sx={tabHeadings}/>
            <Tab label={lugha.navHotel} {...a11yProps(6)} sx={tabHeadings} />
            <Tab label={lugha.navTrain} {...a11yProps(7)} sx={tabHeadings} />
            <Tab label={lugha.navFerry} {...a11yProps(8)} sx={tabHeadings} />            
        </Tabs> */}
      </div>
      <div className="container mx-auto max-w-xs">
        <img
          alt="Tiketika"
          src="https://tiketika.online/img/assets/tiketika-white-shaded-padded-logo.png"
          class="inset-0 h-full w-full object-contain md:hidden"
        />
      </div>

      {/* BUS */}
      <TabPanel value={tab} index={0}>
        <Intro
          text={lugha.introBus}
          mobile={mobile}
        />
        <BusForm />
        <div className="bg-[#EDEDED]">
          <PopularBusRoutesCarousel />
          {/* <AdSpace /> */}
          <PopularEntertainmentCarousel />
          {/* <AdSpace /> */}
          <FooterNav />
        </div>
      </TabPanel>

      {/* FLIGHTS */}
      <TabPanel value={tab} index={1}>
        <Intro
          text={lugha.introFlight}
          mobile={mobile}
        />
        <FlightForm />
        <div className="bg-[#EDEDED]">
          <PopularBusRoutesCarousel />
          <TrendingEvents />
          {/* <AdSpace /> */}
          <PopularEntertainmentCarousel />
          {/* <AdSpace /> */}
          <FooterNav />
        </div>
      </TabPanel>

      {/* ENTERTAINMENT */}
      <TabPanel value={tab} index={2}>
        <Intro
          text={lugha.introEntertainment}
          mobile={mobile}
        />
        <EntertainmentForm />
        <div className="bg-[#EDEDED]">
          <PopularEntertainmentCarousel />
          {/* <AdSpace /> */}
          <PopularBusRoutesCarousel />
          {/* <AdSpace /> */}
          <FooterNav />
        </div>
      </TabPanel>

      {/* TOURS */}
      <TabPanel value={tab} index={3}>
        <Intro
          text={lugha.introTour}
          mobile={mobile}
        />
        <TourForm />
        <div className="bg-[#EDEDED]" > 
        {/* style={tabStyles} */}
          <PopularBusRoutesCarousel />
          {/* <AdSpace /> */}
          <TrendingTours />
          <FooterNav />
        </div>
      </TabPanel>

      {/* HOTELS */}
      <TabPanel value={tab} index={4}>
        <Intro
          text={lugha.introHotel}
          mobile={mobile}
        />
        <HotelForm />
        <div className="bg-[#EDEDED]">
          <PopularBusRoutesCarousel />
          {/* <AdSpace /> */}
          <PopularEntertainmentCarousel />
          {/* <AdSpace /> */}
          <FooterNav />
        </div>
      </TabPanel>
      
      {/* EVENTS */}
      <TabPanel value={tab} index={5}>
        <Intro
          text={lugha.introEvent}
          mobile={mobile}
        />
        <EventForm />
        <div className="bg-[#EDEDED]">
          <PopularBusRoutesCarousel />
          {/* <AdSpace /> */}
          <PopularEntertainmentCarousel />
          <FooterNav />
        </div>
      </TabPanel>
      
      {/* CINEMAS */}
      {/* <TabPanel value={tab} index={4}>
        <CinemaForm />
        <div style={tabStyles}>
          <TrendingMovies />
          <FooterNav />
        </div>
      </TabPanel> */}

      {/* RESTAURANT */}
      {/* <TabPanel value={tab} index={5}>
        <RestForm />
        <div style={tabStyles}>
          <PopularRestaurants />
          <FooterNav />
        </div>
      </TabPanel> */}

      {/* SGR */}
      {/* <TabPanel value={tab} index={7}>
        <TrainForm />
        <div style={tabStyles}>
          <PopularTrainRoutes />
          <FooterNav />
        </div>
      </TabPanel> */}

      {/* FERRIES */}
      {/* <TabPanel value={tab} index={8}>
        <FerryForm />
        <div style={tabStyles}>
          <PopularFerryRoutes />
          <FooterNav />
        </div>
      </TabPanel> */}
      
    </Box>
  );
}
