import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  basic: {
    padding: 15,
    borderRadius: 15,
    width: "100%",
    background: "#fff",
    border: "1px solid #ECECEC",
    outline: "none",
    margin: 5,
    fontFamily: "Euclid-Bold",
    color: "#2BA147",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.07)",
    fontSize: 15,
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

function LInput({
  options,
  placeholder,
  disabled,
  onfocus,
  onblur,
  onchange,
  autofocus,
  value,
}) {
  const classes = useStyles();
  let location = value
  
  if(!isNaN(parseInt(value))){
    if(options && options.length){
      location = options.find(loc => parseInt(loc.locationID) === parseInt(value)).location
    }
  }

  return (
    //select
    <div>
      <select
        disabled={disabled}
        onFocus={onfocus}
        onBlur={onblur}
        className={classes.basic}
        onChange={onchange}
        autoFocus={autofocus}
      >
        <option value={value} disabled selected>
          {" "}
          {value ? location : placeholder}{" "}
        </option>

        {options?.map((option, index) => {
          return (
            <option key={index} value={option.locationID}>
              {option.location}
            </option>
          );
        })}
      </select>
    </div>
  );
}

export default LInput;

const styles = {
  select: {
    padding: 20,
    borderRadius: 50,
    width: "100%",
    background: "#fff",
    border: "none",
    outline: "none",
    margin: 5,
    fontFamily: "Euclid-Bold",
    color: "#2BA147",
    boxShadow: "0px 1px 9px rgba(0, 0, 0, 0.07)",
    fonSize: 20,
  },
};
