import React from "react";

function Primary({ text, action, type, id, style }) {
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      class="rounded-md bg-[#2BA147] px-5 py-2.5 text-sm font-medium text-white transition hover:bg-transparent hover:text-[#2BA147] hover:border hover:border-[#2BA147] flex flex-col justify-center items-center"
      style={{ ...style, fontFamily: "Euclid-Bold", cursor: 'pointer' }}
      onClick={action}
      type={type}
      id={id}
    >
      {text}
    </a>
  );
}

export default Primary;
