import React from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import IconButton from "@mui/material/IconButton";
import ToIcon from '@mui/icons-material/East';
import TicketIcon from "@mui/icons-material/ConfirmationNumber";
import Carousel from 'react-material-ui-carousel'
import { Button, Grid, Paper } from "@mui/material";
import Primary from "../Buttons/Primary";
import Bold from "../Text/Bold";
import Regular from "../Text/Regular";
import Light from "../Text/Light";
import { NumberFormatter } from "../fx/fx";

function PopularRoutesCarousel() {
    const theme = useTheme();

    const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    var lugha = JSON.parse(sessionStorage.getItem("lugha"));

    const adStyle = { 
        width: '80%', 
        height: '40%',
        margin: '0 auto',
        marginTop: '40px',
        marginBottom: '10px',
        backgroundColor: '#2BA147', 
    }

  return (
    <>
    
    <div className="text-center text-xl py-8" style={adStyle}>
        <Bold
            fontSize={smallScreen ? 26 : 36}
            text={lugha.advertisement.toLocaleUpperCase()}
            color={"#fff"}
            padding={'20px 0'}
        />
    </div>
    </>
  );

}

export default PopularRoutesCarousel;

{
  /* <img src={routes[3].url} alt={routes[3].title} style={{ width: '100%' }} /> */
}
