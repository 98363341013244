import { Grid } from '@mui/material'
import React from 'react'
import Bold from './Bold'

function Intro({text, mobile}) {
  return (
    <Grid container>
      <Grid item xs={12} sm={6} xl={6}>
        <div style={!mobile ? { position: 'relative', left: '20%', textAlign: 'left', textShadow: '2px 2px 2px #333' } : {}}>
        <Bold
          text={text}
          color={'#FFF'}
          fontSize={mobile ? '1.2rem' : '2.4rem'}
        />
        </div>
      </Grid>
      <Grid item xs={0} sm={6} xl={6}>
      </Grid>
    </Grid>
  )
}

export default Intro