import React, { useState } from 'react'
import Primary from './../Buttons/Primary';
import Button from '@mui/material/Button';
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';
import AxiosInstance from '../Axios/AxiosConfig';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 5,
};

const useStyles = makeStyles((theme) => ({

    basic: {
        padding: 10,
        borderRadius: 15,
        width: '98%',
        border: "none",
        outline: "none",
        margin: 5,
        fontFamily: "Euclid-Bold",
        color: '#ffffff', 
        background: '#252525',
        boxShadow: "0px 1px 9px rgba(0, 0, 0, 0.07)",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center",
        [theme.breakpoints.down('sm')]: {
            width: '85vw',
        },

    },
    formInput: {
        fontSize: 14, 
        textAlign: 'left', 
        fontFamily: "Euclid-Bold", 
        color: '#ffffff',
        position: 'relative',
        left: '2rem',
        top: '0.8rem',
    }

}));


//   Sign up function using axios
export default function SignUp() {

    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rpassword, setRPassword] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [generalError, setGeneralError] = useState('');
    const [generalSuccess, setGeneralSuccess] = useState('');

    const errorStyles = {
        width: 'inherit',
        height: '50px',
        //left: matches ? '' : '6rem',
        position: 'absolute',
        borderRadius: '15px',
        padding: '4px 8px',
        top: '1rem',
        backgroundColor: '#D84646',
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '-2px 6px 15px 0px rgba(51,51,51,0.43)',
        '-webkit-box-shadow': '-2px 6px 15px 0px rgba(51,51,51,0.43)',
        '-moz-box-shadow': '-2px 6px 15px 0px rgba(51,51,51,0.43)',
        zIndex: '1000'
    }

    const successStyles = {
        width: 'inherit',
        height: '50px',
        //left: matches ? '' : '6rem',
        position: 'absolute',
        borderRadius: '15px',
        padding: '4px 8px',
        top: '1rem',
        backgroundColor: '#2DA148',
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '-2px 6px 15px 0px rgba(51,51,51,0.43)',
        '-webkit-box-shadow': '-2px 6px 15px 0px rgba(51,51,51,0.43)',
        '-moz-box-shadow': '-2px 6px 15px 0px rgba(51,51,51,0.43)',
        zIndex: '1000'
    }

    const handleEmail = (e) => {
        setEmail(e.target.value);
    }

    const handlePassword = (e) => {
        setPassword(e.target.value);
    }

    const handleName = (e) => {
        setName(e.target.value);
    }

    const handlePhone = (e) => {
        setPhone(e.target.value);
    }

    const handleRPassword = (e) => {
        setRPassword(e.target.value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            name: name,
            email: email,
            phone: phone,
            password: password,
            rpassword: rpassword,
        }
        console.log(data)

        if(password !== rpassword) {
            setGeneralError('Passwords do not match');
        }
        else {
            AxiosInstance.post('/authenticate/register/', data)
            .then(res => {
                console.log(res);
                if(parseInt(res.data.status) === 0){
                 //Acknowledge registration   
                 setGeneralSuccess(res.data.message)
                }
                else{
                    setGeneralError(res.data.message)
                }
            })
            .catch(err => {
                console.log(err);
            })
        }

    }

    //declare lugha
    var lugha = JSON.parse(window.sessionStorage.getItem('lugha'))

    return (

        <form onSubmit={handleSubmit} style={{ flexDirection: 'row', padding: '5px 12px', color: '#ffffff' }}>
            
            <div style={{ paddingTop: '2rem' }}><h1>{lugha.registerText}</h1></div>

            {/* Error div */}
            {generalError && <div style={errorStyles}>{generalError}</div>}
            
            {/* Success div */}
            {generalSuccess && <div style={successStyles}>{generalSuccess}</div>}
            
            <div>
                <label>
                    <span className={classes.formInput}>{lugha.name}</span> <br />
                    <input required type="text" className={classes.basic} name="name" placeholder={lugha.name} onChange={handleName} />
                </label>
            </div>
            <div>
                <label>
                    <span className={classes.formInput}>{lugha.email}</span> <br />
                    <input required type="text" className={classes.basic} name="email" placeholder={lugha.email} onChange={handleEmail} />
                </label>
            </div>
            <div>
                <label>
                    <span className={classes.formInput}>{lugha.phone}</span> <br />
                    <input required type="text" className={classes.basic} name="phone" placeholder={lugha.phone} onChange={handlePhone} />
                </label>
            </div>
            <div>
                <label>
                    <span className={classes.formInput}>{lugha.password}</span> <br />
                    <input required type="password" className={classes.basic} name="password" placeholder={lugha.password} onChange={handlePassword} />
                </label>
            </div>
            <div>
                <label>
                    <span className={classes.formInput}>{lugha.rPassword}</span> <br />
                    <input required type="password" className={classes.basic} name="rpassword" placeholder={lugha.rpassword} onChange={handleRPassword} />
                </label>
            </div>

            <div>
                <Button onClick={handleSubmit}>
                    <Primary text={lugha.registerButton} />
                </Button>
            </div>

        </form>
    )
}