import React from 'react'

function Regular({text, color, fontSize, align}) {
  return (
    <span 
        style={{
            padding: '5 0',
            margin: 2,
            fontFamily: "Euclid-Regular",
            fontSize: fontSize ? fontSize : 14, 
            color: color ? color : "#fff",
            textDecoration: 'none',
            textTransform: 'none',
            textAlign: align ? align : 'center',
        }}
    > 
        {text} 
    </span>
  )
}

export default Regular