import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Seats from "../components/Forms/Seats";
import Nav from "./../components/Main/Nav";
import Semi from "../components/Text/Semi";
import Bold from "../components/Text/Bold";
import Primary from "../components/Buttons/Primary";
import RestaurantSidePanel from "../components/Main/RestaurantSidePanel";
import HotelSidePanel from "../components/Main/HotelSidePanel";
import { Dialog, DialogActions, DialogContent, Grid, Paper } from "@material-ui/core";
import CircularProgress from '@mui/material/CircularProgress';
import LInput from "./../components/Inputs/LInput";
import axios from "axios";
import { NumberFormatter } from '../components/fx/fx'

import { useDispatch, useSelector } from "react-redux";
import {
  selectBookedSeat,
  selectChosenBus,
  setChosenBus,
  selectPickUpDropOff,
  setPickUpDropOff
} from "../slices/NavSlice";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import ActionFooter from "./../components/Main/ActionFooter";
import Tiketika from "../components/Axios/AxiosConfig";

const seatData = {
  total: 40,
  booked: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  reserved: [11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
  available: [21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
};

// //function to book bus seats
// const bookSeats = (busId, seats) => {
//   //api call to book seats
//   fetch("http://localhost:3000/bookSeats", {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify({
//       busId: busId,
//       seats: seats,
//     }),
//   })
//     .then((res) => res.json())
//     .then((data) => {
//       console.log(data);
//     })
//     .catch((err) => {
//       console.log(err);
//     });
// };


const BookSeats = () => {
  const dispatch = useDispatch();
  //declare lugha
  const lugha = JSON.parse(window.sessionStorage.getItem("lugha"));
  // const locations = JSON.parse(window.sessionStorage.getItem('locations'))

  const chosenSeatsByActiveSession = useSelector(selectBookedSeat);

  const chosenBus = useSelector(selectChosenBus);
  const pickUpDropOff = useSelector(selectPickUpDropOff);

  const [currentBus, setCurrentBus] = useState(chosenBus);
  const [seatLayout, setSeatLayout] = useState([]);
  const [showRestaurantSidePanel, setShowRestaurantSidePanel] = useState(false);
  const [showHotelSidePanel, setShowHotelSidePanel] = useState(false);

  const [selectedRestaurant, setSelectedRestaurant] = useState();
  const [selectedHotel, setSelectedHotel] = useState();
  const [locations, setLocations] = useState([]);
  
  const [pickUp, setPickUp] = useState('');
  const [dropOff, setDropOff] = useState('');
  const { tripID, routeID, busDate } = useParams();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const [openLoadingModal, setOpenLoadingModal] = useState(true);
  const handleOpenLoadingModal = () => setOpenLoadingModal(true);
  const handleCloseLoadingModal = () => setOpenLoadingModal(false);

  // Get the bus seat availability details
  async function chosenOne() {
    try {
      const response = await Tiketika.post("/ticket/uts-seats/", {
        tripID, routeID
      });
      const { bus, status } = response.data
      console.log(response.data)
      if (status === '00') {
        
        if(bus && bus.plate){
          handleCloseLoadingModal()
          console.log("Current Bus ", bus);
          dispatch(setChosenBus(bus));
          setCurrentBus(bus);
          const { seats, tripLocations } = bus
          let places = tripLocations
          setLocations(places)
          setSeatLayout(seats)
          console.log(seats)

          if(places && places.length){
            let first = places[0]
            let last = places[places.length - 1]
            setPickUp(first.locationID)
            setDropOff(last.locationID)
            dispatch(setPickUpDropOff({ pickUp: first.locationID, pickUpLocation: first.location, dropOff: last.locationID, dropOffLocation: last.location }))
          }
        }
      }
    } catch (error) {
      console.log("error asxsa", error);
    }
  }

  useEffect(() => {
    if (tripID && routeID) {
      chosenOne();
    }
  }, []);

  useEffect(() => {
    if(pickUp && dropOff){
      if(locations && locations.length){
        let pickUpLocation = locations.find(loc => parseInt(loc.locationID) === parseInt(pickUp)).location
        let dropOffLocation = locations.find(loc => parseInt(loc.locationID) === parseInt(dropOff)).location
        dispatch(setPickUpDropOff({ pickUp: pickUp, pickUpLocation: pickUpLocation, dropOff: dropOff, dropOffLocation: dropOffLocation }))
      }
    }  
  }, [pickUp, dropOff])
  

  const BookingListItem = ({ seatName, price }) => {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Primary text={seatName} />
          <div>
            <Bold text={lugha.fare} />: <Semi text={"Tsh. " + NumberFormatter(price)} />
          </div>
          {/* <Secondary text={'X'} /> */}
        </div>
        <div style={styles.separator}></div>
      </>
    );
  };

  const RestListItem = ({ name, menu, image, location }) => {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "left",
            width: "100%",
            textAlign: "left",
          }}
        >
          {matches ? (
            <></>
          ) : (
            <img
              src={"https://tiketika.online/img/restaurants/" + image}
              alt="Restaurant Image"
              style={{ width: 94, borderRadius: 10 }}
            />
          )}

          <div>
            <Bold text={name} fontSize={12} />
            <div>
              <Semi text={lugha.location + ": " + location} fontSize={12} />
            </div>
          </div>

          <Primary
            text={lugha.restaurantCTA}
            action={() => {
              setShowRestaurantSidePanel(true);
              setSelectedRestaurant({ name, menu, image });
            }}
          />
        </div>
        <div style={styles.separator}></div>
      </>
    );
  };

  const HotelListItem = ({ name, room, image, location }) => {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "left",
            width: "100%",
            textAlign: "left",
          }}
        >
          {matches ? (
            <></>
          ) : (
            // eslint-disable-next-line jsx-a11y/img-redundant-alt
            <img
              src={"https://tiketika.online/img/restaurants/" + image}
              alt="Hotel Image"
              style={{ width: 94, borderRadius: 10 }}
            />
          )}

          <div>
            <Bold text={name} fontSize={12} />
            <div>
              <Semi text={lugha.location + ":  " + location} fontSize={12} />
            </div>
          </div>

          <Primary
            text={lugha.hotelCTA}
            action={() => {
              setShowHotelSidePanel(true);
              setSelectedHotel({ name, room, image, location });
            }}
          />
        </div>
        <div style={styles.separator}></div>
      </>
    );
  };

  const rows = 11;
  const columns = ["a", "b", "c", "d"];

  const seats = [];
  for (let i = 1; i <= rows; i++) {
    const rowSeats = [];
    for (let j = 0; j < columns.length; j++) {
      const seat = `${i}${columns[j]}`;
      rowSeats.push(seat);
    }
    seats.push(rowSeats);
  }

  return (
    <section>
      <Nav colored={true} />

        {/* Loading Dialog */}
        <Dialog
          open={openLoadingModal}
          onClose={handleCloseLoadingModal}
          aria-labelledby={'Loading'}
          aria-describedby={'Loading'}
          maxWidth="sm"
        >
          <DialogContent>
            <CircularProgress />    
          </DialogContent>
        </Dialog>

      <div className="pt-8">
        <Bold fontSize={23} text={lugha.selectSeats.toLocaleUpperCase()} />
      </div>
      <div class="max-w-screen-2xl  pb-4 mx-auto">
        <div class="grid grid-cols-1 gap-2 md:gap-6  sm:grid-cols-2 lg:grid-cols-2 py-3 px-3 ">
          <div >
            <div className="bg-white p-2 rounded-2xl">
              <Seats
                total={currentBus?.normalSeats}
                booked={currentBus?.bookedSeats}
                available={seatData.available}
                reserved={seatData.reserved}
              />
            </div>
          </div>
          <div className="">
            <div className="p-4 rounded-2xl bg-white">
              <Bold text={lugha.busDetails} />
              <div style={styles.busDetails}>
                <div>
                  <Bold fontSize={'1.4rem'} text={currentBus?.company} />
                </div>
                <div>
                  <Bold fontSize={'1.2rem'} text={currentBus?.origin +' - '+ currentBus?.destination} />
                </div>
                <div>
                  <Bold text={currentBus?.type} />
                </div>

                <div style={styles.separator}></div>

                {/* <div><Bold text='Departure Time'></Bold> : <Semi text={bus.departure_time} /></div> */}
                <div style={{ width: "100%" }}>
                  <LInput
                    placeholder={lugha.pickUpLocation}
                    options={locations ? locations : []}
                    onchange={(e) => setPickUp(e.target.value)}
                    value={pickUp}
                  />
                </div>
                <div>
                  <LInput
                    placeholder={lugha.dropOffLocation}
                    options={locations ? locations : []}
                    onchange={(e) => setDropOff(e.target.value)}
                    value={dropOff}
                  />
                </div>
              </div>{" "}
              <div style={styles.separator}></div>
              {/* Bus Booked Seats */}
              <div style={styles.bookedSeats}>
                <div>
                  <Bold
                    color={"#2BA147"}
                    text={
                      lugha.bookedSeats + " ( " + chosenSeatsByActiveSession.length + " )"
                    }
                  />
                </div>
                <div
                  style={{
                    padding: 10,
                    marginTop: 2,
                    backgroundColor: "#CBFFB5",
                    borderRadius: 15,
                  }}
                >
                  {chosenSeatsByActiveSession.map((item, i) => {
                    return (
                      <BookingListItem
                        key={i}
                        seatName={item}
                        price={currentBus?.price}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </div>

          {/* <div className="sm:col-span-2 lg:col-span-1 ">
            <div className="p-4 rounded-2xl bg-white">
              <Bold text={lugha.navRestaurant} />

              <div style={{ paddingTop: 15 }}>
                {currentBus?.restaurants?.map((item, i) => {
                  return (
                    <RestListItem
                      key={i}
                      name={item.restaurant}
                      image={item.path}
                      menu={item.menu}
                      location={item.location}
                    />
                  );
                })}
              </div>
            </div>
            <div className="p-4 rounded-2xl bg-white mt-4 ">
              <Bold text={lugha.navHotel} />

              <div style={{ paddingTop: 15 }}>
                {currentBus?.hotels?.map((item, i) => {
                  return (
                    <HotelListItem
                      key={i}
                      name={item.hotel}
                      image={item.path}
                      room={item.room}
                      location={item.location}
                    />
                  );
                })}
              </div>
            </div>
          </div> */}
        </div>
      </div>
      {chosenSeatsByActiveSession.length > 0 && (
        <ActionFooter text={lugha.continue.toLocaleUpperCase()} action={"/booking/details"} />
      )}

      {/* {showRestaurantSidePanel && (
        <RestaurantSidePanel
          heading={"'" + selectedRestaurant.name + "'"}
          text={"'" + lugha.add +" "+ selectedRestaurant.name + "'"}
          closeAction={() => setShowRestaurantSidePanel(false)}
          items={selectedRestaurant?.menu}
        />
      )}

      {showHotelSidePanel && (
        <HotelSidePanel
          heading={"'" + selectedHotel.name + "'"}
          text={"'" + lugha.add +" "+ selectedHotel.name + "'"}
          closeAction={() => setShowHotelSidePanel(false)}
          items={selectedHotel?.room}
        />
      )} */}
    </section>
  );
};

export default BookSeats;

const styles = {
  cards: {
    // marginTop: 100,
    padding: 40,
  },
  busDetails: {
    textAlign: "left",
    padding: 25,
  },
  bookedSeats: {
    padding: 25,
  },
  separator: {
    width: "100%",
    height: 1,
    backgroundColor: "#e0e0e0",
    margin: 10,
  },
};
