import { Card } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import { Routes, Route, Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Seats from "../components/Forms/Seats";
import Nav from "./../components/Main/Nav";
import Semi from "../components/Text/Semi";
import Bold from "../components/Text/Bold";
import Primary from "../components/Buttons/Primary";
import Secondary from "../components/Buttons/Secondary";
import FInput from "../components/Inputs/FInput";
import Checkout from "../components/Main/Checkout";

import { useDispatch, useSelector } from "react-redux";
import { selectBookedSeat, selectChosenBus, selectPickUpDropOff } from "../slices/NavSlice";
import ActionFooter from "./../components/Main/ActionFooter";
import Tiketika from "../components/Axios/AxiosConfig";
import { type } from "@testing-library/user-event/dist/type";
import { NumberFormatter } from "../components/fx/fx";

// //function to book bus seats
// const bookSeats = (busId, seats) => {
//   //api call to book seats
//   fetch("http://localhost:3000/bookSeats", {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify({
//       busId: busId,
//       seats: seats,
//     }),
//   })
//     .then((res) => res.json())
//     .then((data) => {
//       console.log(data);
//     })
//     .catch((err) => {
//       console.log(err);
//     });
// };

// // function to book tickets
// const bookTickets = async () => {
//   try {
//     const res = await Tiketika.post("booking/", { params: { type: "bus" } });
//     console.log("response from trying to book tickets", res);
//   } catch (error) {
//     console.log("an error occured");
//   }
// };

// const bus = {
//   name: "Bus Name",
//   price: 10000,
//   rating: 4.5,
//   image:
//     "https://firebasestorage.googleapis.com/v0/b/qpass-97fa6.appspot.com/o/bux.jpg?alt=media&token=a0171524-7c63-4e8d-8012-6e1d0e6c3f9c",
//   bus_id: 1,
//   departure_time: "10:00",
//   arrival_time: "12:00",
//   departure_date: "2021-10-10",
//   arrival_date: "2021-10-10",
//   departure_city: "Cairo",
//   arrival_city: "Alexandria",
//   bus_type: "VIP",
//   bus_company: "Company Name",
//   bus_company_logo:
//     "https://firebasestorage.googleapis.com/v0/b/qpass-97fa6.appspot.com/o/bux.jpg?alt=media&token=a0171524-7c63-4e8d-8012-6e1d0e6c3f9c",
//   bus_company_rating: 4.5,
//   bus_company_reviews: 100,
//   bus_company_id: 1,
//   bus_company_website: "https://www.google.com",
//   bus_company_phone: "01000000000",
//   bus_company_email: "",
//   bus_company_address: "",
//   bus_seats: 50,
// };


const BookDetails = () => {
  //declare lugha
  const lugha = JSON.parse(window.sessionStorage.getItem("lugha"));
  const locations = JSON.parse(window.sessionStorage.getItem('locations'))

  const location = useLocation();
  const chosenSeatsByActiveSession = useSelector(selectBookedSeat);
  const chosenBus = useSelector(selectChosenBus);
  const pickUpDropOff = useSelector(selectPickUpDropOff)
  console.log(pickUpDropOff)

  // generate unique session id
  const sessionId =
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15);

  const [showCheckout, setShowCheckout] = useState(false);
  const [passengers, setPassengers] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const passengerData = Object.fromEntries(formData.entries());
    setPassengers(passengerData);
    setShowCheckout(true);
  };

  const BookingListItem = ({ seatName, price }) => {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Primary text={seatName} />
          <div>
            <Bold text={lugha.fare} />: <Semi text={'Tsh. ' + price} />
          </div>
        </div>
        <div style={styles.separator}></div>
      </>
    );
  };
  
  return (
    <>
      <Nav colored={true} />
      <div className="container mx-auto">
        <h1
          class="text-center text-2xl font-bold text-black sm:text-3xl uppercase pt-6 sm:pt-8 md:pt-12 lg:pt-20 pb-2"
          style={{ fontFamily: "Euclid-Bold" }}
        >
          {/* {lugha.fillDetails.toLocaleUpperCase()} */}
        </h1>
      </div>
      <div class="max-w-screen-2xl  pb-4 mx-auto">
        <div class="grid grid-cols-1 gap-2 md:gap-6  md:grid-cols-2 py-3 px-3 ">
          <div>
            <div className="p-2 rounded-2xl bg-white">
              <div class="mx-auto max-w-2xl py-4">
                <h1
                  class="text-center text-2xl font-bold text-black sm:text-3xl"
                  style={{ fontFamily: "Euclid-Bold" }}
                >
                  {lugha.passengerDetails}
                </h1>

                <p
                  class="mx-auto mt-4 max-w-md text-center text-gray-900"
                  style={{ fontFamily: "Euclid-Regular" }}
                >
                  {lugha.fillDetails}
                </p>
                {chosenSeatsByActiveSession.length > 0 && (
                  <form id="user-details-form" method="" onSubmit={handleSubmit}>
                    {chosenSeatsByActiveSession.map((item, i) => {
                      let seat = lugha.ticketDetails + " (" + item + ")";
                      return (
                        <div
                          className="mt-6 mb-0 space-y-2 rounded-lg p-4 shadow-lg sm:p-6 lg:p-8"
                          key={i}
                        >
                          <p
                            class="text-center text-lg font-medium"
                            style={{ fontFamily: "Euclid-Regular" }}
                          >
                            {seat}
                          </p>
                          <FInput
                            width={'100%'}
                            type={"text"}
                            required
                            placeholder={lugha.name}
                            name={"name-" + (parseInt(i)+1)}
                          />
                          <div className="flex justify-between">
                            <div className="w-full mr-2">
                              <FInput
                                width={'100%'}
                                isSelect={true}
                                options={lugha.ageOptions}
                                required
                                placeholder={lugha.select + ' ' + lugha.age}
                                name={"age-" + (parseInt(i)+1)}
                              />
                            </div>
                            <div className="w-full">
                              <FInput
                                width={'100%'}
                                isSelect={true}
                                options={lugha.genderOptions}
                                required
                                placeholder={lugha.select + ' ' + lugha.gender}
                                name={"gender-" + (parseInt(i)+1)}
                              />
                            </div>
                          </div>
                          <hr />
                          <FInput
                            width={'100%'}
                            type={"text"}
                            required
                            placeholder={lugha.phone}
                            name={"phone-" + (parseInt(i)+1)}
                          />
                          <FInput
                            width={'100%'}
                            type={"email"}
                            placeholder={lugha.email + " ("+ lugha.optional +")"}
                            name={"email-" + (parseInt(i)+1)}
                          />
                          <div className="flex justify-between">
                            <div className="w-full mr-2">
                              <FInput
                                width={'100%'}
                                isSelect={true}
                                options={lugha.idOptions}
                                placeholder={lugha.idType + " ("+ lugha.optional +")"}
                                name={"idType-" + (parseInt(i)+1)}
                              />
                            </div>
                            <div className="w-full">
                              <FInput
                                width={'100%'}
                                type={"number"}
                                placeholder={lugha.idNumber + " ("+ lugha.optional +")"}
                                name={"identity-" + (parseInt(i)+1)}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </form>
                )}
              </div>
            </div>
          </div>

          <div className="">
            <div className="p-2 rounded-2xl bg-white">
              <div class="mx-auto max-w-screen-xl pt-2">
                <h1
                  class="text-center text-2xl font-bold text-black sm:text-3xl"
                  style={{ fontFamily: "Euclid-Bold" }}
                >
                  {lugha.busDetails}
                </h1>
                <div style={styles.busDetails}>
                  <div>
                    <Bold text={chosenBus?.company} />
                  </div>
                  <div className="pb-2">
                    <Bold text={chosenBus?.type} />
                  </div>

                  <hr className="py-2" />

                  <div>
                    <Bold text={lugha.departure}></Bold>:{" "}
                    <Semi text={chosenBus.time} />
                  </div>
                  <div>
                    <Bold text={lugha.pickUpLocation}></Bold>:{" "}
                    <Semi text={pickUpDropOff?.pickUpLocation} />
                  </div>
                  <div>
                    <Bold text={lugha.dropOffLocation}></Bold>:{" "}
                    <Semi text={pickUpDropOff?.dropOffLocation} />
                  </div>
                </div>

                {/* Bus Booked Seats */}
                <div style={styles.bookedSeats}>
                  <hr className="py-2" />
                  <div>
                    <Bold color={"#2BA147"} text={lugha.bookedSeats} />
                  </div>
                  <div
                    style={{
                      padding: 15,
                      margin: 5,
                      backgroundColor: "#CBFFB5",
                      borderRadius: 15,
                    }}
                  >
                    {chosenSeatsByActiveSession.map((item, i) => {
                      return (
                        <BookingListItem
                          key={i}
                          seatName={item}
                          price={chosenBus.price && NumberFormatter(chosenBus.price)}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button
        style={{
          position: "fixed",
          bottom: 0,
          backgroundColor: "#fff",
          width: "100%",
          padding: 15,
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
          zIndex: 5,
          boxShadow: "0px 1px 9px rgba(0, 0, 0, 0.07)",
        }}
        type="submit"
        form="user-details-form"
      >
        <p style={{ fontWeight: 600 }} className="rounded-md bg-[#2BA147] px-5 py-2.5 text-sm font-medium text-white transition  hover:bg-slate-400  flex flex-col justify-center items-center">
          {lugha.checkout.toLocaleUpperCase()}
        </p>
      </button>

      {showCheckout && (
        <Checkout
          text={lugha.payNow}
          id={sessionId}
          type='bus'
          currency='Tsh'
          action={() => setShowCheckout(false)} 
          // pathTo={`/`}
          closeAction={() => setShowCheckout(false)}
          items={{
            seats: chosenSeatsByActiveSession,
            price: chosenBus.price,
            passengers: passengers,
            bus: chosenBus,
            pickUpDropOff: pickUpDropOff,
          }}
        />
      )}
    </>
  );
};

export default BookDetails;

const styles = {
  cards: {
    marginTop: 100,
    padding: 40,
  },
  busDetails: {
    textAlign: "left",
    padding: 25,
  },
  bookedSeats: {
    padding: 25,
  },
  separator: {
    width: "100%",
    height: 1,
    backgroundColor: "#e0e0e0",
    margin: 10,
  },
};
