// a component to render a bus listing in the main page
import React, { useEffect, useState } from "react";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from "@material-ui/core/styles";
import InstagramIcon from '@mui/icons-material/Instagram';
import Primary from "../Buttons/Primary";
import Secondary from "../Buttons/Secondary";
import Bold from "../Text/Bold";
import Semi from "../Text/Semi";
import CircularProgress from '@mui/material/CircularProgress';
import ActionFooter from "./ActionFooter";
import Checkout from "./Checkout";

import { useDispatch, useSelector } from "react-redux";
import {
  setHomeBg,
  selectCheckoutDetails,
  selectHotelForm,
  selectHotelRooms,
} from "../../slices/NavSlice";
import { NumberFormatter } from "../fx/fx";
import moment from "moment/moment";
import { Dialog, DialogActions, DialogContent, Paper } from "@material-ui/core";
import Regular from "../Text/Regular";
import { CardActionArea, Divider, Skeleton } from "@mui/material";
import Submit from "../Buttons/Submit";
import Tiketika from "../Axios/AxiosConfig";
import parse from 'html-react-parser';
import Carousel from 'react-material-ui-carousel'

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "60%",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 20,
    boxShadow: "0px 1px 9px rgba(0, 0, 0, 0.07)",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "80%",
    },
    margin: 15,
    padding: 15,
  },
  rootMenu: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    width: "80%",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 20,
    flexDirection: "column",
    boxShadow: "0px 1px 9px rgba(0, 0, 0, 0.07)",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "80%",
      transform: "translate(-53%, -50%)",
    },
    padding: 15,
  },
  details: {
    width: "60%",
    margin: 10,
    padding: 5,
    borderRadius: 5,
    backgroundColor: "#CBFFB5",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: 0,
    },
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    height: 150,
    width: 200,
    paddingRight: 10,
    borderRadius: 10,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: 0,
      height: 150,
      borderRadius: 10,
    },
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
}));

function HotelInfo({id}) {
  
  //declare lugha
  const lugha = JSON.parse(window.sessionStorage.getItem("lugha"));  
  
  async function getHotel() {
    
    try {
      const response = await Tiketika.get("/hotel/?id=" + id);
      if (response.data) {
        setRooms(response.data.rooms)
        setHotel(response.data.hotel)
      }
    } catch (error) {
      console.log("error asxsa", error);
    }
  }

  const [open, setOpen] = useState(false);
  const [openResponseModal, setOpenResponseModal] = useState(false);
  const [message, setMessage] = useState(false);
  const [status, setStatus] = useState(false);
  const [hotel, setHotel] = useState();
  const [roomDescription, setRoomDescription] = useState();
  const [gallery, setGallery] = useState();
  const [rooms, setRooms] = useState();
  
  // state to store total menu price for items in a particular venue
  const [totalHotelPrice, setTotalHotelPrice] = useState(0);
  const [itemsCount, setItemsCount] = useState([]);
  const [checkoutForm, setCheckoutForm] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenResponseModal = () => setOpenResponseModal(true);
  const handleCloseResponseModal = () => {
    setMessage(false)
    setOpenResponseModal(false);
  }

  const checkoutDetails = useSelector(selectCheckoutDetails);
  const hotelRooms = useSelector(selectHotelRooms);
  const hotelForm = useSelector(selectHotelForm);

  // Trigger save and USSD push for payment
  async function submitDetailsProcessPayment() {
    const { name, phone, email, payPhone } = checkoutDetails
    const { checkInDate, checkOutDate } = hotelForm
    
    if(itemsCount){
      let items = consolidateRooms(itemsCount)
      let amount = totalHotelPrice

      if(payPhone){
        handleOpenResponseModal()  
        try {
          const response = await Tiketika.post('/hotel/', { name, phone, email, checkInDate, checkOutDate, payPhone, items, amount, headers:  { "Access-Control-Allow-Origin": "*" } });
          if (response.data) {
            const { message, status } = response.data
            if(message && status){
              setMessage(message)
              setStatus(status)
            }
          }
        }
        catch (error) {
          console.log("Error", error);
        }
      }
      else{
        setMessage('Please enter the phone number for payment')
        handleOpenResponseModal()
      }
    }
    else{
      setMessage('Please select a room')
      handleOpenResponseModal()
    }
  }
  
  // function to send checkout details and form details to api
  const CompleteCheckout = () => {    
    //Trigger payment via AzamPay's GW
    submitDetailsProcessPayment()
  }

  useEffect(() => {
    getHotel()
  },[id])

  // useEffect total price for all items in itemsCount
  useEffect(() => {
    let total = 0;
    let items = itemsCount.map(item => ({ room: { roomID: item.room.roomID, price: item.room.price, }, quantity: item.quantity }));
    itemsCount.forEach((item) => {
      total += parseInt(item.room.price) * parseInt(item.quantity);
    });
    setTotalHotelPrice(total);
  }, [itemsCount]);

  const CartCount = ({ item, hotelID }) => {
    var count = 0;
    itemsCount.map((thing) => {
      if (thing.room.roomID === item.roomID && thing.room.hotelID === hotelID) {
        count++;
      }
      return count;
    });

    return <Semi text={count} />;
  };
  
  
  const Item = (props) => {
    const { item } = props
    let paperStyle = {
      background: `linear-gradient(to bottom, rgba(10, 10, 10, 0), rgba(10, 10, 10, .01)), url(${item})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      height: '400px',
    }

    let contentStyle = {
      position: 'absolute',
      bottom: 10,
      padding: 8,
      width: '100%',
      textAlign: 'left',
    }

    return (
      <Paper elevation={2} style={paperStyle}>
          <div style={contentStyle}>
            <Grid container>
            </Grid>
          </div>

      </Paper>
    )
  }

  const showGallery = (roomID) => {
    console.log(roomID)
    if(rooms && rooms.length){
      let room = rooms.find(rr => parseInt(rr.roomID) === parseInt(roomID))
      
      if(room && room.description){
        var description = room.description;
        if(lugha.lugha === 'swahili'){
          description = room.maelezo;
        }
        setRoomDescription(description)
      }

      if(room.images && room.images.length){
        let carousel = room.images.map((item, i) => (
          <Item key={i} item={item} />
        ));
        setGallery(carousel)
      }
      else{
        setGallery('')
      }
    }
    handleOpen();
  }

  // delete item from itemsCount array
  const deleteItem = (item, hotelID) => {
    const newItemsCount = itemsCount.filter((thing) => {
      return thing.room.roomID == item.roomID && thing.hotelID == hotelID;
    });

    const index = itemsCount.indexOf(newItemsCount[0]);
    itemsCount.splice(index, 1);
    setItemsCount([...itemsCount]);
  };

  
  const consolidateRooms = (items) => {
    let inputArray = items.map(({ room: { roomID, price }, quantity }) => ({ room: { roomID, price }, quantity }));

    return inputArray.reduce((accumulator, current) => {
      const existingPackage = accumulator.find(item => item.room.roomID === current.room.roomID);
  
      if (existingPackage) {
        existingPackage.quantity += current.quantity;
      } else {
        accumulator.push(current);
      }
  
      return accumulator;
    }, []);
  }


  return (
    <>
      <div
        style={{
          // ...centerize,
          // justifyContent: "space-between",
          marginBottom: 25,
          // paddingTop: '2rem',
        }}
      >
    
        {/* Response Dialog */}
        <Dialog
          open={openResponseModal}
          onClose={handleCloseResponseModal}
          aria-labelledby={'Booking Response'}
          aria-describedby={'Booking Response'}
          maxWidth="sm"
        >
          <DialogContent>
            <Grid container spacing={1}>
              {message !== false ? 
              <Semi text={message} fontSize={'1.2rem'} color={status === '00' ? "#333" : "#333"} />
              : 
              <CircularProgress />              
              }
            </Grid>
            <DialogActions>
              {message !== false &&
                <Secondary text="Close" action={() => { handleCloseResponseModal() }} />
              }
            </DialogActions>
          </DialogContent>
        </Dialog>

        {hotel ? 
        <Grid container spacing={2} className="p-10">
          <Grid item xs={12} sm={7}>
            {/* Description and details */}
            <Paper elevation={4} style={{ padding: '10px 20px', textAlign: 'left' }}>
              <div className="mb-1 pb-2 pt-1">
                <Bold text={hotel.hotel} fontSize={'1.2rem'} color={"#2BA147"} />
                <Divider className="pt-1 mb-1" />
              </div>
              
              <Regular
                text={lugha.lugha === 'swahili' ? parse(hotel.maelezo) : parse(hotel.description)}
                fontSize={'1rem'}
                color={"#333"}
                align={'left'}
              />
              <div className="bottom mt-4">
                <Grid container>                

                  {/* Gallery */}
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby={lugha.gallery}
                    aria-describedby={lugha.gallery}
                    maxWidth="md"
                    fullWidth
                  >
                    <DialogContent>
                      {/* List the package items grouped by type */}
                      <Grid container spacing={1}>
                        <Grid item xs={12} md={12}>
                          {gallery ? 

                          <Carousel autoPlay stopAutoPlayOnHover navButtonsAlwaysVisible
                            animation="slide" 
                            swipe interval={5000} 
                            duration={500} 
                            slidesPerPage={1}
                            navButtonsProps={{ 
                              style: {
                                backgroundColor: '#2DA148',
                              }
                            }} 
                          >
                            {gallery}
                          </Carousel>
                          
                          :
                            <Regular text={'No images yet, please check again another time'} color={'#000'} />
                          }
                        </Grid>

                        <Grid item xs={12} md={12} style={{ textAlign: 'left' }}>
                            
                            <Regular text={roomDescription ? parse(roomDescription) : ''} color={'#000'} />
                        </Grid>
                      </Grid>
                      <DialogActions>
                        <Secondary
                          text="Close"
                          action={() => { handleClose(); }}
                        />
                      </DialogActions>
                    </DialogContent>
                  </Dialog>

                  <Grid item xs={12} sm={3}>
                    <Bold
                      text={'Follow'}
                      fontSize={'0.8rem'}
                      color={"#333"}
                      align={'left'}
                    />
                    <br />

                    <a href={hotel.instagram && hotel.instagram} target="_blank" rel="noopener noreferrer">
                      <InstagramIcon />
                    </a>
                  </Grid>

                  {/* Gallery Button */}
                  <Grid item xs={12} sm={5}>      
                  
                  </Grid>
                  
                  {/* Blank */}
                  <Grid item xs={12} sm={4} />

                </Grid>
              </div>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={5}>
            {/* List of Tables available for reservation */}
            <Paper elevation={4} style={{ padding: '20px' }}>
              {rooms &&
                <>
                  {rooms.map((item, i) => {
                    var hotelID = item.hotelID;
                    var price = item.price;
                    var description = item.description;
                    var type = item.roomType
                    if(lugha.lugha === 'swahili'){
                      description = item.maelezo;
                      type = item.ainaYaChumba
                    }

                    return (
                      <>
                        {i === 0 ? 
                          <div className="mb-1 pb-2">
                            <Bold color={'#2BA147'} fontSize={'1.2rem'} text={lugha.hotelRooms} />
                            <Divider />
                          </div>
                        :
                        ''
                        }
                        

                        <Grid container>
                          <Grid item xs={6} md={6}>
                            <div>
                              <Bold text={item.room} fontSize={'1.4rem'} />
                              <Semi text={'Tsh. '+ NumberFormatter(price)} />
                              <br />
                              <Submit
                                text={lugha.view +' '+ lugha.gallery}
                                action={() => showGallery(item.roomID)}
                                inverse
                                size={'narrow'}
                              />
                            </div>
                          </Grid>

                          <Grid item xs={6} md={6}>
                            {/* Cart Counter */}
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                                alignItems: "center",
                              }}
                            >
                              <Primary
                                text={"-"}
                                action={() => {
                                  deleteItem(item, hotelID);
                                }}
                              />

                              <CartCount item={item} hotelID={hotelID} />

                              <Primary
                                text={"+"}
                                action={() => {
                                  // add item to items array

                                  var newItem = {
                                    room: item,
                                    quantity: 1,
                                    // hotelID: hotelID,
                                    // price: price,
                                  };

                                  setItemsCount([...itemsCount, newItem]);
                                }}
                              />
                            </div>
                          </Grid>
                          
                          <Grid item xs={12} md={12} style={{ textAlign: 'left' }}>
                              <Regular text={parse(description.substring(0, 190) + '...')} color={'#000'} />
                          </Grid>

                        <div style={styles.separator}></div>
                        </Grid>
                      </>
                    );
                  })}
                  </> 
              }
              
            </Paper>
          </Grid>
        </Grid>
        :
        <Grid container spacing={2} className="p-10">
          <Grid item xs={12} sm={7}>
            <Paper elevation={4} style={{ padding: '0 20px' }}>
              <Skeleton width={200} height={50} animation={'wave'} />
              <Skeleton width={'auto'} height={300} animation={'wave'} />
            </Paper>
          </Grid>

          <Grid item xs={12} sm={5}>
            <Paper elevation={4} style={{ padding: '10px 40px' }}>
              <Skeleton width={'auto'} height={80} animation={'wave'} />
              <Skeleton width={'auto'} height={80} animation={'wave'} />
              <Skeleton width={'auto'} height={80} animation={'wave'} />
            </Paper>
          </Grid>
        </Grid>
        }
      </div>

      {itemsCount.length > 0 && (
        <ActionFooter
          text={lugha.checkout}
          clickAction={() => setCheckoutForm(true)}
        />
      )}

      {checkoutForm && (
        <Checkout
          total={totalHotelPrice}
          text={lugha.payNow}
          type='hotel'
          currency='Tsh'
          closeAction={() => {
            // dispatch(setCheckoutDetails([]));
            setCheckoutForm(false);
          }}
          items={itemsCount}
          details={[
            {
              type: "text",
              placeholder: "Full Name",
              name: "fullname",
            },
            {
              type: "phone",
              placeholder: "Phone Number",
              name: "phone",
            },
            {
              type: "email",
              placeholder: "Email",
              name: "email",
            },
          ]}
          action={(e) => {
            e.preventDefault();
            CompleteCheckout();
          }}
        />
      )}
    </>
  );
}

export default HotelInfo;

const styles = {
  card: {
    width: 300,
    margin: 80,
    boxShadow: "0px 1px 9px rgba(0, 0, 0, 0.07)",
    display: "flex",
    justifyContent: "center",
  },
  eventDetails: {
    textAlign: "left",
    alignItems: "left",
    flex: 2,
  },
  separator: {
    width: "100%",
    height: 1,
    backgroundColor: "#e0e0e0",
    margin: 10,
  },
  priceDetails: {
    padding: 20,
    backgroundColor: "#f5f5f5",
    textAlign: "center",
    width: "20%",
    borderRadius: 30,
  },

  anem: {
    display: "flex",
    alignItems: "center",
    margin: 5,
  },

  price: {
    fontSize: 30,
    fontWeight: "bold",
    color: "#2BA147",
  },
};
