import React, { useState, useEffect } from 'react'
import Primary from '../Buttons/Primary';
import Button from '@mui/material/Button';
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import AxiosInstance from '../Axios/AxiosConfig';

// const style = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     borderRadius: '15px',
//     width: 400,
//     boxShadow: 24,
//     p: 4,
// };

const useStyles = makeStyles((theme) => ({

    basic: {
        padding: 16,
        width: 300,
        border: "none",
        outline: "none",
        margin: 5,
        fontFamily: "Euclid-Bold",
        color: '#ffffff',
        background: '#252525',
        borderRadius: 15,
        // boxShadow: "0px 1px 9px rgba(0, 0, 0, 0.07)",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center",
        [theme.breakpoints.down('sm')]: {
            width: '78vw',
        },

    },
    formInput: {
        fontSize: 14, 
        textAlign: 'left', 
        fontFamily: "Euclid-Bold", 
        color: '#ffffff',
        position: 'relative',
        left: '2rem',
        top: '0.8rem',
    }

}));
  

//   Sign up function using axios
export default function LogIn({handleCloseLogin}) {

    const classes = useStyles();
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [generalError, setGeneralError] = useState('');
    const [generalSuccess, setGeneralSuccess] = useState('');
    
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    const handlePassword = (e) => {
        setPassword(e.target.value);
    }

    const handleEmail = (e) => {
        setEmail(e.target.value);
    }
    

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            password: password,
            email: email,
        }

        AxiosInstance.post('/authenticate/login/', data)
            .then(res => {
                if(res.data.name){
                    window.sessionStorage.setItem('name', res.data.name)
                    window.sessionStorage.setItem('email', res.data.email)
                    window.sessionStorage.setItem('phone', res.data.phone)
                    window.sessionStorage.setItem('typeID', res.data.typeID)
                    window.sessionStorage.setItem('token', res.data.token)
                    setGeneralSuccess(res.data.message)

                    setTimeout(() => {
                        handleCloseLogin();
                        document.location.reload();
                    }, 1000);
                }
                else{
                    setGeneralError(res.data.message)
                }
                console.log(res);
            })
            .catch(err => {
                console.log(err);
            })
    }

    //declare lugha
    var lugha = JSON.parse(window.sessionStorage.getItem('lugha'))
  
    const errorStyles = {
        width: 'inherit',
        height: '50px',
        //left: matches ? '' : '6rem',
        position: 'absolute',
        borderRadius: '15px',
        padding: '4px 8px',
        top: '1rem',
        backgroundColor: '#D84646',
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '-2px 6px 15px 0px rgba(51,51,51,0.43)',
        '-webkit-box-shadow': '-2px 6px 15px 0px rgba(51,51,51,0.43)',
        '-moz-box-shadow': '-2px 6px 15px 0px rgba(51,51,51,0.43)',
        zIndex: '1000'
    }

    const successStyles = {
        width: 'inherit',
        height: '50px',
        //left: matches ? '' : '6rem',
        position: 'absolute',
        borderRadius: '15px',
        padding: '4px 8px',
        top: '1rem',
        backgroundColor: '#2DA148',
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '-2px 6px 15px 0px rgba(51,51,51,0.43)',
        '-webkit-box-shadow': '-2px 6px 15px 0px rgba(51,51,51,0.43)',
        '-moz-box-shadow': '-2px 6px 15px 0px rgba(51,51,51,0.43)',
        zIndex: '1000'
    }
        
    useEffect(() => {
        setTimeout(() => {
            setGeneralError('');
        }, 3000)
    }, [generalError])
            
    useEffect(() => {
        setTimeout(() => {
            setGeneralSuccess('');
        }, 3000)
    }, [generalSuccess])

    return (

        <form onSubmit={handleSubmit} style={{ flexDirection: 'row', padding: '5px 12px', color: '#ffffff' }}>
            <div style={{ paddingTop: '2rem' }}><h1>{lugha.logInText}</h1></div>

            {/* Error div */}
            {generalError && <div style={errorStyles}>{generalError}</div>}
            
            {/* Success div */}
            {generalSuccess && <div style={successStyles}>{generalSuccess}</div>}

            <div>
                <label>
                    <span className={classes.formInput}>{lugha.email}</span> <br />
                    <input required type="email" className={classes.basic} name="email" placeholder={lugha.email} onChange={handleEmail} />
                </label>
            </div>
            <div>
                <label>
                    <span className={classes.formInput}>{lugha.password}</span> <br />
                    <input required type="password" className={classes.basic} name="password" placeholder={lugha.password} onChange={handlePassword} />
                </label>
            </div>

            <div>
                <Button onClick={handleSubmit}>
                    <Primary text={lugha.logInButton} />
                </Button>
            </div>
            

        </form>

    )
}