import React, { useState, useEffect } from "react";
import BInput from "./../Inputs/BInput";
import DatePicker from "./../Inputs/DatePicker";
import DropInput from "./../Inputs/DropInput";
import DInput from "../Inputs/DInput";
import Primary from "./../Buttons/Primary";
import Submit from "./../Buttons/Submit";
import { Link, useHistory, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { makeStyles } from "@material-ui/core/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useDispatch, useSelector } from "react-redux";
import {
  addBusForm,
  selectBusForm,
  setAvailableBuses,
  selectAvailableBuses,
  setChosenBus,
  setBookedSeat,
} from "../../slices/NavSlice";
import { BottomPattern, GreenLogo } from "../../assets/images";
import Tiketika from "../Axios/AxiosConfig";
import DateTabs from "../Main/DateTabx";
import Regular from "../Text/Regular";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "5%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginBottom: "5%",
    },
  },
  formHome: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-end",
    marginTop: "27%",
    marginRight: "15%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginBottom: "5%",
    },
  },

  formHomeS: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    //marginTop: '92%',
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginBottom: "5%",
      paddingTop: "16%",
    },
  },
}));

const regionOptions = [
  { value: "Arusha", label: "Arusha" },
  { value: "Dar es Salaam", label: "Dar es Salaam" },
  { value: "Dodoma", label: "Dodoma" },
  { value: "Mwanza", label: "Mwanza" },
];

function BusForm() {
  // // Once regions are loaded, refresh
  // window.addEventListener('regionsLoaded', function() {
  //   window.location.reload();
  // });

  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const browserLocation = useLocation();

  const busGlobalState = useSelector(selectBusForm);
  const small = useMediaQuery(theme.breakpoints.down("sm"));
  const [regions, setRegions] = useState(window.sessionStorage.getItem('regions') ? 
  JSON.parse(window.sessionStorage.getItem('regions')) : []);
  
  // Form variables
  const today = new Date();
  const [tripType, setTripType] = useState('One-way')
  const tripTypeButton = { position: 'absolute', marginTop: '-3rem' }

  const [origin, setOrigin] = useState(busGlobalState ? busGlobalState.origin : "");
  const [destination, setDestination] = useState(busGlobalState ? busGlobalState.destination : "");
  const [date, setDate] = useState(busGlobalState.date ? busGlobalState.date : today.toISOString().slice(0, 10));
  const [returnDate, setReturnDate] = useState(busGlobalState.returnDate ? busGlobalState.returnDate : null);
  const [generalError, setGeneralError] = useState("");

  async function postBusFormData() {
    try {
            
      // Check if the date is before today
      let parsedDate = moment(date);
      if (parsedDate.isBefore(moment(), 'day')) {
        setDate(moment().format('YYYY-MM-DD'));
      }
      const response = await Tiketika.post("/search/?type=bus", {
        origin, destination, date, returnDate,
      });

      const content = response.data;
      console.log(content)
      if (content.buses && content.buses.length === 0) {
        console.log("error");
        dispatch(setAvailableBuses(null));
        setGeneralError("No buses found for this route");
      } 
      else {
        dispatch(setAvailableBuses(content.buses));
        dispatch(setChosenBus([]));
        dispatch(setBookedSeat([]));
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (origin && destination && date && tripType && origin !== destination) {
      dispatch(addBusForm({ origin, destination, date, tripType, returnDate }));
      //postBusFormData();
    } 
    else if (origin && destination && date && origin == destination) {
      setGeneralError("Please enter correct info!");
    }
  }, [origin, destination, date, returnDate]);

  useEffect(() => {
    setTimeout(() => {
      setGeneralError("");
    }, 5000);
  }, [generalError]);

  //declare lugha
  var lugha = JSON.parse(window.sessionStorage.getItem("lugha"));

  return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        {browserLocation.pathname === "/" ? (
          <div className="flex justify-center align-center pt-5 pb-5 mb-10">
            <div className="flex flex-col px-4 py-20 bg-[#333] bg-opacity-90 w-[80%] align-center">
              <form action="#" class="my-2">
                
                <div style={small ? {} : tripTypeButton}>
                  <FormControl sx={{ m: 1, minWidth: 100 }}>
                    <Select
                      id="tripType"
                      value={tripType}
                      onChange={(e) => setTripType(e.target.value)}
                      style={{ background: '#5F5F5F' }}
                      IconComponent={() => <ArrowDropDownIcon style={{ color: '#FFF' }} />} 
                      inputProps={{ sx: { padding: '6px', background: '#5F5F5F' } }}
                      MenuProps={{
                        PaperProps: {
                          style: {background: '#5F5F5F'},
                        },
                      }}
                    >
                      <MenuItem key={'One-way'} value={'One-way'}><Regular text={'One-way Trip'} /></MenuItem>
                      <MenuItem key={'Round'} value={'Round'}><Regular text={'Round Trip'} /></MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div class="flex w-full justify-around flex-col md:flex-row">
                  
                  <div className="w-full mr-2">
                    
                    <FormControl sx={{ m: 1, minWidth: 200 }}>
                      <InputLabel id="origin-label" style={{ color: '#FFF', backgroundColor: 'rgba(51, 51, 51, 0.8)', padding: '0 2px' }}>{lugha.busOrigin}</InputLabel>
                      <Select
                        labelId="origin-label"
                        id="origin"
                        value={origin}
                        label={lugha.busOrigin}
                        style={{ color: '#FFF', border: '1px solid #FFF' }}
                        onChange={(e) => setOrigin(e.target.value)}
                        IconComponent={() => <ArrowDropDownIcon style={{ color: '#FFF' }} />} 
                        MenuProps={{
                          PaperProps: {
                            style: {backgroundColor: 'rgba(51, 51, 51, 1)'},
                          },
                        }}
                      >
                        {
                          regions.map((region) => 
                            <MenuItem key={region.regionID} value={region.regionID}><Regular text={region.region} /></MenuItem>
                          )
                        }
                      </Select>
                    </FormControl>

                    <FormControl sx={{ m: 1, minWidth: 200 }}>
                      <InputLabel id="destination-label" style={{ color: '#FFF', backgroundColor: 'rgba(51, 51, 51, 0.8)', padding: '0 2px' }}>{lugha.busDestination}</InputLabel>
                      <Select
                        labelId="destination-label"
                        id="destination"
                        value={destination}
                        label={lugha.busDestination}
                        style={{ color: '#FFF', border: '1px solid #FFF' }}
                        onChange={(e) => setDestination(e.target.value)}
                        IconComponent={() => <ArrowDropDownIcon style={{ color: '#FFF' }} />} 
                        MenuProps={{
                          PaperProps: {
                            style: {backgroundColor: 'rgba(51, 51, 51, 1)'},
                          },
                        }}
                      >
                        {
                          regions.map((region) => 
                            <MenuItem key={region.regionID} value={region.regionID}><Regular text={region.region} /></MenuItem>
                          )
                        }
                      </Select>
                    </FormControl>

                    <DatePicker
                      placeholder={lugha.busDeparture}
                      value={date}
                      // color={'#1F1F1F'}
                      // labelColor={'#5F5F5F'}
                      color={'#FFF'}
                      labelColor={'#FFF'}
                      onchange={(value) =>
                        setDate(moment(value).format("YYYY-MM-DD"))
                      }
                    />
                    {tripType === 'Round' && 
                      <DatePicker
                        placeholder={lugha.busReturn}
                        value={returnDate}
                        color={'#FFF'}
                        labelColor={'#FFF'}
                        onchange={(value) =>
                          setReturnDate(moment(value).format("YYYY-MM-DD"))
                        }
                      />
                    }
                    <div>
                      {origin && destination &&
                        origin !== destination && (
                          <Submit
                            text={lugha.search}
                            iconName={faMagnifyingGlass}
                            pathTo="/buses"
                            action={() => postBusFormData()}
                          />
                        )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        ) : (
          <div class="relative flex flex-col items-center justify-center w-full bg-[#333] pt-16 pb-8">
            <form action="#" class="my-2">
              
              <div style={small ? {} : tripTypeButton}>
                <FormControl sx={{ m: 1, minWidth: 100 }}>
                  <Select
                    id="tripType"
                    value={tripType}
                    onChange={(e) => setTripType(e.target.value)}
                    style={{ background: '#5F5F5F' }}
                    IconComponent={() => <ArrowDropDownIcon style={{ color: '#FFF' }} />} 
                    inputProps={{ sx: { padding: '6px', background: '#5F5F5F' } }}
                    MenuProps={{
                      PaperProps: {
                        style: {background: '#5F5F5F'},
                      },
                    }}
                  >
                    <MenuItem key={'One-way'} value={'One-way'}><Regular text={'One-way Trip'} /></MenuItem>
                    <MenuItem key={'Round'} value={'Round'}><Regular text={'Round Trip'} /></MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div class="flex w-full justify-around flex-col md:flex-row">
                
                <div className="w-full mr-2">
                  
                  <FormControl sx={{ m: 1, minWidth: 200 }}>
                    <InputLabel id="origin-label" style={{ color: '#FFF', background: '#333', padding: '0 2px' }}>{lugha.busOrigin}</InputLabel>
                    <Select
                      labelId="origin-label"
                      id="origin"
                      value={origin}
                      label={lugha.busOrigin}
                      style={{ color: '#FFF', border: '1px solid #FFF' }}
                      onChange={(e) => setOrigin(e.target.value)}
                      IconComponent={() => <ArrowDropDownIcon style={{ color: '#FFF' }} />} 
                      MenuProps={{
                        PaperProps: {
                          style: {background: '#5F5F5F'},
                        },
                      }}
                    >
                      {
                        regions.map((region) => 
                          <MenuItem key={region.regionID} value={region.regionID}><Regular text={region.region} /></MenuItem>
                        )
                      }
                    </Select>
                  </FormControl>

                  <FormControl sx={{ m: 1, minWidth: 200 }}>
                    <InputLabel id="destination-label" style={{ color: '#FFF', background: '#333', padding: '0 2px' }}>{lugha.busDestination}</InputLabel>
                    <Select
                      labelId="destination-label"
                      id="destination"
                      value={destination}
                      label={lugha.busDestination}
                      style={{ color: '#FFF', border: '1px solid #FFF' }}
                      onChange={(e) => setDestination(e.target.value)}
                      IconComponent={() => <ArrowDropDownIcon style={{ color: '#FFF' }} />} 
                      MenuProps={{
                        PaperProps: {
                          style: {background: '#5F5F5F'},
                        },
                      }}
                    >
                      {
                        regions.map((region) => 
                          <MenuItem key={region.regionID} value={region.regionID}><Regular text={region.region} /></MenuItem>
                        )
                      }
                    </Select>
                  </FormControl>

                  <DatePicker
                    placeholder={lugha.busDeparture}
                    value={date}
                    // color={'#1F1F1F'}
                    // labelColor={'#5F5F5F'}
                    color={'#FFF'}
                    labelColor={'#FFF'}
                    onchange={(value) =>
                      setDate(moment(value).format("YYYY-MM-DD"))
                    }
                  />
                  {tripType === 'Round' && 
                    <DatePicker
                      placeholder={lugha.busReturn}
                      value={returnDate}
                      color={'#FFF'}
                      labelColor={'#FFF'}
                      onchange={(value) =>
                        setReturnDate(moment(value).format("YYYY-MM-DD"))
                      }
                    />
                  }
                  <div style={{ display: 'inline-flex' }}>
                    <Submit
                      text={lugha.search}
                      iconName={faMagnifyingGlass}
                      pathTo="/buses"
                      action={() => postBusFormData()}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        )}
      </LocalizationProvider>
  );
}

export default BusForm;
