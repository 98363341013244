
const popularHotelCities = [
    {
        "cityID": "807",
        "cityCode": "212101",
        "cityName": "Dubai",
        "countryCode": "AE",
        "countryName": "United Arab Emirates"
    },
    {
        "cityID": "3924",
        "cityCode": "213199",
        "cityName": "Ajman",
        "countryCode": "AE",
        "countryName": "United Arab Emirates"
    },
    {
        "cityID": "6043",
        "cityCode": "211916",
        "cityName": "Sharjah",
        "countryCode": "AE",
        "countryName": "United Arab Emirates"
    },
    {
        "cityID": "6962",
        "cityCode": "211117",
        "cityName": "Abu Dhabi",
        "countryCode": "AE",
        "countryName": "United Arab Emirates"
    },
    {
        "cityID": "11990",
        "cityCode": "210942",
        "cityName": "Al Ain",
        "countryCode": "AE",
        "countryName": "United Arab Emirates"
    },
    {
        "cityID": "24300",
        "cityCode": "10003396",
        "cityName": "Fujairah",
        "countryCode": "AE",
        "countryName": "United Arab Emirates"
    },
    {
        "cityID": "24301",
        "cityCode": "213282",
        "cityName": "Ras Al Khaimah",
        "countryCode": "AE",
        "countryName": "United Arab Emirates"
    },
    {
        "cityID": "24304",
        "cityCode": "213256",
        "cityName": "Dibba",
        "countryCode": "AE",
        "countryName": "United Arab Emirates"
    },
    {
        "cityID": "46663",
        "cityCode": "213251",
        "cityName": "Al Aqah",
        "countryCode": "AE",
        "countryName": "United Arab Emirates"
    },
    {
        "cityID": "48680",
        "cityCode": "10003350",
        "cityName": "Al Barsha",
        "countryCode": "ae",
        "countryName": "United Arab Emirates"
    },
    {
        "cityID": "54439",
        "cityCode": "211846",
        "cityName": "Khor Fakkan",
        "countryCode": "AE",
        "countryName": "United Arab Emirates"
    },
    {
        "cityID": "72540",
        "cityCode": "10003361",
        "cityName": "Al Jazirah Al Hamra",
        "countryCode": "AE",
        "countryName": "United Arab Emirates"
    },
    {
        "cityID": "76303",
        "cityCode": "211700",
        "cityName": "Ghayathi",
        "countryCode": "AE",
        "countryName": "United Arab Emirates"
    },
    {
        "cityID": "76307",
        "cityCode": "10003326",
        "cityName": "Deira",
        "countryCode": "ae",
        "countryName": "United Arab Emirates"
    },
    {
        "cityID": "76769",
        "cityCode": "213261",
        "cityName": "Al-Fujairah",
        "countryCode": "AE",
        "countryName": "United Arab Emirates"
    },
    {
        "cityID": "76806",
        "cityCode": "211107",
        "cityName": "Madinat Zayed",
        "countryCode": "AE",
        "countryName": "United Arab Emirates"
    },
    {
        "cityID": "76811",
        "cityCode": "212084",
        "cityName": "Umm al Quwain",
        "countryCode": "AE",
        "countryName": "United Arab Emirates"
    },
    {
        "cityID": "78751",
        "cityCode": "211875",
        "cityName": "Al Dhaid",
        "countryCode": "AE",
        "countryName": "United Arab Emirates"
    },
    {
        "cityID": "79223",
        "cityCode": "211762",
        "cityName": "Ghantoot",
        "countryCode": "AE",
        "countryName": "United Arab Emirates"
    },
    {
        "cityID": "80606",
        "cityCode": "213271",
        "cityName": "Bidya",
        "countryCode": "AE",
        "countryName": "United Arab Emirates"
    },
    {
        "cityID": "90780",
        "cityCode": "211819",
        "cityName": "Hatta",
        "countryCode": "AE",
        "countryName": "United Arab Emirates"
    },
    {
        "cityID": "99552",
        "cityCode": "211708",
        "cityName": "Sir Bani Yas Island",
        "countryCode": "AE",
        "countryName": "United Arab Emirates"
    },
    {
        "cityID": "102338",
        "cityCode": "211782",
        "cityName": "Mahdar Bin Usayyan",
        "countryCode": "AE",
        "countryName": "United Arab Emirates"
    },
    {
        "cityID": "109083",
        "cityCode": "211734",
        "cityName": "Abu Krayyah",
        "countryCode": "AE",
        "countryName": "United Arab Emirates"
    },
    {
        "cityID": "110700",
        "cityCode": "211750",
        "cityName": "Jebel Dhanna",
        "countryCode": "AE",
        "countryName": "United Arab Emirates"
    },
    {
        "cityID": "110952",
        "cityCode": "211758",
        "cityName": "Al Faqa",
        "countryCode": "AE",
        "countryName": "United Arab Emirates"
    },
    {
        "cityID": "113681",
        "cityCode": "323427",
        "cityName": "Catumbela",
        "countryCode": "AO",
        "countryName": "Angola"
    },
    {
        "cityID": "1044",
        "cityCode": "323240",
        "cityName": "Luanda",
        "countryCode": "AO",
        "countryName": "Angola"
    },
    {
        "cityID": "4442",
        "cityCode": "323323",
        "cityName": "Benguela",
        "countryCode": "AO",
        "countryName": "Angola"
    },
    {
        "cityID": "5629",
        "cityCode": "323198",
        "cityName": "Calulo",
        "countryCode": "AO",
        "countryName": "Angola"
    },
    {
        "cityID": "11550",
        "cityCode": "323440",
        "cityName": "Sumbe",
        "countryCode": "AO",
        "countryName": "Angola"
    },
    {
        "cityID": "16481",
        "cityCode": "323445",
        "cityName": "Bairro Caila",
        "countryCode": "AO",
        "countryName": "Angola"
    },
    {
        "cityID": "27",
        "cityCode": "377560",
        "cityName": "Sydney",
        "countryCode": "AU",
        "countryName": "Australia"
    },
    {
        "cityID": "35",
        "cityCode": "10003079",
        "cityName": "Buddina",
        "countryCode": "AU",
        "countryName": "Australia"
    },
    {
        "cityID": "58",
        "cityCode": "347865",
        "cityName": "Palmtree",
        "countryCode": "AU",
        "countryName": "Australia"
    },
    {
        "cityID": "673",
        "cityCode": "371007",
        "cityName": "Coffs Harbour",
        "countryCode": "AU",
        "countryName": "Australia"
    },
    {
        "cityID": "792",
        "cityCode": "372107",
        "cityName": "Nelson Bay",
        "countryCode": "AU",
        "countryName": "Australia"
    },
    {
        "cityID": "808",
        "cityCode": "351168",
        "cityName": "Brisbane",
        "countryCode": "AU",
        "countryName": "Australia"
    },
    {
        "cityID": "900",
        "cityCode": "361787",
        "cityName": "Melbourne",
        "countryCode": "AU",
        "countryName": "Australia"
    },
    {
        "cityID": "924",
        "cityCode": "375205",
        "cityName": "Broken Hill",
        "countryCode": "AU",
        "countryName": "Australia"
    },
    {
        "cityID": "929",
        "cityCode": "355494",
        "cityName": "Inverloch",
        "countryCode": "AU",
        "countryName": "Australia"
    },
    {
        "cityID": "1007",
        "cityCode": "364478",
        "cityName": "Cowleds Landing",
        "countryCode": "AU",
        "countryName": "Australia"
    },
    {
        "cityID": "1260",
        "cityCode": "371679",
        "cityName": "Newcastle",
        "countryCode": "AU",
        "countryName": "Australia"
    },
    {
        "cityID": "2063",
        "cityCode": "348574",
        "cityName": "Blackwater",
        "countryCode": "AU",
        "countryName": "Australia"
    },
    {
        "cityID": "2099",
        "cityCode": "369360",
        "cityName": "Busselton",
        "countryCode": "AU",
        "countryName": "Australia"
    },
    {
        "cityID": "2230",
        "cityCode": "361113",
        "cityName": "Marysville",
        "countryCode": "AU",
        "countryName": "Australia"
    },
    {
        "cityID": "2248",
        "cityCode": "369979",
        "cityName": "Perth",
        "countryCode": "AU",
        "countryName": "Australia"
    },
    {
        "cityID": "2357",
        "cityCode": "345892",
        "cityName": "Townsville",
        "countryCode": "AU",
        "countryName": "Australia"
    },
    {
        "cityID": "2379",
        "cityCode": "358935",
        "cityName": "Port Campbell",
        "countryCode": "AU",
        "countryName": "Australia"
    },
    {
        "cityID": "2448",
        "cityCode": "360553",
        "cityName": "Daylesford",
        "countryCode": "AU",
        "countryName": "Australia"
    },
    {
        "cityID": "2655",
        "cityCode": "355093",
        "cityName": "Alice Springs",
        "countryCode": "AU",
        "countryName": "Australia"
    },
    {
        "cityID": "119510",
        "cityCode": "732669",
        "cityName": "Great Harbour Cay",
        "countryCode": "BS",
        "countryName": "Bahamas"
    },
    {
        "cityID": "119512",
        "cityCode": "732784",
        "cityName": "Matthew Town",
        "countryCode": "BS",
        "countryName": "Bahamas"
    },
    {
        "cityID": "119513",
        "cityCode": "732985",
        "cityName": "Seymour's",
        "countryCode": "BS",
        "countryName": "Bahamas"
    },
    {
        "cityID": "3813",
        "cityCode": "324922",
        "cityName": "Bujumbura",
        "countryCode": "BI",
        "countryName": "Burundi"
    },
    {
        "cityID": "39623",
        "cityCode": "324974",
        "cityName": "Gitega",
        "countryCode": "BI",
        "countryName": "Burundi"
    },
    {
        "cityID": "98",
        "cityCode": "203513",
        "cityName": "Teresopolis",
        "countryCode": "BR",
        "countryName": "Brazil"
    },
    {
        "cityID": "109",
        "cityCode": "198225",
        "cityName": "Aparecida",
        "countryCode": "BR",
        "countryName": "Brazil"
    },
    {
        "cityID": "117",
        "cityCode": "178867",
        "cityName": "Ji Parana",
        "countryCode": "BR",
        "countryName": "Brazil"
    },
    {
        "cityID": "143",
        "cityCode": "203807",
        "cityName": "Arraial do Cabo",
        "countryCode": "BR",
        "countryName": "Brazil"
    },
    {
        "cityID": "168",
        "cityCode": "201938",
        "cityName": "Bertioga",
        "countryCode": "BR",
        "countryName": "Brazil"
    },
    {
        "cityID": "178",
        "cityCode": "204084",
        "cityName": "Rio de Janeiro",
        "countryCode": "BR",
        "countryName": "Brazil"
    },
    {
        "cityID": "186",
        "cityCode": "10000571",
        "cityName": "Sao Paulo",
        "countryCode": "BR",
        "countryName": "Brazil"
    },
    {
        "cityID": "56920",
        "cityCode": "188216",
        "cityName": "Colombo",
        "countryCode": "BR",
        "countryName": "Brazil"
    },
    {
        "cityID": "112770",
        "cityCode": "207538",
        "cityName": "Santa Maria",
        "countryCode": "BR",
        "countryName": "Brazil"
    },
    {
        "cityID": "1527",
        "cityCode": "733233",
        "cityName": "George Town",
        "countryCode": "BS",
        "countryName": "Bahamas"
    },
    {
        "cityID": "5949",
        "cityCode": "733980",
        "cityName": "Nassau",
        "countryCode": "BS",
        "countryName": "Bahamas"
    },
    {
        "cityID": "113695",
        "cityCode": "324882",
        "cityName": "Muyinga",
        "countryCode": "BI",
        "countryName": "Burundi"
    },
    {
        "cityID": "2892",
        "cityCode": "324801",
        "cityName": "Gaborone",
        "countryCode": "BW",
        "countryName": "Botswana"
    },
    {
        "cityID": "4012",
        "cityCode": "324837",
        "cityName": "Francistown",
        "countryCode": "BW",
        "countryName": "Botswana"
    },
    {
        "cityID": "5210",
        "cityCode": "324379",
        "cityName": "Kasane",
        "countryCode": "BW",
        "countryName": "Botswana"
    },
    {
        "cityID": "36529",
        "cityCode": "324292",
        "cityName": "Central Kalahari Game Reserve",
        "countryCode": "BW",
        "countryName": "Botswana"
    },
    {
        "cityID": "68431",
        "cityCode": "324333",
        "cityName": "Chobe National Park",
        "countryCode": "BW",
        "countryName": "Botswana"
    },
    {
        "cityID": "97823",
        "cityCode": "324547",
        "cityName": "Nxai Pan National Park",
        "countryCode": "BW",
        "countryName": "Botswana"
    },
    {
        "cityID": "62",
        "cityCode": "7628",
        "cityName": "Niagara-on-the-Lake",
        "countryCode": "CA",
        "countryName": "Canada"
    },
    {
        "cityID": "81",
        "cityCode": "11688",
        "cityName": "Toronto",
        "countryCode": "CA",
        "countryName": "Canada"
    },
    {
        "cityID": "279",
        "cityCode": "10043",
        "cityName": "Waterloo",
        "countryCode": "CA",
        "countryName": "Canada"
    },
    {
        "cityID": "291",
        "cityCode": "20519",
        "cityName": "White Rock",
        "countryCode": "CA",
        "countryName": "Canada"
    },
    {
        "cityID": "294",
        "cityCode": "9820",
        "cityName": "North Grenville",
        "countryCode": "CA",
        "countryName": "Canada"
    },
    {
        "cityID": "314",
        "cityCode": "4823",
        "cityName": "Edmonton",
        "countryCode": "CA",
        "countryName": "Canada"
    },
    {
        "cityID": "562",
        "cityCode": "16414",
        "cityName": "Winnipeg",
        "countryCode": "CA",
        "countryName": "Canada"
    },
    {
        "cityID": "1060",
        "cityCode": "30694",
        "cityName": "Saint-Alexis-des-Monts",
        "countryCode": "CA",
        "countryName": "Canada"
    },
    {
        "cityID": "1082",
        "cityCode": "20749",
        "cityName": "Coquitlam",
        "countryCode": "CA",
        "countryName": "Canada"
    },
    {
        "cityID": "1089",
        "cityCode": "17585",
        "cityName": "Victoria",
        "countryCode": "CA",
        "countryName": "Canada"
    },
    {
        "cityID": "1468",
        "cityCode": "19992",
        "cityName": "Vancouver",
        "countryCode": "CA",
        "countryName": "Canada"
    },
    {
        "cityID": "1551",
        "cityCode": "37469",
        "cityName": "St. Johns",
        "countryCode": "CA",
        "countryName": "Canada"
    },
    {
        "cityID": "1864",
        "cityCode": "5166",
        "cityName": "Calgary",
        "countryCode": "CA",
        "countryName": "Canada"
    },
    {
        "cityID": "1865",
        "cityCode": "35908",
        "cityName": "Stratford",
        "countryCode": "CA",
        "countryName": "Canada"
    },
    {
        "cityID": "3669",
        "cityCode": "533403",
        "cityName": "Geneva",
        "countryCode": "CH",
        "countryName": "Switzerland"
    },
    {
        "cityID": "3673",
        "cityCode": "511574",
        "cityName": "Davos",
        "countryCode": "CH",
        "countryName": "Switzerland"
    },
    {
        "cityID": "3674",
        "cityCode": "530919",
        "cityName": "Grindelwald",
        "countryCode": "CH",
        "countryName": "Switzerland"
    },
    {
        "cityID": "3917",
        "cityCode": "519653",
        "cityName": "Renens",
        "countryCode": "CH",
        "countryName": "Switzerland"
    },
    {
        "cityID": "4179",
        "cityCode": "522277",
        "cityName": "Lugano",
        "countryCode": "CH",
        "countryName": "Switzerland"
    },
    {
        "cityID": "4201",
        "cityCode": "518676",
        "cityName": "Ollon",
        "countryCode": "ch",
        "countryName": "Switzerland"
    },
    {
        "cityID": "4202",
        "cityCode": "515105",
        "cityName": "Crans-Montana",
        "countryCode": "CH",
        "countryName": "Switzerland"
    },
    {
        "cityID": "4321",
        "cityCode": "510666",
        "cityName": "Vals",
        "countryCode": "CH",
        "countryName": "Switzerland"
    },
    {
        "cityID": "276",
        "cityCode": "334450",
        "cityName": "Abidjan",
        "countryCode": "CI",
        "countryName": "Cote D Ivoire"
    },
    {
        "cityID": "2826",
        "cityCode": "324930",
        "cityName": "Douala",
        "countryCode": "CM",
        "countryName": "Cameroon"
    },
    {
        "cityID": "4117",
        "cityCode": "325306",
        "cityName": "Limbe",
        "countryCode": "CM",
        "countryName": "Cameroon"
    },
    {
        "cityID": "4860",
        "cityCode": "325064",
        "cityName": "Yaounde",
        "countryCode": "CM",
        "countryName": "Cameroon"
    },
    {
        "cityID": "1",
        "cityCode": "307966",
        "cityName": "Zhuhai",
        "countryCode": "CN",
        "countryName": "China"
    },
    {
        "cityID": "5",
        "cityCode": "304828",
        "cityName": "Guangzhou",
        "countryCode": "CN",
        "countryName": "China"
    },
    {
        "cityID": "22",
        "cityCode": "308115",
        "cityName": "Shanghai",
        "countryCode": "CN",
        "countryName": "China"
    },
    {
        "cityID": "23",
        "cityCode": "295354",
        "cityName": "Yancheng",
        "countryCode": "CN",
        "countryName": "China"
    },
    {
        "cityID": "24",
        "cityCode": "274998",
        "cityName": "Huangshan",
        "countryCode": "CN",
        "countryName": "China"
    },
    {
        "cityID": "25",
        "cityCode": "289405",
        "cityName": "Weihai",
        "countryCode": "CN",
        "countryName": "China"
    },
    {
        "cityID": "26",
        "cityCode": "290523",
        "cityName": "Dalian",
        "countryCode": "CN",
        "countryName": "China"
    },
    {
        "cityID": "29",
        "cityCode": "278376",
        "cityName": "Dali",
        "countryCode": "CN",
        "countryName": "China"
    },
    {
        "cityID": "30",
        "cityCode": "289659",
        "cityName": "Taiyuan",
        "countryCode": "CN",
        "countryName": "China"
    },
    {
        "cityID": "31",
        "cityCode": "297891",
        "cityName": "Kaifeng",
        "countryCode": "CN",
        "countryName": "China"
    },
    {
        "cityID": "37",
        "cityCode": "293460",
        "cityName": "Yangzhou",
        "countryCode": "CN",
        "countryName": "China"
    },
    {
        "cityID": "45",
        "cityCode": "286279",
        "cityName": "Suining",
        "countryCode": "CN",
        "countryName": "China"
    },
    {
        "cityID": "80",
        "cityCode": "283133",
        "cityName": "Hangzhou",
        "countryCode": "CN",
        "countryName": "China"
    },
    {
        "cityID": "112",
        "cityCode": "300782",
        "cityName": "Guilin",
        "countryCode": "CN",
        "countryName": "China"
    },
    {
        "cityID": "160",
        "cityCode": "285565",
        "cityName": "Chengdu",
        "countryCode": "CN",
        "countryName": "China"
    },
    {
        "cityID": "161",
        "cityCode": "289087",
        "cityName": "Taian",
        "countryCode": "CN",
        "countryName": "China"
    },
    {
        "cityID": "183",
        "cityCode": "279081",
        "cityName": "Lijiang",
        "countryCode": "CN",
        "countryName": "China"
    },
    {
        "cityID": "195",
        "cityCode": "309066",
        "cityName": "Tianjin",
        "countryCode": "CN",
        "countryName": "China"
    },
    {
        "cityID": "204",
        "cityCode": "10001195",
        "cityName": "Tengchong",
        "countryCode": "CN",
        "countryName": "China"
    },
    {
        "cityID": "209",
        "cityCode": "304237",
        "cityName": "Jiuquan",
        "countryCode": "CN",
        "countryName": "China"
    },
    {
        "cityID": "211",
        "cityCode": "310301",
        "cityName": "Beijing",
        "countryCode": "CN",
        "countryName": "China"
    },
    {
        "cityID": "212",
        "cityCode": "300106",
        "cityName": "Zhuzhou",
        "countryCode": "CN",
        "countryName": "China"
    },
    {
        "cityID": "341",
        "cityCode": "299110",
        "cityName": "Zhangjiajie",
        "countryCode": "CN",
        "countryName": "China"
    },
    {
        "cityID": "365",
        "cityCode": "298774",
        "cityName": "Wuhan",
        "countryCode": "CN",
        "countryName": "China"
    },
    {
        "cityID": "367",
        "cityCode": "10000739",
        "cityName": "Yangshuo",
        "countryCode": "CN",
        "countryName": "China"
    },
    {
        "cityID": "373",
        "cityCode": "293586",
        "cityName": "Changzhou",
        "countryCode": "CN",
        "countryName": "China"
    },
    {
        "cityID": "419",
        "cityCode": "280791",
        "cityName": "Ningbo",
        "countryCode": "CN",
        "countryName": "China"
    },
    {
        "cityID": "422",
        "cityCode": "292977",
        "cityName": "Nanjing",
        "countryCode": "CN",
        "countryName": "China"
    },
    {
        "cityID": "757",
        "cityCode": "293716",
        "cityName": "Wuxi",
        "countryCode": "CN",
        "countryName": "China"
    },
    {
        "cityID": "759",
        "cityCode": "10001967",
        "cityName": "Yuanyang",
        "countryCode": "CN",
        "countryName": "China"
    },
    {
        "cityID": "769",
        "cityCode": "296694",
        "cityName": "Nanchang",
        "countryCode": "CN",
        "countryName": "China"
    },
    {
        "cityID": "814",
        "cityCode": "307885",
        "cityName": "Shaoguan",
        "countryCode": "CN",
        "countryName": "China"
    },
    {
        "cityID": "823",
        "cityCode": "307698",
        "cityName": "Shantou",
        "countryCode": "CN",
        "countryName": "China"
    },
    {
        "cityID": "839",
        "cityCode": "302293",
        "cityName": "Handan",
        "countryCode": "CN",
        "countryName": "China"
    },
    {
        "cityID": "906",
        "cityCode": "286492",
        "cityName": "Yibin",
        "countryCode": "CN",
        "countryName": "China"
    },
    {
        "cityID": "934",
        "cityCode": "300571",
        "cityName": "Beihai",
        "countryCode": "CN",
        "countryName": "China"
    },
    {
        "cityID": "957",
        "cityCode": "303470",
        "cityName": "Quanzhou",
        "countryCode": "CN",
        "countryName": "China"
    },
    {
        "cityID": "987",
        "cityCode": "292305",
        "cityName": "Xianyang",
        "countryCode": "CN",
        "countryName": "China"
    },
    {
        "cityID": "1287",
        "cityCode": "461811",
        "cityName": "Kiti",
        "countryCode": "CY",
        "countryName": "Cyprus"
    },
    {
        "cityID": "1357",
        "cityCode": "461358",
        "cityName": "Ayia Napa",
        "countryCode": "CY",
        "countryName": "Cyprus"
    },
    {
        "cityID": "1700",
        "cityCode": "462102",
        "cityName": "Protaras",
        "countryCode": "CY",
        "countryName": "Cyprus"
    },
    {
        "cityID": "2997",
        "cityCode": "461491",
        "cityName": "Sotira",
        "countryCode": "CY",
        "countryName": "Cyprus"
    },
    {
        "cityID": "91",
        "cityCode": "562280",
        "cityName": "Gersthofen",
        "countryCode": "DE",
        "countryName": "Germany"
    },
    {
        "cityID": "92",
        "cityCode": "601135",
        "cityName": "Engen",
        "countryCode": "DE",
        "countryName": "Germany"
    },
    {
        "cityID": "99",
        "cityCode": "630204",
        "cityName": "Pronstorf",
        "countryCode": "DE",
        "countryName": "Germany"
    },
    {
        "cityID": "205",
        "cityCode": "617280",
        "cityName": "Limbach-Oberfrohna",
        "countryCode": "DE",
        "countryName": "Germany"
    },
    {
        "cityID": "206",
        "cityCode": "672353",
        "cityName": "Weimar",
        "countryCode": "DE",
        "countryName": "Germany"
    },
    {
        "cityID": "303",
        "cityCode": "560135",
        "cityName": "Oberstdorf",
        "countryCode": "DE",
        "countryName": "Germany"
    },
    {
        "cityID": "320",
        "cityCode": "554583",
        "cityName": "Breitenberg",
        "countryCode": "DE",
        "countryName": "Germany"
    },
    {
        "cityID": "451",
        "cityCode": "562524",
        "cityName": "Bad Kissingen",
        "countryCode": "DE",
        "countryName": "Germany"
    },
    {
        "cityID": "489",
        "cityCode": "574774",
        "cityName": "Aschau im Chiemgau",
        "countryCode": "DE",
        "countryName": "Germany"
    },
    {
        "cityID": "551",
        "cityCode": "598291",
        "cityName": "Waldenbuch",
        "countryCode": "DE",
        "countryName": "Germany"
    },
    {
        "cityID": "717",
        "cityCode": "675275",
        "cityName": "Berlin",
        "countryCode": "DE",
        "countryName": "Germany"
    },
    {
        "cityID": "817",
        "cityCode": "666959",
        "cityName": "Bad Fallingbostel",
        "countryCode": "DE",
        "countryName": "Germany"
    },
    {
        "cityID": "828",
        "cityCode": "658698",
        "cityName": "Weyhausen",
        "countryCode": "DE",
        "countryName": "Germany"
    },
    {
        "cityID": "25997",
        "cityCode": "585937",
        "cityName": "Schmogrow",
        "countryCode": "DE",
        "countryName": "Germany"
    },
    {
        "cityID": "115921",
        "cityCode": "506837",
        "cityName": "Copenhagen",
        "countryCode": "DK",
        "countryName": "Denmark"
    },
    {
        "cityID": "116013",
        "cityCode": "511616",
        "cityName": "Lynge",
        "countryCode": "DK",
        "countryName": "Denmark"
    },
    {
        "cityID": "116035",
        "cityCode": "512638",
        "cityName": "Nordost",
        "countryCode": "DK",
        "countryName": "Denmark"
    },
    {
        "cityID": "116067",
        "cityCode": "514481",
        "cityName": "Lonstrup",
        "countryCode": "DK",
        "countryName": "Denmark"
    },
    {
        "cityID": "108",
        "cityCode": "330733",
        "cityName": "Nuweiba",
        "countryCode": "EG",
        "countryName": "Egypt"
    },
    {
        "cityID": "632",
        "cityCode": "332119",
        "cityName": "Hurghada",
        "countryCode": "EG",
        "countryName": "Egypt"
    },
    {
        "cityID": "13",
        "cityCode": "493935",
        "cityName": "San Bartolome de Tirajana",
        "countryCode": "ES",
        "countryName": "Spain"
    },
    {
        "cityID": "42",
        "cityCode": "503991",
        "cityName": "Barbate",
        "countryCode": "ES",
        "countryName": "Spain"
    },
    {
        "cityID": "66",
        "cityCode": "504011",
        "cityName": "Tarifa",
        "countryCode": "ES",
        "countryName": "Spain"
    },
    {
        "cityID": "74",
        "cityCode": "501256",
        "cityName": "CÃ³rdoba",
        "countryCode": "ES",
        "countryName": "Spain"
    },
    {
        "cityID": "126",
        "cityCode": "489507",
        "cityName": "Son Servera",
        "countryCode": "ES",
        "countryName": "Spain"
    },
    {
        "cityID": "146",
        "cityCode": "492967",
        "cityName": "Granadilla de Abona",
        "countryCode": "ES",
        "countryName": "Spain"
    },
    {
        "cityID": "172",
        "cityCode": "479652",
        "cityName": "Llanes",
        "countryCode": "ES",
        "countryName": "Spain"
    },
    {
        "cityID": "176",
        "cityCode": "501545",
        "cityName": "Carboneras",
        "countryCode": "ES",
        "countryName": "Spain"
    },
    {
        "cityID": "197",
        "cityCode": "505133",
        "cityName": "El Molar",
        "countryCode": "ES",
        "countryName": "Spain"
    },
    {
        "cityID": "374",
        "cityCode": "475318",
        "cityName": "Campo de Criptana",
        "countryCode": "ES",
        "countryName": "Spain"
    },
    {
        "cityID": "474",
        "cityCode": "504564",
        "cityName": "Ronda",
        "countryCode": "ES",
        "countryName": "Spain"
    },
    {
        "cityID": "684",
        "cityCode": "503897",
        "cityName": "Sanlucar de Barrameda",
        "countryCode": "ES",
        "countryName": "Spain"
    },
    {
        "cityID": "778",
        "cityCode": "503807",
        "cityName": "Berchules",
        "countryCode": "ES",
        "countryName": "Spain"
    },
    {
        "cityID": "782",
        "cityCode": "483406",
        "cityName": "Robleda-Cervantes",
        "countryCode": "ES",
        "countryName": "Spain"
    },
    {
        "cityID": "824",
        "cityCode": "496364",
        "cityName": "La Union",
        "countryCode": "ES",
        "countryName": "Spain"
    },
    {
        "cityID": "949",
        "cityCode": "489554",
        "cityName": "Sant Llorenc des Cardassar",
        "countryCode": "ES",
        "countryName": "Spain"
    },
    {
        "cityID": "960",
        "cityCode": "488360",
        "cityName": "Vall-Llobrega",
        "countryCode": "ES",
        "countryName": "Spain"
    },
    {
        "cityID": "998",
        "cityCode": "505174",
        "cityName": "Madrid",
        "countryCode": "ES",
        "countryName": "Spain"
    },
    {
        "cityID": "1041",
        "cityCode": "489732",
        "cityName": "Santanyi",
        "countryCode": "ES",
        "countryName": "Spain"
    },
    {
        "cityID": "1049",
        "cityCode": "503608",
        "cityName": "Lepe",
        "countryCode": "ES",
        "countryName": "Spain"
    },
    {
        "cityID": "1133",
        "cityCode": "470094",
        "cityName": "Bilbao",
        "countryCode": "ES",
        "countryName": "Spain"
    },
    {
        "cityID": "1140",
        "cityCode": "493547",
        "cityName": "Costa Adeje",
        "countryCode": "ES",
        "countryName": "Spain"
    },
    {
        "cityID": "1145",
        "cityCode": "489596",
        "cityName": "Sineu",
        "countryCode": "ES",
        "countryName": "Spain"
    },
    {
        "cityID": "1150",
        "cityCode": "491233",
        "cityName": "Benissa",
        "countryCode": "ES",
        "countryName": "Spain"
    },
    {
        "cityID": "1236",
        "cityCode": "495485",
        "cityName": "Ainsa",
        "countryCode": "ES",
        "countryName": "Spain"
    },
    {
        "cityID": "1244",
        "cityCode": "500295",
        "cityName": "La Coruna",
        "countryCode": "ES",
        "countryName": "Spain"
    },
    {
        "cityID": "1249",
        "cityCode": "498769",
        "cityName": "Foz",
        "countryCode": "ES",
        "countryName": "Spain"
    },
    {
        "cityID": "1255",
        "cityCode": "476935",
        "cityName": "Cuenca",
        "countryCode": "ES",
        "countryName": "Spain"
    },
    {
        "cityID": "1266",
        "cityCode": "499476",
        "cityName": "Santiago de Compostela",
        "countryCode": "ES",
        "countryName": "Spain"
    },
    {
        "cityID": "1271",
        "cityCode": "491164",
        "cityName": "Beniarbeig",
        "countryCode": "ES",
        "countryName": "Spain"
    },
    {
        "cityID": "1275",
        "cityCode": "499126",
        "cityName": "Sarria",
        "countryCode": "ES",
        "countryName": "Spain"
    },
    {
        "cityID": "1737",
        "cityCode": "493661",
        "cityName": "Buenavista del Norte",
        "countryCode": "ES",
        "countryName": "Spain"
    },
    {
        "cityID": "1844",
        "cityCode": "483907",
        "cityName": "Leon",
        "countryCode": "ES",
        "countryName": "Spain"
    },
    {
        "cityID": "1876",
        "cityCode": "479423",
        "cityName": "Cangas de Onis",
        "countryCode": "ES",
        "countryName": "Spain"
    },
    {
        "cityID": "1885",
        "cityCode": "503312",
        "cityName": "Chiclana de la Frontera",
        "countryCode": "ES",
        "countryName": "Spain"
    },
    {
        "cityID": "1898",
        "cityCode": "491686",
        "cityName": "Benidorm",
        "countryCode": "ES",
        "countryName": "Spain"
    },
    {
        "cityID": "1934",
        "cityCode": "493028",
        "cityName": "Vilaflor",
        "countryCode": "ES",
        "countryName": "Spain"
    },
    {
        "cityID": "1948",
        "cityCode": "494377",
        "cityName": "Costa Teguise",
        "countryCode": "ES",
        "countryName": "Spain"
    },
    {
        "cityID": "1964",
        "cityCode": "490576",
        "cityName": "Pollensa",
        "countryCode": "ES",
        "countryName": "Spain"
    },
    {
        "cityID": "1965",
        "cityCode": "489792",
        "cityName": "Sant Antoni de Portmany",
        "countryCode": "ES",
        "countryName": "Spain"
    },
    {
        "cityID": "1990",
        "cityCode": "490712",
        "cityName": "Sant Joan de Labritja",
        "countryCode": "ES",
        "countryName": "Spain"
    },
    {
        "cityID": "1993",
        "cityCode": "10001880",
        "cityName": "Tenerife",
        "countryCode": "ES",
        "countryName": "Spain"
    },
    {
        "cityID": "2010",
        "cityCode": "476195",
        "cityName": "Consuegra",
        "countryCode": "ES",
        "countryName": "Spain"
    },
    {
        "cityID": "2021",
        "cityCode": "480209",
        "cityName": "Valdes",
        "countryCode": "ES",
        "countryName": "Spain"
    },
    {
        "cityID": "2085",
        "cityCode": "10000121",
        "cityName": "Ibiza",
        "countryCode": "ES",
        "countryName": "Spain"
    },
    {
        "cityID": "149",
        "cityCode": "653480",
        "cityName": "Chailles",
        "countryCode": "FR",
        "countryName": "France"
    },
    {
        "cityID": "177",
        "cityCode": "619851",
        "cityName": "Bar-sur-Seine",
        "countryCode": "FR",
        "countryName": "France"
    },
    {
        "cityID": "346",
        "cityCode": "604742",
        "cityName": "Pierrelaye",
        "countryCode": "FR",
        "countryName": "France"
    },
    {
        "cityID": "389",
        "cityCode": "614695",
        "cityName": "Loray",
        "countryCode": "FR",
        "countryName": "France"
    },
    {
        "cityID": "462",
        "cityCode": "592906",
        "cityName": "Arcachon",
        "countryCode": "FR",
        "countryName": "France"
    },
    {
        "cityID": "650",
        "cityCode": "607049",
        "cityName": "Paris",
        "countryCode": "FR",
        "countryName": "France"
    },
    {
        "cityID": "692",
        "cityCode": "580487",
        "cityName": "Saintes-Maries-de-la-Mer",
        "countryCode": "FR",
        "countryName": "France"
    },
    {
        "cityID": "780",
        "cityCode": "658058",
        "cityName": "Lyon",
        "countryCode": "FR",
        "countryName": "France"
    },
    {
        "cityID": "791",
        "cityCode": "566503",
        "cityName": "Carcassonne",
        "countryCode": "FR",
        "countryName": "France"
    },
    {
        "cityID": "796",
        "cityCode": "599998",
        "cityName": "Saint-Georges-les-Baillargeaux",
        "countryCode": "FR",
        "countryName": "France"
    },
    {
        "cityID": "809",
        "cityCode": "556182",
        "cityName": "Guidel",
        "countryCode": "FR",
        "countryName": "France"
    },
    {
        "cityID": "843",
        "cityCode": "667755",
        "cityName": "Arc-1800",
        "countryCode": "FR",
        "countryName": "France"
    },
    {
        "cityID": "862",
        "cityCode": "584964",
        "cityName": "Ramatuelle",
        "countryCode": "FR",
        "countryName": "France"
    },
    {
        "cityID": "890",
        "cityCode": "606901",
        "cityName": "Bry-sur-Marne",
        "countryCode": "FR",
        "countryName": "France"
    },
    {
        "cityID": "896",
        "cityCode": "585236",
        "cityName": "Carqueiranne",
        "countryCode": "FR",
        "countryName": "France"
    },
    {
        "cityID": "910",
        "cityCode": "556732",
        "cityName": "Argol",
        "countryCode": "FR",
        "countryName": "France"
    },
    {
        "cityID": "919",
        "cityCode": "566629",
        "cityName": "Caunes-Minervois",
        "countryCode": "FR",
        "countryName": "France"
    },
    {
        "cityID": "931",
        "cityCode": "585178",
        "cityName": "La Seyne-sur-Mer",
        "countryCode": "FR",
        "countryName": "France"
    },
    {
        "cityID": "936",
        "cityCode": "582118",
        "cityName": "Reillanne",
        "countryCode": "FR",
        "countryName": "France"
    },
    {
        "cityID": "967",
        "cityCode": "604933",
        "cityName": "Taverny",
        "countryCode": "FR",
        "countryName": "France"
    },
    {
        "cityID": "1243",
        "cityCode": "583476",
        "cityName": "Nice",
        "countryCode": "FR",
        "countryName": "France"
    },
    {
        "cityID": "1261",
        "cityCode": "538484",
        "cityName": "Porto-Vecchio",
        "countryCode": "FR",
        "countryName": "France"
    },
    {
        "cityID": "1272",
        "cityCode": "548461",
        "cityName": "Caen",
        "countryCode": "FR",
        "countryName": "France"
    },
    {
        "cityID": "1326",
        "cityCode": "592049",
        "cityName": "Bordeaux",
        "countryCode": "FR",
        "countryName": "France"
    },
    {
        "cityID": "6",
        "cityCode": "540025",
        "cityName": "London",
        "countryCode": "GB",
        "countryName": "United Kingdom"
    },
    {
        "cityID": "154",
        "cityCode": "552431",
        "cityName": "Leicester",
        "countryCode": "GB",
        "countryName": "United Kingdom"
    },
    {
        "cityID": "324",
        "cityCode": "567943",
        "cityName": "York",
        "countryCode": "GB",
        "countryName": "United Kingdom"
    },
    {
        "cityID": "111580",
        "cityCode": "10002355",
        "cityName": "Hassel (Weser)",
        "countryCode": "DE",
        "countryName": "Germany"
    },
    {
        "cityID": "111586",
        "cityCode": "10002677",
        "cityName": "Lindern (Oldenburg)",
        "countryCode": "DE",
        "countryName": "Germany"
    },
    {
        "cityID": "112620",
        "cityCode": "20007084",
        "cityName": "Schwarzenberg",
        "countryCode": "DE",
        "countryName": "Germany"
    },
    {
        "cityID": "266",
        "cityCode": "333316",
        "cityName": "Accra",
        "countryCode": "GH",
        "countryName": "Ghana"
    },
    {
        "cityID": "1469",
        "cityCode": "333574",
        "cityName": "Kumasi",
        "countryCode": "GH",
        "countryName": "Ghana"
    },
    {
        "cityID": "4223",
        "cityCode": "214828",
        "cityName": "Hong Kong",
        "countryCode": "HK",
        "countryName": "Hong Kong"
    },
    {
        "cityID": "5229",
        "cityCode": "214756",
        "cityName": "Sai Kung",
        "countryCode": "HK",
        "countryName": "Hong Kong"
    },
    {
        "cityID": "6395",
        "cityCode": "10001109",
        "cityName": "Hong Kong Island",
        "countryCode": "HK",
        "countryName": "Hong Kong"
    },
    {
        "cityID": "55",
        "cityCode": "219497",
        "cityName": "Agra",
        "countryCode": "IN",
        "countryName": "India"
    },
    {
        "cityID": "77",
        "cityCode": "232641",
        "cityName": "Yercaud",
        "countryCode": "IN",
        "countryName": "India"
    },
    {
        "cityID": "78",
        "cityCode": "239180",
        "cityName": "Muzaffarpur",
        "countryCode": "IN",
        "countryName": "India"
    },
    {
        "cityID": "83",
        "cityCode": "240618",
        "cityName": "Jaisalmer",
        "countryCode": "IN",
        "countryName": "India"
    },
    {
        "cityID": "84",
        "cityCode": "234741",
        "cityName": "Ujjain",
        "countryCode": "IN",
        "countryName": "India"
    },
    {
        "cityID": "87",
        "cityCode": "240357",
        "cityName": "Ajmer",
        "countryCode": "IN",
        "countryName": "India"
    },
    {
        "cityID": "88",
        "cityCode": "240382",
        "cityName": "Pushkar",
        "countryCode": "IN",
        "countryName": "India"
    },
    {
        "cityID": "89",
        "cityCode": "238291",
        "cityName": "Sasan Gir",
        "countryCode": "IN",
        "countryName": "India"
    },
    {
        "cityID": "90",
        "cityCode": "232020",
        "cityName": "Coimbatore",
        "countryCode": "IN",
        "countryName": "India"
    },
    {
        "cityID": "93",
        "cityCode": "234531",
        "cityName": "Shillong",
        "countryCode": "IN",
        "countryName": "India"
    },
    {
        "cityID": "103",
        "cityCode": "222580",
        "cityName": "Kochi",
        "countryCode": "IN",
        "countryName": "India"
    },
    {
        "cityID": "107",
        "cityCode": "240071",
        "cityName": "Jodhpur",
        "countryCode": "IN",
        "countryName": "India"
    },
    {
        "cityID": "111",
        "cityCode": "232260",
        "cityName": "Chidambaram",
        "countryCode": "IN",
        "countryName": "India"
    },
    {
        "cityID": "124",
        "cityCode": "10039983",
        "cityName": "Goa",
        "countryCode": "IN",
        "countryName": "India"
    },
    {
        "cityID": "21",
        "cityCode": "698969",
        "cityName": "San Martino in Badia",
        "countryCode": "IT",
        "countryName": "Italy"
    },
    {
        "cityID": "60",
        "cityCode": "727641",
        "cityName": "Ragusa",
        "countryCode": "IT",
        "countryName": "Italy"
    },
    {
        "cityID": "64",
        "cityCode": "698594",
        "cityName": "Carisolo",
        "countryCode": "IT",
        "countryName": "Italy"
    },
    {
        "cityID": "65",
        "cityCode": "728060",
        "cityName": "Catania",
        "countryCode": "IT",
        "countryName": "Italy"
    },
    {
        "cityID": "101",
        "cityCode": "718027",
        "cityName": "Corsano",
        "countryCode": "IT",
        "countryName": "Italy"
    },
    {
        "cityID": "118",
        "cityCode": "720925",
        "cityName": "Bologna",
        "countryCode": "IT",
        "countryName": "Italy"
    },
    {
        "cityID": "123",
        "cityCode": "723463",
        "cityName": "San Vincenzo",
        "countryCode": "IT",
        "countryName": "Italy"
    },
    {
        "cityID": "127",
        "cityCode": "721661",
        "cityName": "Cesenatico",
        "countryCode": "IT",
        "countryName": "Italy"
    },
    {
        "cityID": "194",
        "cityCode": "717435",
        "cityName": "Polignano a Mare",
        "countryCode": "IT",
        "countryName": "Italy"
    },
    {
        "cityID": "239",
        "cityCode": "698497",
        "cityName": "Cavedine",
        "countryCode": "IT",
        "countryName": "Italy"
    },
    {
        "cityID": "253",
        "cityCode": "711245",
        "cityName": "Borgo Valbelluna",
        "countryCode": "IT",
        "countryName": "Italy"
    },
    {
        "cityID": "268",
        "cityCode": "710143",
        "cityName": "Venice",
        "countryCode": "IT",
        "countryName": "Italy"
    },
    {
        "cityID": "280",
        "cityCode": "726842",
        "cityName": "Partinico",
        "countryCode": "IT",
        "countryName": "Italy"
    },
    {
        "cityID": "306",
        "cityCode": "700205",
        "cityName": "Rome",
        "countryCode": "IT",
        "countryName": "Italy"
    },
    {
        "cityID": "307",
        "cityCode": "728732",
        "cityName": "Cossogno",
        "countryCode": "IT",
        "countryName": "Italy"
    },
    {
        "cityID": "308",
        "cityCode": "704855",
        "cityName": "Livigno",
        "countryCode": "IT",
        "countryName": "Italy"
    },
    {
        "cityID": "309",
        "cityCode": "727603",
        "cityName": "Comiso",
        "countryCode": "IT",
        "countryName": "Italy"
    },
    {
        "cityID": "315",
        "cityCode": "699607",
        "cityName": "San Genesio Atesino",
        "countryCode": "IT",
        "countryName": "Italy"
    },
    {
        "cityID": "380",
        "cityCode": "724526",
        "cityName": "Florence",
        "countryCode": "IT",
        "countryName": "Italy"
    },
    {
        "cityID": "382",
        "cityCode": "711044",
        "cityName": "Rosa",
        "countryCode": "IT",
        "countryName": "Italy"
    },
    {
        "cityID": "393",
        "cityCode": "695815",
        "cityName": "Cerreto di Spoleto",
        "countryCode": "IT",
        "countryName": "Italy"
    },
    {
        "cityID": "394",
        "cityCode": "698706",
        "cityName": "Ziano di Fiemme",
        "countryCode": "IT",
        "countryName": "Italy"
    },
    {
        "cityID": "395",
        "cityCode": "729006",
        "cityName": "Treville",
        "countryCode": "IT",
        "countryName": "Italy"
    },
    {
        "cityID": "424",
        "cityCode": "713508",
        "cityName": "Cagliari",
        "countryCode": "IT",
        "countryName": "Italy"
    },
    {
        "cityID": "448",
        "cityCode": "706590",
        "cityName": "Capaccio-Paestum",
        "countryCode": "IT",
        "countryName": "Italy"
    },
    {
        "cityID": "455",
        "cityCode": "728357",
        "cityName": "Giarre",
        "countryCode": "IT",
        "countryName": "Italy"
    },
    {
        "cityID": "113224",
        "cityCode": "244510",
        "cityName": "Nanporo",
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "cityID": "113226",
        "cityCode": "244625",
        "cityName": "Ando",
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "cityID": "113227",
        "cityCode": "244717",
        "cityName": "Oyodo",
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "cityID": "113228",
        "cityCode": "244847",
        "cityName": "Gose",
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "cityID": "113229",
        "cityCode": "245086",
        "cityName": "Kinokawa",
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "cityID": "113230",
        "cityCode": "246447",
        "cityName": "Kawaminami",
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "cityID": "113231",
        "cityCode": "247249",
        "cityName": "Okagaki",
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "cityID": "113232",
        "cityCode": "247289",
        "cityName": "Aka-mura",
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "cityID": "113233",
        "cityCode": "247303",
        "cityName": "Koge",
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "cityID": "113234",
        "cityCode": "247997",
        "cityName": "China",
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "cityID": "113235",
        "cityCode": "248256",
        "cityName": "Minamisatsuma",
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "cityID": "113236",
        "cityCode": "248344",
        "cityName": "Ogawa",
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "cityID": "113237",
        "cityCode": "248662",
        "cityName": "Shiraoka",
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "cityID": "113238",
        "cityCode": "248743",
        "cityName": "Kashima",
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "cityID": "113239",
        "cityCode": "249205",
        "cityName": "Hijiori Onsen",
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "cityID": "113240",
        "cityCode": "249363",
        "cityName": "Mikawa",
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "cityID": "113241",
        "cityCode": "250526",
        "cityName": "Shirakawa",
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "cityID": "113242",
        "cityCode": "250644",
        "cityName": "Mizuho",
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "cityID": "113244",
        "cityCode": "251232",
        "cityName": "Chikuhoku-mura",
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "cityID": "113245",
        "cityCode": "251534",
        "cityName": "Yamagata",
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "cityID": "113246",
        "cityCode": "251563",
        "cityName": "Asahi",
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "cityID": "113247",
        "cityCode": "252194",
        "cityName": "Mori",
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "cityID": "113249",
        "cityCode": "253867",
        "cityName": "Ohira-mura",
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "cityID": "113251",
        "cityCode": "254556",
        "cityName": "Gojome",
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "cityID": "113253",
        "cityCode": "255794",
        "cityName": "Toyoyama",
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "cityID": "113254",
        "cityCode": "255872",
        "cityName": "Kitanagoya",
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "cityID": "113255",
        "cityCode": "256108",
        "cityName": "Kaizu",
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "cityID": "113256",
        "cityCode": "256583",
        "cityName": "Rokunohe",
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "cityID": "113257",
        "cityCode": "256736",
        "cityName": "Higashidori-mura",
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "cityID": "113258",
        "cityCode": "256836",
        "cityName": "Misaki",
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "cityID": "113259",
        "cityCode": "256838",
        "cityName": "Kumenan",
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "cityID": "113260",
        "cityCode": "258192",
        "cityName": "Hinode",
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "cityID": "113262",
        "cityCode": "260304",
        "cityName": "Ayagawa",
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "cityID": "113263",
        "cityCode": "261286",
        "cityName": "Mugi",
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "cityID": "113264",
        "cityCode": "261404",
        "cityName": "Inashiki",
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "cityID": "113268",
        "cityCode": "262919",
        "cityName": "Tobe",
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "cityID": "18",
        "cityCode": "243739",
        "cityName": "Higashikawa",
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "cityID": "208",
        "cityCode": "257018",
        "cityName": "Okayama",
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "cityID": "219",
        "cityCode": "251239",
        "cityName": "Nagano",
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "cityID": "220",
        "cityCode": "251684",
        "cityName": "Omachi",
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "cityID": "249",
        "cityCode": "243374",
        "cityName": "Kutchan",
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "cityID": "270",
        "cityCode": "254820",
        "cityName": "Kyoto",
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "cityID": "329",
        "cityCode": "242108",
        "cityName": "Kamogawa",
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "cityID": "368",
        "cityCode": "256796",
        "cityName": "Hachinohe",
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "cityID": "370",
        "cityCode": "241302",
        "cityName": "Shirako",
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "cityID": "376",
        "cityCode": "260800",
        "cityName": "Annaka",
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "cityID": "387",
        "cityCode": "247950",
        "cityName": "Tokunoshima",
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "cityID": "390",
        "cityCode": "246308",
        "cityName": "Toyooka",
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "cityID": "441",
        "cityCode": "251513",
        "cityName": "Suwa",
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "cityID": "490",
        "cityCode": "251365",
        "cityName": "Suzaka",
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "cityID": "498",
        "cityCode": "245388",
        "cityName": "Toba",
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "cityID": "517",
        "cityCode": "248934",
        "cityName": "Aso",
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "cityID": "582",
        "cityCode": "242212",
        "cityName": "Tateyama",
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "cityID": "617",
        "cityCode": "245304",
        "cityName": "Shima",
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "cityID": "619",
        "cityCode": "248793",
        "cityName": "Kumamoto",
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "cityID": "708",
        "cityCode": "248558",
        "cityName": "Kawagoe",
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "cityID": "719",
        "cityCode": "244853",
        "cityName": "Ikoma",
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "cityID": "723",
        "cityCode": "258298",
        "cityName": "Tokyo",
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "cityID": "724",
        "cityCode": "254395",
        "cityName": "Minamiuonuma",
        "countryCode": "JP",
        "countryName": "Japan"
    },
    {
        "cityID": "852",
        "cityCode": "334438",
        "cityName": "Nairobi",
        "countryCode": "KE",
        "countryName": "Kenya"
    },
    {
        "cityID": "1603",
        "cityCode": "334834",
        "cityName": "Kisumu",
        "countryCode": "KE",
        "countryName": "Kenya"
    },
    {
        "cityID": "2148",
        "cityCode": "335051",
        "cityName": "Nanyuki",
        "countryCode": "KE",
        "countryName": "Kenya"
    },
    {
        "cityID": "2750",
        "cityCode": "334759",
        "cityName": "Nakuru",
        "countryCode": "KE",
        "countryName": "Kenya"
    },
    {
        "cityID": "3246",
        "cityCode": "334791",
        "cityName": "Naivasha",
        "countryCode": "KE",
        "countryName": "Kenya"
    },
    {
        "cityID": "4675",
        "cityCode": "334865",
        "cityName": "Mombasa",
        "countryCode": "KE",
        "countryName": "Kenya"
    },
    {
        "cityID": "4677",
        "cityCode": "334856",
        "cityName": "Diani Beach",
        "countryCode": "KE",
        "countryName": "Kenya"
    },
    {
        "cityID": "4743",
        "cityCode": "335198",
        "cityName": "Maasai Mara",
        "countryCode": "KE",
        "countryName": "Kenya"
    },
    {
        "cityID": "5497",
        "cityCode": "334825",
        "cityName": "Isiolo",
        "countryCode": "KE",
        "countryName": "Kenya"
    },
    {
        "cityID": "5498",
        "cityCode": "335238",
        "cityName": "Embu",
        "countryCode": "KE",
        "countryName": "Kenya"
    },
    {
        "cityID": "10079",
        "cityCode": "334434",
        "cityName": "Syokimau",
        "countryCode": "KE",
        "countryName": "Kenya"
    },
    {
        "cityID": "10719",
        "cityCode": "334381",
        "cityName": "Ngong",
        "countryCode": "KE",
        "countryName": "Kenya"
    },
    {
        "cityID": "11689",
        "cityCode": "334719",
        "cityName": "Tsavo",
        "countryCode": "KE",
        "countryName": "Kenya"
    },
    {
        "cityID": "11816",
        "cityCode": "335168",
        "cityName": "Lamu",
        "countryCode": "KE",
        "countryName": "Kenya"
    },
    {
        "cityID": "12082",
        "cityCode": "334852",
        "cityName": "Mtwapa",
        "countryCode": "KE",
        "countryName": "Kenya"
    },
    {
        "cityID": "12409",
        "cityCode": "334755",
        "cityName": "Naro Moru",
        "countryCode": "KE",
        "countryName": "Kenya"
    },
    {
        "cityID": "12517",
        "cityCode": "335078",
        "cityName": "Kitale",
        "countryCode": "KE",
        "countryName": "Kenya"
    },
    {
        "cityID": "53811",
        "cityCode": "335038",
        "cityName": "Namunyak Wildlife Conservancy",
        "countryCode": "KE",
        "countryName": "Kenya"
    },
    {
        "cityID": "53929",
        "cityCode": "334959",
        "cityName": "Makuyu",
        "countryCode": "KE",
        "countryName": "Kenya"
    },
    {
        "cityID": "55482",
        "cityCode": "334829",
        "cityName": "Meru National Park",
        "countryCode": "KE",
        "countryName": "Kenya"
    },
    {
        "cityID": "99045",
        "cityCode": "334855",
        "cityName": "Shimba Hills National Reserve",
        "countryCode": "KE",
        "countryName": "Kenya"
    },
    {
        "cityID": "102",
        "cityCode": "323543",
        "cityName": "Lagos",
        "countryCode": "NG",
        "countryName": "Nigeria"
    },
    {
        "cityID": "132",
        "cityCode": "324036",
        "cityName": "Abuja",
        "countryCode": "NG",
        "countryName": "Nigeria"
    },
    {
        "cityID": "295",
        "cityCode": "323327",
        "cityName": "Asaba",
        "countryCode": "NG",
        "countryName": "Nigeria"
    },
    {
        "cityID": "628",
        "cityCode": "10003113",
        "cityName": "Ikeja",
        "countryCode": "NG",
        "countryName": "Nigeria"
    },
    {
        "cityID": "1118",
        "cityCode": "324023",
        "cityName": "Calabar",
        "countryCode": "NG",
        "countryName": "Nigeria"
    },
    {
        "cityID": "1715",
        "cityCode": "323715",
        "cityName": "Ibadan",
        "countryCode": "NG",
        "countryName": "Nigeria"
    },
    {
        "cityID": "4523",
        "cityCode": "323900",
        "cityName": "Owo",
        "countryCode": "NG",
        "countryName": "Nigeria"
    },
    {
        "cityID": "5671",
        "cityCode": "323975",
        "cityName": "Port Harcourt",
        "countryCode": "NG",
        "countryName": "Nigeria"
    },
    {
        "cityID": "5883",
        "cityCode": "323464",
        "cityName": "Abakaliki",
        "countryCode": "NG",
        "countryName": "Nigeria"
    },
    {
        "cityID": "536",
        "cityCode": "209289",
        "cityName": "Muscat",
        "countryCode": "OM",
        "countryName": "Oman"
    },
    {
        "cityID": "31332",
        "cityCode": "10032924",
        "cityName": "Al Amarat",
        "countryCode": "OM",
        "countryName": "Oman"
    },
    {
        "cityID": "6801",
        "cityCode": "208534",
        "cityName": "Doha",
        "countryCode": "QA",
        "countryName": "Qatar"
    },
    {
        "cityID": "877",
        "cityCode": "324404",
        "cityName": "Kigali",
        "countryCode": "RW",
        "countryName": "Rwanda"
    },
    {
        "cityID": "36885",
        "cityCode": "324334",
        "cityName": "Nyungwe Forest National Park",
        "countryCode": "RW",
        "countryName": "Rwanda"
    },
    {
        "cityID": "76",
        "cityCode": "211918",
        "cityName": "Riyadh",
        "countryCode": "SA",
        "countryName": "Saudi Arabia"
    },
    {
        "cityID": "181",
        "cityCode": "212621",
        "cityName": "Mecca/Makkah",
        "countryCode": "SA",
        "countryName": "Saudi Arabia"
    },
    {
        "cityID": "182",
        "cityCode": "212293",
        "cityName": "Jeddah",
        "countryCode": "SA",
        "countryName": "Saudi Arabia"
    },
    {
        "cityID": "290",
        "cityCode": "212749",
        "cityName": "Al Hada",
        "countryCode": "SA",
        "countryName": "Saudi Arabia"
    },
    {
        "cityID": "423",
        "cityCode": "212971",
        "cityName": "Al Khobar",
        "countryCode": "SA",
        "countryName": "Saudi Arabia"
    },
    {
        "cityID": "446",
        "cityCode": "10001548",
        "cityName": "Al Ahsa Governorate",
        "countryCode": "SA",
        "countryName": "Saudi Arabia"
    },
    {
        "cityID": "468",
        "cityCode": "212539",
        "cityName": "Taif",
        "countryCode": "SA",
        "countryName": "Saudi Arabia"
    },
    {
        "cityID": "720",
        "cityCode": "211119",
        "cityName": "Medina/Madina/Madinah",
        "countryCode": "SA",
        "countryName": "Saudi Arabia"
    },
    {
        "cityID": "773",
        "cityCode": "211645",
        "cityName": "Jizan",
        "countryCode": "SA",
        "countryName": "Saudi Arabia"
    },
    {
        "cityID": "59275",
        "cityCode": "211606",
        "cityName": "Baish",
        "countryCode": "SA",
        "countryName": "Saudi Arabia"
    },
    {
        "cityID": "59655",
        "cityCode": "328295",
        "cityName": "Johannesburg",
        "countryCode": "ZA",
        "countryName": "South Africa"
    },
    {
        "cityID": "112846",
        "cityCode": "212332",
        "cityName": "Al Riyadh",
        "countryCode": "SA",
        "countryName": "Saudi Arabia"
    },
    {
        "cityID": "3696",
        "cityCode": "263226",
        "cityName": "Singapore",
        "countryCode": "SG",
        "countryName": "Singapore"
    },
    {
        "cityID": "22789",
        "cityCode": "10002343",
        "cityName": "Orchard",
        "countryCode": "sg",
        "countryName": "Singapore"
    },
    {
        "cityID": "914",
        "cityCode": "325158",
        "cityName": "Mbour",
        "countryCode": "SN",
        "countryName": "Senegal"
    },
    {
        "cityID": "2006",
        "cityCode": "325243",
        "cityName": "Dakar",
        "countryCode": "SN",
        "countryName": "Senegal"
    },
    {
        "cityID": "4350",
        "cityCode": "325190",
        "cityName": "Toubab Dialao",
        "countryCode": "SN",
        "countryName": "Senegal"
    },
    {
        "cityID": "5973",
        "cityCode": "325421",
        "cityName": "Kafountine",
        "countryCode": "SN",
        "countryName": "Senegal"
    },
    {
        "cityID": "9008",
        "cityCode": "10002910",
        "cityName": "Saint-Louis",
        "countryCode": "SN",
        "countryName": "Senegal"
    },
    {
        "cityID": "112877",
        "cityCode": "216144",
        "cityName": "Yen Dung",
        "countryCode": "ZA",
        "countryName": "South Africa"
    },
    {
        "cityID": "113706",
        "cityCode": "325877",
        "cityName": "Lambert's Bay",
        "countryCode": "ZA",
        "countryName": "South Africa"
    },
    {
        "cityID": "119587",
        "cityCode": "78187",
        "cityName": "Worcester",
        "countryCode": "ZA",
        "countryName": "South Africa"
    },
    {
        "cityID": "111530",
        "cityCode": "10001260",
        "cityName": "Avegno Gordevio",
        "countryCode": "CH",
        "countryName": "Switzerland"
    },
    {
        "cityID": "115913",
        "cityCode": "506466",
        "cityName": "Buckten",
        "countryCode": "CH",
        "countryName": "Switzerland"
    },
    {
        "cityID": "115914",
        "cityCode": "506501",
        "cityName": "Zunzgen",
        "countryCode": "CH",
        "countryName": "Switzerland"
    },
    {
        "cityID": "115917",
        "cityCode": "506629",
        "cityName": "Lampenberg",
        "countryCode": "CH",
        "countryName": "Switzerland"
    },
    {
        "cityID": "115953",
        "cityCode": "508168",
        "cityName": "Chezard-Saint-Martin",
        "countryCode": "CH",
        "countryName": "Switzerland"
    },
    {
        "cityID": "112734",
        "cityCode": "20010032",
        "cityName": "Makuyuni",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "113774",
        "cityCode": "330044",
        "cityName": "Mkunguni",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "113775",
        "cityCode": "330161",
        "cityName": "Duka Bovu",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "113776",
        "cityCode": "330226",
        "cityName": "Mikumi National Park",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "113777",
        "cityCode": "330272",
        "cityName": "Olakira Camp",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "113778",
        "cityCode": "330336",
        "cityName": "Fuoni",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "113779",
        "cityCode": "330433",
        "cityName": "Songea",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "113781",
        "cityCode": "330454",
        "cityName": "Kipili",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "113782",
        "cityCode": "330464",
        "cityName": "Changuu Island",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "113783",
        "cityCode": "330468",
        "cityName": "Bawe Island",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "115862",
        "cityCode": "497958",
        "cityName": "Atalaya",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "51",
        "cityCode": "314237",
        "cityName": "Prachuap Khiri Khan",
        "countryCode": "TH",
        "countryName": "Thailand"
    },
    {
        "cityID": "414",
        "cityCode": "322239",
        "cityName": "Bangkok",
        "countryCode": "TH",
        "countryName": "Thailand"
    },
    {
        "cityID": "113677",
        "cityCode": "321951",
        "cityName": "Lam Phaya",
        "countryCode": "TH",
        "countryName": "Thailand"
    },
    {
        "cityID": "2",
        "cityCode": "518154",
        "cityName": "Beysehir",
        "countryCode": "TR",
        "countryName": "Turkey"
    },
    {
        "cityID": "116",
        "cityCode": "518418",
        "cityName": "Safranbolu",
        "countryCode": "TR",
        "countryName": "Turkey"
    },
    {
        "cityID": "140",
        "cityCode": "507455",
        "cityName": "Ayvalik",
        "countryCode": "TR",
        "countryName": "Turkey"
    },
    {
        "cityID": "245",
        "cityCode": "522990",
        "cityName": "Istanbul",
        "countryCode": "TR",
        "countryName": "Turkey"
    },
    {
        "cityID": "361",
        "cityCode": "514511",
        "cityName": "Antalya",
        "countryCode": "TR",
        "countryName": "Turkey"
    },
    {
        "cityID": "116168",
        "cityCode": "520549",
        "cityName": "Bor",
        "countryCode": "TR",
        "countryName": "Turkey"
    },
    {
        "cityID": "116171",
        "cityCode": "520778",
        "cityName": "Yenicaga",
        "countryCode": "TR",
        "countryName": "Turkey"
    },
    {
        "cityID": "116204",
        "cityCode": "522472",
        "cityName": "Sultan Murat YaylasÄ±",
        "countryCode": "TR",
        "countryName": "Turkey"
    },
    {
        "cityID": "116207",
        "cityCode": "522764",
        "cityName": "Mihalgazi",
        "countryCode": "TR",
        "countryName": "Turkey"
    },
    {
        "cityID": "139",
        "cityCode": "330358",
        "cityName": "Dar es Salaam",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "568",
        "cityCode": "330224",
        "cityName": "Morogoro",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "1026",
        "cityCode": "330172",
        "cityName": "Arusha",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "1531",
        "cityCode": "330485",
        "cityName": "Mtwara",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "1664",
        "cityCode": "329918",
        "cityName": "Moshi",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "1868",
        "cityCode": "330054",
        "cityName": "Michamvi",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "1916",
        "cityCode": "330154",
        "cityName": "Matemwe",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "3112",
        "cityCode": "330164",
        "cityName": "Karatu",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "3786",
        "cityCode": "330311",
        "cityName": "Zanzibar Town",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "4684",
        "cityCode": "330049",
        "cityName": "Jambiani",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "5164",
        "cityCode": "330309",
        "cityName": "Fumba",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "5876",
        "cityCode": "330119",
        "cityName": "Bagamoyo",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "6749",
        "cityCode": "330060",
        "cityName": "Bwejuu",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "6891",
        "cityCode": "330194",
        "cityName": "Ngorongoro Conservation Area",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "9090",
        "cityCode": "330040",
        "cityName": "Paje",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "9922",
        "cityCode": "330149",
        "cityName": "Nungwi",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "9968",
        "cityCode": "330096",
        "cityName": "Tanga",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "10525",
        "cityCode": "330203",
        "cityName": "Mwanza",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "10681",
        "cityCode": "10000368",
        "cityName": "Zanzibar",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "12304",
        "cityCode": "330109",
        "cityName": "Mloka",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "15405",
        "cityCode": "330082",
        "cityName": "Pangani",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "15780",
        "cityCode": "330134",
        "cityName": "Kiwengwa",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "16159",
        "cityCode": "10034074",
        "cityName": "Serengeti",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "16311",
        "cityCode": "330114",
        "cityName": "Mafia Island",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "24195",
        "cityCode": "330069",
        "cityName": "Uroa",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "36212",
        "cityCode": "330288",
        "cityName": "Mafinga",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "36363",
        "cityCode": "329888",
        "cityName": "Marangu",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "36386",
        "cityCode": "330349",
        "cityName": "Kibondo",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "36392",
        "cityCode": "10034077",
        "cityName": "Ololosokwan",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "36584",
        "cityCode": "330292",
        "cityName": "Iringa",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "36639",
        "cityCode": "330132",
        "cityName": "Chuini",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "36874",
        "cityCode": "330247",
        "cityName": "Ruaha National Park",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "36984",
        "cityCode": "330131",
        "cityName": "Bububu",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "37052",
        "cityCode": "330002",
        "cityName": "Machame",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "37108",
        "cityCode": "330297",
        "cityName": "Dodoma",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "38026",
        "cityCode": "330145",
        "cityName": "Pwani Mchangani",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "38167",
        "cityCode": "330255",
        "cityName": "Kahama",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "38576",
        "cityCode": "330005",
        "cityName": "Boma la Ngombe",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "38781",
        "cityCode": "329840",
        "cityName": "Lake Manyara National Park",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "39268",
        "cityCode": "330043",
        "cityName": "Kizimkazi",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "39371",
        "cityCode": "330428",
        "cityName": "Singida",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "39401",
        "cityCode": "330055",
        "cityName": "Pingwe",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "39549",
        "cityCode": "329801",
        "cityName": "Babati",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "39605",
        "cityCode": "330248",
        "cityName": "Mbeya",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "39799",
        "cityCode": "330431",
        "cityName": "Mbamba Bay",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "39955",
        "cityCode": "330246",
        "cityName": "Tunduma",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "39995",
        "cityCode": "330233",
        "cityName": "Lindi",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "40105",
        "cityCode": "330234",
        "cityName": "Kilwa",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "40798",
        "cityCode": "329999",
        "cityName": "Himo",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "40849",
        "cityCode": "10002093",
        "cityName": "Kilindoni",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "41238",
        "cityCode": "330163",
        "cityName": "Mbuguni",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "41598",
        "cityCode": "330080",
        "cityName": "Lushoto",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "41982",
        "cityCode": "330471",
        "cityName": "Pemba Island",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "42121",
        "cityCode": "10002138",
        "cityName": "Arusha Chini",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "42373",
        "cityCode": "330147",
        "cityName": "Donge",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "42555",
        "cityCode": "330243",
        "cityName": "Matema",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "43005",
        "cityCode": "330143",
        "cityName": "Kidoti",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "43019",
        "cityCode": "330095",
        "cityName": "Mambo",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "43020",
        "cityCode": "330344",
        "cityName": "Kigoma",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "43021",
        "cityCode": "330276",
        "cityName": "Bunda",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "43022",
        "cityCode": "330113",
        "cityName": "Kibaha",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "43023",
        "cityCode": "329864",
        "cityName": "Kahe",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "43024",
        "cityCode": "330166",
        "cityName": "Monduli",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "43025",
        "cityCode": "330352",
        "cityName": "Nansio",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "43026",
        "cityCode": "329957",
        "cityName": "Sanya Juu",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "43027",
        "cityCode": "330278",
        "cityName": "Musoma",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "43029",
        "cityCode": "330167",
        "cityName": "Lake Natron",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "43034",
        "cityCode": "330058",
        "cityName": "Makunduchi",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "43035",
        "cityCode": "330107",
        "cityName": "Logeloge",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "43036",
        "cityCode": "330214",
        "cityName": "Kiberege",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "43037",
        "cityCode": "330193",
        "cityName": "Manyara",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "43041",
        "cityCode": "330075",
        "cityName": "Pongwe",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "48500",
        "cityCode": "330283",
        "cityName": "Bariadi",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "50436",
        "cityCode": "330035",
        "cityName": "Geita",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "50493",
        "cityCode": "330446",
        "cityName": "Bukoba",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "50792",
        "cityCode": "330144",
        "cityName": "Kendwa",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "53304",
        "cityCode": "329990",
        "cityName": "Ndungu",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "53756",
        "cityCode": "330219",
        "cityName": "Selous Game Reserve",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "54972",
        "cityCode": "329818",
        "cityName": "Tarangire National Park",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "55466",
        "cityCode": "329983",
        "cityName": "Engare Nairobi",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "59668",
        "cityCode": "330266",
        "cityName": "Seronera",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "62039",
        "cityCode": "330222",
        "cityName": "Ubena Bwawani",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "63467",
        "cityCode": "330066",
        "cityName": "Marumbi",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "67419",
        "cityCode": "329961",
        "cityName": "Same",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "67596",
        "cityCode": "10003126",
        "cityName": "Stone Town",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "68680",
        "cityCode": "330094",
        "cityName": "Korogwe",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "69477",
        "cityCode": "330251",
        "cityName": "Sitalike",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "69490",
        "cityCode": "330263",
        "cityName": "Grumeti",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "71952",
        "cityCode": "330271",
        "cityName": "Kogatende",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "73507",
        "cityCode": "330438",
        "cityName": "Tabora",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "73532",
        "cityCode": "330125",
        "cityName": "Saadani",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "75618",
        "cityCode": "330108",
        "cityName": "Kwangwazi",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "77127",
        "cityCode": "330170",
        "cityName": "Longido",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "78421",
        "cityCode": "330245",
        "cityName": "Tukuyu",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "79426",
        "cityCode": "330073",
        "cityName": "Chwaka",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "80048",
        "cityCode": "330063",
        "cityName": "Dongwe",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "80133",
        "cityCode": "330303",
        "cityName": "Mpwapwa",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "80657",
        "cityCode": "330195",
        "cityName": "Sinoni",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "81083",
        "cityCode": "10001878",
        "cityName": "Makambako",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "81351",
        "cityCode": "330071",
        "cityName": "Unguja Ukuu",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "81405",
        "cityCode": "330093",
        "cityName": "Amani",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "81913",
        "cityCode": "329829",
        "cityName": "Kwa Kuchinia",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "82817",
        "cityCode": "330103",
        "cityName": "Mkwaja",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "83157",
        "cityCode": "330444",
        "cityName": "Njombe",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "88463",
        "cityCode": "330223",
        "cityName": "Kisaki",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "90216",
        "cityCode": "330260",
        "cityName": "Nyanungu",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "90561",
        "cityCode": "330112",
        "cityName": "Chalinze",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "93074",
        "cityCode": "330136",
        "cityName": "Kama",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "94081",
        "cityCode": "330466",
        "cityName": "Chumbe Island",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "96664",
        "cityCode": "330289",
        "cityName": "Idodi",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "99878",
        "cityCode": "330225",
        "cityName": "Mikumi",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "100992",
        "cityCode": "330142",
        "cityName": "Fukuchani",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "101455",
        "cityCode": "330033",
        "cityName": "Kakanse",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "104220",
        "cityCode": "330127",
        "cityName": "Shinyanga",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "104256",
        "cityCode": "330470",
        "cityName": "Masasi",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "104803",
        "cityCode": "330338",
        "cityName": "Bweleo",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "105140",
        "cityCode": "330171",
        "cityName": "Mangola",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "109371",
        "cityCode": "330159",
        "cityName": "Potoa",
        "countryCode": "TZ",
        "countryName": "Tanzania"
    },
    {
        "cityID": "167",
        "cityCode": "333331",
        "cityName": "Entebbe",
        "countryCode": "UG",
        "countryName": "Uganda"
    },
    {
        "cityID": "1098",
        "cityCode": "333494",
        "cityName": "Kabale",
        "countryCode": "UG",
        "countryName": "Uganda"
    },
    {
        "cityID": "1460",
        "cityCode": "332899",
        "cityName": "Masaka",
        "countryCode": "UG",
        "countryName": "Uganda"
    },
    {
        "cityID": "1609",
        "cityCode": "332920",
        "cityName": "Kampala",
        "countryCode": "UG",
        "countryName": "Uganda"
    },
    {
        "cityID": "106499",
        "cityCode": "10034156",
        "cityName": "Kitukutwe",
        "countryCode": "UG",
        "countryName": "Uganda"
    },
    {
        "cityID": "112832",
        "cityCode": "211104",
        "cityName": "Al Mirfa",
        "countryCode": "AE",
        "countryName": "United Arab Emirates"
    },
    {
        "cityID": "112838",
        "cityCode": "211754",
        "cityName": "Mezaira'a",
        "countryCode": "AE",
        "countryName": "United Arab Emirates"
    },
    {
        "cityID": "112839",
        "cityCode": "211774",
        "cityName": "Al Ruwais",
        "countryCode": "AE",
        "countryName": "United Arab Emirates"
    },
    {
        "cityID": "112840",
        "cityCode": "211864",
        "cityName": "Al Madam",
        "countryCode": "AE",
        "countryName": "United Arab Emirates"
    },
    {
        "cityID": "112858",
        "cityCode": "213242",
        "cityName": "Al Gissemari",
        "countryCode": "AE",
        "countryName": "United Arab Emirates"
    },
    {
        "cityID": "116535",
        "cityCode": "536317",
        "cityName": "Elton",
        "countryCode": "GB",
        "countryName": "United Kingdom"
    },
    {
        "cityID": "116536",
        "cityCode": "536349",
        "cityName": "Crich",
        "countryCode": "GB",
        "countryName": "United Kingdom"
    },
    {
        "cityID": "116540",
        "cityCode": "536713",
        "cityName": "Kings Langley",
        "countryCode": "GB",
        "countryName": "United Kingdom"
    },
    {
        "cityID": "116542",
        "cityCode": "537020",
        "cityName": "Little Berkhampstead",
        "countryCode": "GB",
        "countryName": "United Kingdom"
    },
    {
        "cityID": "116545",
        "cityCode": "537400",
        "cityName": "London",
        "countryCode": "GB",
        "countryName": "United Kingdom"
    },
    {
        "cityID": "118597",
        "cityCode": "665822",
        "cityName": "St. Saviour",
        "countryCode": "GB",
        "countryName": "United Kingdom"
    },
    {
        "cityID": "118601",
        "cityCode": "665843",
        "cityName": "Saint Mary",
        "countryCode": "GB",
        "countryName": "United Kingdom"
    },
    {
        "cityID": "118605",
        "cityCode": "665889",
        "cityName": "Derbyhaven",
        "countryCode": "GB",
        "countryName": "United Kingdom"
    },
    {
        "cityID": "118606",
        "cityCode": "665949",
        "cityName": "Glen Mona",
        "countryCode": "GB",
        "countryName": "United Kingdom"
    },
    {
        "cityID": "118607",
        "cityCode": "665951",
        "cityName": "Baldrine",
        "countryCode": "GB",
        "countryName": "United Kingdom"
    },
    {
        "cityID": "111796",
        "cityCode": "126202",
        "cityName": "Myrtle Beach",
        "countryCode": "US",
        "countryName": "United States Of America"
    },
    {
        "cityID": "113252",
        "cityCode": "25543",
        "cityName": "Los Angeles",
        "countryCode": "US",
        "countryName": "United States Of America"
    },
    {
        "cityID": "1328",
        "cityCode": "22064",
        "cityName": "Palm Springs",
        "countryCode": "US",
        "countryName": "United States Of America"
    },
    {
        "cityID": "1345",
        "cityCode": "137506",
        "cityName": "San Antonio",
        "countryCode": "US",
        "countryName": "United States Of America"
    },
    {
        "cityID": "1346",
        "cityCode": "125432",
        "cityName": "Charleston",
        "countryCode": "US",
        "countryName": "United States Of America"
    },
    {
        "cityID": "1360",
        "cityCode": "140212",
        "cityName": "Warren",
        "countryCode": "US",
        "countryName": "United States Of America"
    },
    {
        "cityID": "1361",
        "cityCode": "40719",
        "cityName": "Estes Park",
        "countryCode": "US",
        "countryName": "United States Of America"
    },
    {
        "cityID": "1362",
        "cityCode": "73880",
        "cityName": "Portland",
        "countryCode": "US",
        "countryName": "United States Of America"
    },
    {
        "cityID": "110071",
        "cityCode": "63942",
        "cityName": "Fair Oaks",
        "countryCode": "US",
        "countryName": "United States Of America"
    },
    {
        "cityID": "110074",
        "cityCode": "78029",
        "cityName": "Osterville",
        "countryCode": "US",
        "countryName": "United States Of America"
    },
    {
        "cityID": "110080",
        "cityCode": "113801",
        "cityName": "St. Marys",
        "countryCode": "US",
        "countryName": "United States Of America"
    },
    {
        "cityID": "110081",
        "cityCode": "121705",
        "cityName": "Reedsville",
        "countryCode": "US",
        "countryName": "United States Of America"
    },
    {
        "cityID": "110082",
        "cityCode": "127992",
        "cityName": "Counce",
        "countryCode": "US",
        "countryName": "United States Of America"
    },
    {
        "cityID": "110087",
        "cityCode": "113781",
        "cityName": "Ada",
        "countryCode": "US",
        "countryName": "United States Of America"
    },
    {
        "cityID": "110125",
        "cityCode": "100598",
        "cityName": "Calcium",
        "countryCode": "US",
        "countryName": "United States Of America"
    },
    {
        "cityID": "110219",
        "cityCode": "140273",
        "cityName": "Mount Holly",
        "countryCode": "US",
        "countryName": "United States Of America"
    },
    {
        "cityID": "110221",
        "cityCode": "150329",
        "cityName": "South Range",
        "countryCode": "US",
        "countryName": "United States Of America"
    },
    {
        "cityID": "110237",
        "cityCode": "119590",
        "cityName": "California",
        "countryCode": "US",
        "countryName": "United States Of America"
    },
    {
        "cityID": "110426",
        "cityCode": "42064",
        "cityName": "Brooklyn",
        "countryCode": "US",
        "countryName": "United States Of America"
    },
    {
        "cityID": "111194",
        "cityCode": "140418",
        "cityName": "Shoreham",
        "countryCode": "US",
        "countryName": "United States Of America"
    },
    {
        "cityID": "111224",
        "cityCode": "54985",
        "cityName": "Blakely",
        "countryCode": "US",
        "countryName": "United States Of America"
    },
    {
        "cityID": "111225",
        "cityCode": "74231",
        "cityName": "Bingham",
        "countryCode": "US",
        "countryName": "United States Of America"
    },
    {
        "cityID": "193",
        "cityCode": "326387",
        "cityName": "Cape Town",
        "countryCode": "ZA",
        "countryName": "South Africa"
    },
    {
        "cityID": "1126",
        "cityCode": "329535",
        "cityName": "Rustenburg",
        "countryCode": "ZA",
        "countryName": "South Africa"
    },
    {
        "cityID": "1831",
        "cityCode": "327299",
        "cityName": "Port Elizabeth",
        "countryCode": "ZA",
        "countryName": "South Africa"
    },
    {
        "cityID": "1979",
        "cityCode": "328682",
        "cityName": "Kempton Park",
        "countryCode": "ZA",
        "countryName": "South Africa"
    },
    {
        "cityID": "2002",
        "cityCode": "328297",
        "cityName": "Sandton",
        "countryCode": "ZA",
        "countryName": "South Africa"
    },
    {
        "cityID": "40485",
        "cityCode": "325970",
        "cityName": "Witsand",
        "countryCode": "ZA",
        "countryName": "South Africa"
    },
    {
        "cityID": "54211",
        "cityCode": "329525",
        "cityName": "Pilanesberg National Park",
        "countryCode": "ZA",
        "countryName": "South Africa"
    },
    {
        "cityID": "57344",
        "cityCode": "327972",
        "cityName": "South Durban",
        "countryCode": "ZA",
        "countryName": "South Africa"
    },
    {
        "cityID": "72496",
        "cityCode": "327818",
        "cityName": "Dolphin Coast",
        "countryCode": "ZA",
        "countryName": "South Africa"
    },
    {
        "cityID": "73602",
        "cityCode": "329069",
        "cityName": "Pilgrims Rest",
        "countryCode": "ZA",
        "countryName": "South Africa"
    },
    {
        "cityID": "78116",
        "cityCode": "328079",
        "cityName": "Outer West Durban",
        "countryCode": "ZA",
        "countryName": "South Africa"
    },
    {
        "cityID": "80213",
        "cityCode": "329691",
        "cityName": "Sutherland",
        "countryCode": "ZA",
        "countryName": "South Africa"
    },
    {
        "cityID": "80611",
        "cityCode": "325980",
        "cityName": "Jongensfontein",
        "countryCode": "ZA",
        "countryName": "South Africa"
    },
    {
        "cityID": "92083",
        "cityCode": "328137",
        "cityName": "Sodwana Bay National Park",
        "countryCode": "ZA",
        "countryName": "South Africa"
    },
    {
        "cityID": "93007",
        "cityCode": "327537",
        "cityName": "Welkom",
        "countryCode": "ZA",
        "countryName": "South Africa"
    },
    {
        "cityID": "98106",
        "cityCode": "325465",
        "cityName": "Hopefield",
        "countryCode": "ZA",
        "countryName": "South Africa"
    },
    {
        "cityID": "98371",
        "cityCode": "327847",
        "cityName": "Nkwalini",
        "countryCode": "ZA",
        "countryName": "South Africa"
    },
    {
        "cityID": "98455",
        "cityCode": "328590",
        "cityName": "Randfontein",
        "countryCode": "ZA",
        "countryName": "South Africa"
    },
    {
        "cityID": "98833",
        "cityCode": "327388",
        "cityName": "Studtis",
        "countryCode": "ZA",
        "countryName": "South Africa"
    },
    {
        "cityID": "102455",
        "cityCode": "328062",
        "cityName": "Mpumalanga",
        "countryCode": "ZA",
        "countryName": "South Africa"
    },
    {
        "cityID": "102559",
        "cityCode": "326192",
        "cityName": "Boggoms Bay",
        "countryCode": "ZA",
        "countryName": "South Africa"
    },
    {
        "cityID": "550",
        "cityCode": "334105",
        "cityName": "Livingstone",
        "countryCode": "ZM",
        "countryName": "Zambia"
    },
    {
        "cityID": "36257",
        "cityCode": "334064",
        "cityName": "Choma",
        "countryCode": "ZM",
        "countryName": "Zambia"
    },
    {
        "cityID": "36768",
        "cityCode": "334232",
        "cityName": "Mpika",
        "countryCode": "ZM",
        "countryName": "Zambia"
    },
    {
        "cityID": "36788",
        "cityCode": "333984",
        "cityName": "Kafue",
        "countryCode": "ZM",
        "countryName": "Zambia"
    },
    {
        "cityID": "41424",
        "cityCode": "334084",
        "cityName": "Siavonga",
        "countryCode": "ZM",
        "countryName": "Zambia"
    },
    {
        "cityID": "43042",
        "cityCode": "334245",
        "cityName": "South Luangwa National Park",
        "countryCode": "ZM",
        "countryName": "Zambia"
    },
    {
        "cityID": "43043",
        "cityCode": "334192",
        "cityName": "Katete",
        "countryCode": "ZM",
        "countryName": "Zambia"
    },
    {
        "cityID": "43044",
        "cityCode": "334049",
        "cityName": "Mufulira",
        "countryCode": "ZM",
        "countryName": "Zambia"
    },
    {
        "cityID": "55452",
        "cityCode": "333992",
        "cityName": "Lower Zambezi National Park",
        "countryCode": "ZM",
        "countryName": "Zambia"
    }
];

export { popularHotelCities }