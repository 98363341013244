import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import "./dateStyle.css";

const useStyles = makeStyles((theme) => ({
  basic: {
    padding: 15,
    borderRadius: 15,
    width: 180,
    border: "none",
    outline: "none",
    margin: "5px 8px",
    fontFamily: "Euclid-Bold",
    boxShadow: "0px 1px 9px rgba(0, 0, 0, 0.24)",
    fontSize: 15,
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "59vw",
    },
  },
}));

function BInput({
  type,
  placeholder,
  disabled,
  onfocus,
  onblur,
  onchange,
  autofocus,
  value,
  required,
  colored,
}) {
  const classes = useStyles();

  return (
    <div className="">
      <label>
        <p
          style={{
            fontSize: 12,
            textAlign: "left",
            marginBottom: 2,
            paddingLeft: 2,
            fontFamily: "Euclid-Bold",
            color: colored ? "#fff" : "#2BA147",
          }}
          className="pt-3 pb-1"
        >
          {placeholder}
        </p>
        <input
          type={type}
          placeholder={placeholder}
          label={placeholder}
          disabled={disabled}
          //  className={classes.basic}
          className="py-4 px-6 border-none rounded-xl w-full outline-none text-sm mb-2"
          onFocus={onfocus}
          onBlur={onblur}
          onChange={onchange}
          value={value}
          required={required}
          style={{
            color: colored ? "#fff" : "#2BA147",
            background: colored ? "#252525" : "#fff",
          }}
        ></input>
        
      </label>
    </div>
  );
}
//  <label style={{ border: "2px solid red" }}>
//    <span
//      style={{
//        fontSize: 12,
//        textAlign: "left",
//        fontFamily: "Euclid-Bold",
//        color: colored ? "#fff" : "#2BA147",
//      }}
//    >
//      {placeholder}
//    </span>
//    <br />
//    <input
//      type={type}
//      placeholder={placeholder}
//      label={placeholder}
//      disabled={disabled}
//      // className={classes.basic}
//      className="py-4 px-6 border-none rounded-xl w-full outline-none text-sm mb-2"
//      onFocus={onfocus}
//      onBlur={onblur}
//      onChange={onchange}
//      value={value}
//      required={required}
//      style={{
//        color: colored ? "#fff" : "#2BA147",
//        background: colored ? "#252525" : "#fff",
//      }}
//    ></input>
//  </label>;
export default BInput;
