
import Tiketika from "../Axios/AxiosConfig";

const getLocations = async () => {
    try {
        const res = await Tiketika.get("location/");
        window.sessionStorage.setItem('locations', JSON.stringify(res.data.locations));
    } 
    catch(error) {
        console.log("Regions endpoint error ", error);
    }
}

const getRegions = async () => {
    try {
        const res = await Tiketika.get("region/");
        window.sessionStorage.setItem('regions', JSON.stringify(res.data.regions));
        
        // Trigger a custom event
        const regionsLoadedEvent = new Event('regionsLoaded');
        window.dispatchEvent(regionsLoadedEvent);
    } 
    catch(error) {
        console.log("Regions endpoint error ", error);
    }
}

const Orodha = () => {
    //Regions MUST run last as the custom Event is launched from getRegions
    getLocations()
    getRegions()
}

const AOSQueryParameters = [
    {
        "Label": "Domain",
        "Variable name": "/flight/result?",
        "Type": "Mandatory",
        "Description": "Site domain with HTTPS and extension /flight/result?"
    },
    {
        "Label": "Departure Airport Code",
        "Variable name": "dep1",
        "Type": "Mandatory",
        "Description": "3 letter departure airport code of first segment"
    },
    {
        "Label": "Arrival Airport Code",
        "Variable name": "ret1",
        "Type": "Mandatory",
        "Description": "3 letter arrival airport code of first segment"
    },
    {
        "Label": "Departure Date",
        "Variable name": "dtt1",
        "Type": "Mandatory",
        "Description": "Departure date of first segment in DD-MMM-YYYY format"
    },
    {
        "Label": "Cabin Class",
        "Variable name": "cl1",
        "Type": "Mandatory",
        "Description": "Cabin class of first segment"
    },
    {
        "Label": "Y Economy"
    },
    {
        "Label": "W Premium Economy"
    },
    {
        "Label": "C Business"
    },
    {
        "Label": "F First"
    },
    {
        "Label": "Trip Type",
        "Variable name": "triptype",
        "Type": "Mandatory",
        "Description": "1 Oneway"
    },
    {
        "Label": "2 Round Trip"
    },
    {
        "Label": "1 Multi City (3 segments)"
    },
    {
        "Label": "3 Multi City (6 segments)"
    },
    {
        "Label": "Adult Count",
        "Variable name": "adult",
        "Type": "Mandatory",
        "Description": "Adult pax count (1-9)"
    },
    {
        "Label": "Child Count",
        "Variable name": "child",
        "Type": "Mandatory",
        "Description": "Child pax count (0-9). Pass 0 if no child pax in search"
    },
    {
        "Label": "Infant Count",
        "Variable name": "infant",
        "Type": "Mandatory",
        "Description": "Infant pax count (0-9). Pass 0 if no infant pax in search"
    },
    {
        "Label": "Direct Flights Only",
        "Variable name": "direct",
        "Type": "Optional",
        "Description": "true or false"
    },
    {
        "Label": "Pass true if only direct flight options required"
    },
    {
        "Label": "Journey Type Key",
        "Variable name": "key",
        "Type": "Mandatory",
        "Description": "Journey type key"
    },
    {
        "Label": "OW - One-way"
    },
    {
        "Label": "IRT - Round Trip"
    },
    {
        "Label": "MC - Multi City (3 segments)"
    },
    {
        "Label": "NMC - Multi City (6 segments)"
    },
    {
        "Label": "DRT - Side by Side Round Trip"
    },
    {
        "Label": "Note: DRT is the special key. Pass this if you need side-by-side results for round trip."
    },
    {
        "Label": "Airlines",
        "Variable name": "airlines",
        "Type": "Optional",
        "Description": "2 letter comma separated airline codes (Max 3 airline codes allowed)."
    },
    {
        "Label": "Refundable",
        "Variable name": "ref",
        "Type": "Optional",
        "Description": "true or false"
    },
    {
        "Label": "Pass true if only refundable fares required"
    },
    {
        "Label": "Geography",
        "Variable name": "mgcc",
        "Type": "Optional",
        "Description": "2 letter country code for multi-geography site"
    },
    {
        "Label": "Currency",
        "Variable name": "curr",
        "Type": "Optional",
        "Description": "3 letter currency code activated for multi-currency site"
    },
    {
        "Label": "Language Code",
        "Variable name": "langCode",
        "Type": "Mandatory",
        "Description": "2 letter language code"
    },
    {
        "Label": "EN - English"
    },
    {
        "Label": "AR - Arabic"
    },
    {
        "Label": "FR - French"
    },
    {
        "Label": "Baggage",
        "Variable name": "baggage",
        "Type": "Optional",
        "Description": "true or false"
    },
    {
        "Label": "Pass true if you want only those fares which have free checked-in baggage included"
    },
    {
        "Label": "ipc",
        "Variable name": "ipc",
        "Type": "Optional",
        "Description": "true or false Pass true to display pricing calendar"
    }
]

export {Orodha}