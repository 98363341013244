import React, { useState, useEffect } from "react";
import BInput from "./../Inputs/BInput";
import DatePicker from "./../Inputs/DatePicker";
import DropInput from "./../Inputs/DropInput";
import DInput from "../Inputs/DInput";
import Primary from "./../Buttons/Primary";
import Submit from "./../Buttons/Submit";
import { Link, useHistory, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { makeStyles } from "@material-ui/core/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { Autocomplete, TextField } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useDispatch, useSelector } from "react-redux";
import {
  addHotelForm,
  selectHotelForm,
  setAvailableHotels,
  selectAvailableHotels,
} from "../../slices/NavSlice";
import { BottomPattern, GreenLogo } from "../../assets/images";
import Tiketika from "../Axios/AxiosConfig";
import DateTabs from "../Main/DateTabx";
import Regular from "../Text/Regular";
import { popularHotelCities } from "../fx/cities";
import { ContentCopy } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "5%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginBottom: "5%",
    },
  },
}));

function HotelForm() {

  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const browserLocation = useLocation();

  const hotel = useSelector(selectHotelForm);
  const small = useMediaQuery(theme.breakpoints.down("sm"));
  const tripTypeButton = { position: 'absolute', marginTop: '-3rem' }
  const defaultRegions = [
    {
      "regionID": "2",
      "region": "Dar es Salaam",
      "abbreviation": "DAR",
      "id": 2
    },
    {
      "regionID": "20",
      "region": "Mwanza",
      "abbreviation": "MWZ",
      "id": 2
    }
  ]
  
  // Form variables
  const today = new Date();

  const [regionID, setRegionID] = useState(hotel ? hotel.regionID : "");
  const [tripType, setTripType] = useState(1);
  const [availableCities, setAvailableCities] = useState(popularHotelCities);
  const [city, setCity] = useState("");
  const [checkInDate, setCheckInDate] = useState(hotel.checkInDate ? hotel.checkInDate : today.toISOString().slice(0, 10));
  const [checkOutDate, setCheckOutDate] = useState(hotel.checkOutDate ? hotel.checkOutDate : today.toISOString().slice(0, 10));
  const [generalError, setGeneralError] = useState("");
  
  const [regions, setRegions] = useState(defaultRegions ? defaultRegions : []);

  async function getRegions() {
    try {
      const response = await Tiketika.get("/region/?type=hotel");
      if (response.data) {
        setRegions(response.data.regions)
      }
    } catch (error) {
      console.log("error asxsa", error);
    }
  }
  
  async function postHotelFormData() {
    try {
            
      // Check if the dates are before today
      let parsedCheckIn = moment(checkInDate);
      if (parsedCheckIn.isBefore(moment(), 'day')) {
        setCheckInDate(moment().format('YYYY-MM-DD'));
      }

      let parsedCheckOut = moment(checkOutDate);
      if (parsedCheckOut.isBefore(moment(), 'day')) {
        setCheckOutDate(moment().format('YYYY-MM-DD'));
      }

      const response = await Tiketika.post("/search/?type=hotel", {
        regionID, checkInDate, checkOutDate,
      });

      const content = response.data;
      console.log(content)
      if (content.hotels && content.hotels.length === 0) {
        console.log("Error: ", content.message);
        dispatch(setAvailableHotels(null));
        setGeneralError("No hotels found for this location");
      } 
      else {
        dispatch(setAvailableHotels(content.hotels));
      }
    } catch (error) {
      console.error(error);
    }
  }

  
  const searchGlobalHotels = () => {

    let baseURL = ''
    // Check if the dates are before today
    
    let parsedCheckIn = moment(checkInDate);
    if (parsedCheckIn.isBefore(moment(), 'day')) {
      setCheckInDate(moment().format('YYYY-MM-DD'));
    }
    let checkIn = moment(checkInDate).format('DD-MMM-YYYY');

    let parsedCheckOut = moment(checkOutDate);
    if (parsedCheckOut.isBefore(moment(), 'day')) {
      setCheckOutDate(moment(checkOutDate).format('YYYY-MM-DD'));
    }
    let checkOut = moment(checkOutDate).format('DD-MMM-YYYY');

    //PERFORM LOGIC CHECKS AND ASSEMBLE THE LINK
    if(city.cityCode){
      baseURL = `https://bookings.tiketika.co.tz/hotel/result?CityCode=${city.cityCode}&nationality=${city.countryCode}&checkinDate=${checkIn}&checkoutDate=${checkOut}&room1=1&langCode=EN&curr=TZS`
      openURLInNewTab(baseURL)
    }
    
  }

  function openURLInNewTab(url) {
    // Ensure the URL is valid
    const sanitizedURL = encodeURI(url);
  
    // Open the URL in a new tab and switch to it
    const newTab = window.open(sanitizedURL, '_blank', 'noopener,noreferrer');
    if(newTab){ newTab.focus() }
  }

  useEffect(() => { getRegions() }, []);

  useEffect(() => {
    if (regionID && checkInDate && checkOutDate) {
      dispatch(addHotelForm({ regionID, checkInDate, checkOutDate }));
      postHotelFormData();
    } 
    else{
      // setGeneralError("Please enter correct info!");
    }
  }, [regionID, checkInDate, checkOutDate]);

  useEffect(() => {
    setTimeout(() => {
      setGeneralError("");
    }, 5000);
  }, [generalError]);

  //declare lugha
  var lugha = JSON.parse(window.sessionStorage.getItem("lugha"));

  return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        {browserLocation.pathname === "/" ? (
          <div className="flex justify-center align-center pt-5 pb-5 mb-10">
            <div className="flex flex-col px-4 py-20 bg-[#333] bg-opacity-90 w-[80%] align-center">
              <form action="#" class="my-2">
                
                <div style={small ? {} : tripTypeButton}>
                  <FormControl sx={{ m: 1, minWidth: 100 }}>
                    <Select
                      id="tripType"
                      value={tripType}
                      onChange={(e) => setTripType(e.target.value)}
                      style={{ background: '#5F5F5F' }}
                      IconComponent={() => <ArrowDropDownIcon style={{ color: '#FFF' }} />} 
                      inputProps={{ sx: { padding: '6px', background: '#5F5F5F' } }}
                      MenuProps={{
                        PaperProps: {
                          style: {background: '#5F5F5F'},
                        },
                      }}
                    >
                      <MenuItem key={'1'} value={1}><Regular text={'Local'} /></MenuItem>
                      <MenuItem key={'2'} value={2}><Regular text={'International'} /></MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <br />
                <div class="flex w-full justify-around flex-col md:flex-row">
                  <div className="w-full mr-2"> 
                    
                    {parseInt(tripType) === 1 ? 
                      <FormControl sx={{ m: 1, minWidth: 200 }}>
                        <InputLabel id="location-label" style={{ color: '#FFF', backgroundColor: 'rgba(51, 51, 51, 0.8)', padding: '0 2px' }}>{lugha.hotelLocation}</InputLabel>
                        <Select
                          labelId="location-label"
                          id="location"
                          value={regionID}
                          label={lugha.hotelLocation}
                          style={{ color: '#FFF', border: '1px solid #FFF' }}
                          onChange={(e) => setRegionID(e.target.value)}
                          IconComponent={() => <ArrowDropDownIcon style={{ color: '#FFF' }} />} 
                          MenuProps={{
                            PaperProps: {
                              style: {backgroundColor: 'rgba(51, 51, 51, 1)'},
                            },
                          }}
                        >
                          {
                            regions.map((region) => 
                              <MenuItem key={region.regionID} value={region.regionID}><Regular text={region.region} /></MenuItem>
                            )
                          }
                        </Select>
                      </FormControl>
                    :
                    <FormControl sx={{ m: 1, minWidth: 300, margin: '0 1.2rem' }}>
                      <Autocomplete
                        id="city"
                        value={city}
                        onChange={(event, newValue) => setCity(newValue)}
                        options={availableCities}
                        getOptionLabel={(option) => city ? `${option.cityName}, ${option.countryName}` : ''}
                        className={classes.whiteText}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={lugha.hotelLocation}
                            variant="outlined"
                            style={{ color: '#FFF', border: '1px solid #FFF', borderRadius: '4px' }}
                            InputLabelProps={{ style: { color: '#FFFFFF', background: 'rgba(51,51,51,.8)', padding: '0 2px' } }}
                          />
                        )}
                        renderOption={(props, option) => (
                          <MenuItem {...props}>
                            <div>
                              {option.cityName} 
                              <br /> 
                              <small>{`${option.countryName}, ${option.countryCode}`}</small>
                            </div>
                          </MenuItem>
                        )}
                      />
                    </FormControl>
                    }

                    <DatePicker
                      placeholder={lugha.hotelCheckIn}
                      value={checkInDate}
                      // color={'#1F1F1F'}
                      // labelColor={'#5F5F5F'}
                      color={'#FFF'}
                      labelColor={'#FFF'}
                      onchange={(value) =>
                        setCheckInDate(moment(value).format("YYYY-MM-DD"))
                      }
                    />

                    <DatePicker
                      placeholder={lugha.hotelCheckOut}
                      value={checkOutDate}
                      color={'#FFF'}
                      labelColor={'#FFF'}
                      onchange={(value) =>
                        setCheckOutDate(moment(value).format("YYYY-MM-DD"))
                      }
                    />
                    
                    {parseInt(tripType) === 1 ? 
                    <div>
                      {regionID && checkInDate && checkOutDate && (
                          <Submit
                            text={lugha.search}
                            iconName={faMagnifyingGlass}
                            pathTo="/hotels"
                            action={() => postHotelFormData()}
                          />
                        )}
                    </div>
                    :
                    <div>
                      {city && checkInDate && checkOutDate && (
                          <Submit
                            text={lugha.search}
                            iconName={faMagnifyingGlass}
                            action={() => searchGlobalHotels()}
                          />
                        )}
                    </div>
                    }

                  </div>
                </div>
              </form>
            </div>
          </div>
        ) : (
          <div class="relative flex flex-col items-center justify-center w-full bg-[#333] pt-16 pb-8">
            <form action="#" class="my-2">
              
              <div style={small ? {} : tripTypeButton}>
                <FormControl sx={{ m: 1, minWidth: 100 }}>
                  <Select
                    id="tripType"
                    value={tripType}
                    onChange={(e) => setTripType(e.target.value)}
                    style={{ background: '#5F5F5F' }}
                    IconComponent={() => <ArrowDropDownIcon style={{ color: '#FFF' }} />} 
                    inputProps={{ sx: { padding: '6px', background: '#5F5F5F' } }}
                    MenuProps={{
                      PaperProps: {
                        style: {background: '#5F5F5F'},
                      },
                    }}
                  >
                    <MenuItem key={'1'} value={1}><Regular text={'Local'} /></MenuItem>
                    <MenuItem key={'2'} value={2}><Regular text={'International'} /></MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div class="flex w-full justify-around flex-col md:flex-row">
                
                <div className="w-full mr-2">
                  
                  {parseInt(tripType) === 1 ? 
                    <FormControl sx={{ m: 1, minWidth: 200 }}>
                      <InputLabel id="location-label" style={{ color: '#FFF', background: '#333', padding: '0 2px' }}>{lugha.hotelLocation}</InputLabel>
                      <Select
                        labelId="location-label"
                        id="location"
                        value={regionID}
                        label={lugha.hotelLocation}
                        style={{ color: '#FFF', border: '1px solid #FFF' }}
                        onChange={(e) => setRegionID(e.target.value)}
                        IconComponent={() => <ArrowDropDownIcon style={{ color: '#FFF' }} />} 
                        MenuProps={{
                          PaperProps: {
                            style: {background: '#5F5F5F'},
                          },
                        }}
                      >
                        {
                          regions.map((region) => 
                            <MenuItem key={region.regionID} value={region.regionID}><Regular text={region.region} /></MenuItem>
                          )
                        }
                      </Select>
                    </FormControl>
                  :
                    <FormControl sx={{ m: 1, minWidth: 300, margin: '0 1.2rem' }}>
                      <Autocomplete
                        id="city"
                        value={city}
                        onChange={(event, newValue) => setCity(newValue)}
                        options={availableCities}
                        getOptionLabel={(option) => city ? `${option.cityName}, ${option.countryName}` : ''}
                        className={classes.whiteText}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={lugha.hotelLocation}
                            variant="outlined"
                            style={{ color: '#FFF', border: '1px solid #FFF', borderRadius: '4px' }}
                            InputLabelProps={{ style: { color: '#FFFFFF', background: 'rgba(51,51,51,.8)', padding: '0 2px' } }}
                          />
                        )}
                        renderOption={(props, option) => (
                          <MenuItem {...props}>
                            <div>
                              {option.cityName} 
                              <br /> 
                              <small>{`${option.countryName}, ${option.countryCode}`}</small>
                            </div>
                          </MenuItem>
                        )}
                      />
                    </FormControl>
                  }

                  <DatePicker
                    placeholder={lugha.hotelCheckIn}
                    value={checkInDate}
                    // color={'#1F1F1F'}
                    // labelColor={'#5F5F5F'}
                    color={'#FFF'}
                    labelColor={'#FFF'}
                    onchange={(value) =>
                      setCheckInDate(moment(value).format("YYYY-MM-DD"))
                    }
                  />
                  <DatePicker
                    placeholder={lugha.hotelCheckOut}
                    value={checkOutDate}
                    color={'#FFF'}
                    labelColor={'#FFF'}
                    onchange={(value) =>
                      setCheckOutDate(moment(value).format("YYYY-MM-DD"))
                    }
                  />

                  <div style={{ display: 'inline-flex' }}>

                  {parseInt(tripType) === 1 ? 
                    <Submit
                      text={lugha.search}
                      iconName={faMagnifyingGlass}
                      pathTo="/hotels"
                      action={() => postHotelFormData()}
                    />
                  :
                    <Submit
                      text={lugha.search}
                      iconName={faMagnifyingGlass}
                      action={() => searchGlobalHotels()}
                    />
                  }

                  </div>

                </div>
              </div>
            </form>
          </div>
        )}
      </LocalizationProvider>
  );
}

export default HotelForm;
