import React, { useState, useEffect } from "react";
import BInput from "../Inputs/BInput";
import Primary from "../Buttons/Primary";
import Submit from "../Buttons/Submit";
import DatePicker from "../Inputs/DatePicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useHistory, useLocation } from "react-router-dom";
import DInput from "../Inputs/DInput";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import Tiketika from '../Axios/AxiosConfig'
import { useSelector, useDispatch } from "react-redux";
import {
  setHomeBg,
  addEntertainmentForm,
  selectEntertainmentForm,
  setAvailableEntertainment,
} from "../../slices/NavSlice";
import { spacers } from "../Spacers/Spacers";
import { BottomPattern, GreenLogo } from "../../assets/images";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Regular from "../Text/Regular";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "5%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginBottom: "5%",
    },
  },

  formHome: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-end",
    marginTop: "27%",
    marginRight: "15%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginBottom: "5%",
    },
  },

  formHomeS: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    //marginTop: '92%',
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginBottom: "5%",
      paddingTop: "16%",
    },
  },
}));

function EntertainmentForm() {
  //declare lugha
  var lugha = JSON.parse(window.sessionStorage.getItem("lugha"));
  const classes = useStyles();
  const dispatch = useDispatch();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const browserLocation = useLocation();
  const entertainmentGlobalState = useSelector(selectEntertainmentForm);
  const defaultRegions = [
    {
      "regionID": "1",
      "region": "Arusha",
      "abbreviation": "ARU",
      "id": 1
    },
    {
      "regionID": "2",
      "region": "Dar es Salaam",
      "abbreviation": "DAR",
      "id": 2
    }
  ]
  const today = new Date();
  const [date, setDate] = useState(entertainmentGlobalState.date ? entertainmentGlobalState.date : today.toISOString().slice(0, 10));
  const [regions, setRegions] = useState(defaultRegions ? defaultRegions : []);

  // Form variables
  const [region, setRegion] = useState(entertainmentGlobalState ? entertainmentGlobalState.region : "");
  const [generalError, setGeneralError] = useState(false);

  
  async function getRegions() {
    try {
      const response = await Tiketika.get("/region/?type=entertainment");
      if (response.data) {
        setRegions(response.data.regions)
      }
    } catch (error) {
      console.log("error asxsa", error);
    }
  }

  useEffect(() => { getRegions() }, []);

  async function postEntertainmentFormData() {
    try {
      
      // Check if the date is before today
      let parsedDate = moment(date);
      if (parsedDate.isBefore(moment(), 'day')) {
        setDate(moment().format('YYYY-MM-DD'));
      }
      const response = await Tiketika.post("/search/?type=entertainment", { region, date });
      const content = response.data;

      if (content.entertainment === null) {
        console.log("error");
        dispatch(setAvailableEntertainment(null));
        setGeneralError("No hot spot found, try a different region");
      } 
      else {
        console.log(content)
        dispatch(setAvailableEntertainment(content.entertainment));
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (region, date) {
      dispatch(addEntertainmentForm({ region, date }));
      postEntertainmentFormData();
    }
  }, [region, date]);

  useEffect(() => {
    setTimeout(() => {
      setGeneralError("");
    }, 5000);
  }, [generalError]);



  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
    {browserLocation.pathname === "/" ? (
          <div className="flex justify-center align-center pt-5 pb-5 mb-10">
            <div className={`flex flex-col px-4 py-14 bg-[#333] bg-opacity-90 ${mobile ? 'w-[80%]' : 'w-[60%]'} align-center`}>
              <form action="#" class="my-2">
                <div class="flex w-full justify-around flex-col md:flex-row">
                  
                  <div className="w-full mr-2">
                    <FormControl sx={{ m: 1, minWidth: mobile ? 200 : 300 }}>
                      <InputLabel id="region-label" style={{ color: '#FFF', background: '#333', padding: '0 2px' }}>{lugha.eventLocation}</InputLabel>
                      <Select
                        labelId="region-label"
                        id="region"
                        value={region}
                        label={lugha.eventLocation}
                        style={{ color: '#FFF', border: '1px solid #FFF' }}
                        onChange={(e) => setRegion(e.target.value)}
                        IconComponent={() => <ArrowDropDownIcon style={{ color: '#FFF' }} />} 
                        MenuProps={{
                          PaperProps: {
                            style: {background: '#5F5F5F'},
                          },
                        }}
                      >
                        {
                          regions.map((region) => 
                            <MenuItem key={region.regionID} value={region.regionID}><Regular text={region.region} /></MenuItem>
                          )
                        }
                      </Select>
                    </FormControl>

                      
                    <DatePicker
                      placeholder={lugha.entertainmentArrivalDate}
                      value={date}
                      color={'#FFF'}
                      labelColor={'#FFF'}
                      onchange={(value) =>
                        setDate(moment(value).format("YYYY-MM-DD"))
                      }
                    />

                    <div style={{ display: 'inline-flex' }}>
                      <Submit
                        text={lugha.search}
                        iconName={faMagnifyingGlass}
                        pathTo="/entertainment"
                        action={() => postEntertainmentFormData()}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
    ) : 
    (
    
    <div class="relative flex flex-col items-center justify-center w-full bg-[#333] pt-12 pb-6">
      <form action="#" class="my-2">
        
        <div class="flex w-full justify-around flex-col md:flex-row">  
          <div className="w-full mr-2">
            
            <FormControl sx={{ m: 1, minWidth: 200 }}>
              <InputLabel id="region-label" style={{ color: '#FFF', background: '#333', padding: '0 2px' }}>{lugha.eventLocation}</InputLabel>
              <Select
                labelId="region-label"
                id="region"
                value={region}
                label={lugha.eventLocation}
                style={{ color: '#FFF', border: '1px solid #FFF' }}
                onChange={(e) => setRegion(e.target.value)}
                IconComponent={() => <ArrowDropDownIcon style={{ color: '#FFF' }} />} 
                MenuProps={{
                  PaperProps: {
                    style: {background: '#5F5F5F'},
                  },
                }}
              >
                {
                  regions.map((region) => 
                    <MenuItem key={region.regionID} value={region.regionID}><Regular text={region.region} /></MenuItem>
                  )
                }
              </Select>
            </FormControl>
                      
            <DatePicker
              placeholder={lugha.entertainmentArrivalDate}
              value={date}
              color={'#FFF'}
              labelColor={'#FFF'}
              onchange={(value) =>
                setDate(moment(value).format("YYYY-MM-DD"))
              }
            />

            <div style={{ display: 'inline-flex' }}>
              <Submit
                text={lugha.search}
                iconName={faMagnifyingGlass}
                pathTo="/entertainment"
                action={() => postEntertainmentFormData()}
              />
            </div>
          </div>
        </div>
      </form>
    </div>  
    )
    }
    </LocalizationProvider>
  );
}

export default EntertainmentForm;
