import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Bold from "../Text/Bold";
import Semi from "../Text/Semi";
import { useDispatch, useSelector } from "react-redux";
import {
  selectBookedSeat,
  addBookedSeat,
  removeBookedSeat,
} from "../../slices/NavSlice";
import clsx from "clsx";

const Seats = ({ total, available, booked, reserved }) => {
  //declare lugha
  const lugha = JSON.parse(window.sessionStorage.getItem("lugha"));

  const dispatch = useDispatch();
  const bookedSeatByActiveSession = useSelector(selectBookedSeat);

  const totalSeats = [];

  const rows = 11;
  const columns = ["A", "B", "C", "D"];
  const [selectedSeats, setSelectedSeats] = useState(bookedSeatByActiveSession); //later the initial ar

  const handleSeatClick = (seat) => {
    const index = selectedSeats.indexOf(seat);
    if (index === -1) {
      setSelectedSeats([...selectedSeats, seat]);
      seatBooked(seat);
    } else {
      const newSelectedSeats = [...selectedSeats];
      newSelectedSeats.splice(index, 1);
      setSelectedSeats(newSelectedSeats);
      seatUnBooked(seat);
    }
  };

  const isSeatSelected = (seat) => selectedSeats.includes(seat);

  const seats = [];
  for (let i = 1; i <= rows; i++) {
    const rowSeats = [];
    for (let j = 0; j < columns.length; j++) {
      const seat = `${i}${columns[j]}`;
      rowSeats.push(seat);
    }
    seats.push(rowSeats);
  }

  for (let i = 0; i < total; i++) {
    totalSeats.push(i);
  }

  const seatBooked = (seatValue) => {
    dispatch(addBookedSeat(seatValue));
  };
  const seatUnBooked = (seatValue) => {
    dispatch(removeBookedSeat(seatValue));
  };

  return (
    <div className="flex items-center p-[5px] m-[6px]  justify-between flex-col rounded-2xl bg-[#6de76fc5]">
      <Box sx={{ flexGrow: 1 }}>
        {/* Div with steering wheel icon */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Semi text={lugha.busFront} />
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            paddingTop: 20,
            paddingBottom: 20,
          }}
        >
          <div
            style={{
              padding: 10,
              borderColor: "black",
              backgroundColor: "#fff",
              borderRadius: 50,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Bold text={lugha.driverSeat} color="#888" />
          </div>
        </div>
        {/* _______ */}
        <div className="flex flex-col items-center ">
          <div className="flex justify-center xl:space-x-8 ">
            <div className="bg-white  flex p-2 xl:p-4 rounded-md ">
              <div className="flex flex-col justify-center items-center space-y-4">
                {seats.map((row, rowIndex) => (
                  <div
                    key={`a${rowIndex}`}
                    className={clsx(
                      "inline-block rounded border border-gray-400 bg-gray-400 px-3 py-1.5 sm:px-2 sm:py-1 xl:px-4 xl:py-2 text-sm font-medium text-white hover:bg-transparent hover:text-gray-400 focus:outline-none focus:ring active:text-indigo-500 shadow-gray-300 shadow-lg hover:cursor-pointer ",
                      {
                        "bg-green-500 border-green-400": isSeatSelected(row[0]),
                      }
                    )}
                    onClick={() => handleSeatClick(row[0])}
                  >
                    <p>{row[0]}</p>
                  </div>
                ))}
              </div>
              <div className="ml-2 flex flex-col justify-center items-center space-y-4">
                {seats.map((row, rowIndex) => (
                  <div
                    key={`b${rowIndex}`}
                    className={clsx(
                      "inline-block rounded border border-gray-400 bg-gray-400 px-3 py-1.5 sm:px-2 sm:py-1 xl:px-4 xl:py-2 text-sm font-medium text-white hover:bg-transparent hover:text-gray-400 focus:outline-none focus:ring active:text-indigo-500 shadow-gray-300 shadow-lg hover:cursor-pointer",
                      {
                        "bg-green-500 border-green-400": isSeatSelected(row[1]),
                      }
                    )}
                    onClick={() => handleSeatClick(row[1])}
                  >
                    <p>{row[1]}</p>
                  </div>
                ))}
              </div>
            </div>

            <div className="mx-2"></div>
            <div className="bg-white border flex p-4 rounded-md">
              <div className="flex flex-col justify-center items-center space-y-4">
                {seats.map((row, rowIndex) => (
                  <div
                    key={`c${rowIndex}`}
                    className={clsx(
                      "inline-block rounded border border-gray-400 bg-gray-400 px-3 py-1.5 sm:px-2 sm:py-1 xl:px-4 xl:py-2 text-sm font-medium text-white hover:bg-transparent hover:text-gray-400 focus:outline-none focus:ring active:text-indigo-500 shadow-gray-300 shadow-lg hover:cursor-pointer",
                      {
                        "bg-green-500 border-green-400": isSeatSelected(row[2]),
                      }
                    )}
                    onClick={() => handleSeatClick(row[2])}
                  >
                    <p>{row[2]}</p>
                  </div>
                ))}
              </div>
              <div className="ml-2 flex flex-col justify-center items-center space-y-4">
                {seats.map((row, rowIndex) => (
                  <div
                    key={`d${rowIndex}`}
                    className={clsx(
                      "inline-block rounded border border-gray-400 bg-gray-400 px-3 py-1.5 sm:px-2 sm:py-1 xl:px-4 xl:py-2 text-sm font-medium text-white hover:bg-transparent hover:text-gray-400 focus:outline-none focus:ring active:text-indigo-500 shadow-gray-300 shadow-lg hover:cursor-pointer",
                      {
                        "bg-green-500 border-green-400": isSeatSelected(row[3]),
                      }
                    )}
                    onClick={() => handleSeatClick(row[3])}
                  >
                    <p className="">{row[3]}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {/* ___________ */}
      </Box>
    </div>
  );
};

export default Seats;
