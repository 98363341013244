import React, { useState, useEffect } from 'react'
import Nav from '../components/Main/Nav';
import DateTabs from '../components/Main/DateTabx';
import RestDateTabs from '../components/Main/RestDateTabx';
import RestForm from './../components/Forms/RestForm';
import FooterNav from '../components/Main/FooterNav';

function RestListings() {

    return (
        <div style={styles.container}>
            {/* Navigation */}
            <Nav colored={true} />

            {/* Tabs switcher */}
            <div style={styles.tabs}>
                <RestForm />
                <RestDateTabs />
                <FooterNav />
            </div>

        </div>
    )
}

export default RestListings

const styles = {
    container: {
        // backgroundColor: 'red',
    },

    tabs: {
        position: 'absolute',
        top: 85,
        width: '100vw',
    }
}