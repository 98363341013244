import {React, useState} from "react";
import AxiosInstance from '../Axios/AxiosConfig';
import Bold from "../Text/Bold";
import Secondary from "../Buttons/Secondary";
import Primary from "../Buttons/Primary";
import Regular from "../Text/Regular";
import Light from "../Text/Light";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import BInput from "../Inputs/BInput";
import { date } from "joi";
import moment from "moment";
import { Grid } from "@mui/material";
import { WordCapitalizer } from "../fx/fx";
import { useDispatch } from "react-redux";
import Tiketika from "../Axios/AxiosConfig";
import { setAvailableBuses, setBookedSeat, setChosenBus } from "../../slices/NavSlice";
import Submit from "../Buttons/Submit";
import FInput from "../Inputs/FInput";

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: "#F1F1F1",
    backgroundImage:
      "url('https://tiketika.online/img/assets/tiketika-footer.png')",
    backgroundSize: "cover",
    // backgroundPosition: "center center",
    backgroundPosition: "bottom",
    paddingBottom: "3.5rem",
    backgroundRepeat: "repeat-x",
    width: "100%",
    padding: 25,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    zIndex: 5,
    textDecoration: "none",
    color: "#fff",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      //marginBottom: '5%',
      padding: 0,
      textAlign: "center",
      justifyContent: "center",
      alignItems: "center",
    },
  },

  basic: {
    padding: 20,
    borderRadius: 50,
    width: "85%",
    border: "none",
    outline: "none",
    margin: 5,
    fontFamily: "Euclid-Bold",
    boxShadow: "0px 1px 9px rgba(0, 0, 0, 0.07)",
    // fonSize: 20,
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "55vw",
    },
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FAFAFA",
    boxShadow: 24,
    padding: '2rem',
    borderRadius: 5,
    [theme.breakpoints.down("sm")]: {
      width: "80vw",
    },
  },

  right: {
    paddingRight: "5%",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "0%",
    },
  },
}));



function FooterNav({ text, action, clickAction }) {
  //declare lugha
  var lugha = JSON.parse(window.sessionStorage.getItem("lugha"));
  const classes = useStyles();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [support, setSupport] = useState(false);
  const [ticket, setTicket] = useState(false);
  const [reference, setReference] = useState('');
  const [booking, setBooking] = useState('');

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSupport = () => setSupport(true);
  const handleSupportClose = () => setSupport(false);

  const handleTicket = () => setTicket(true);
  const handleTicketClose = () => setTicket(false);

  
  // an array of routes
  const routes = [
    {
      background: "https://tiketika.online/img/landmarks/dar-tanzanite-bridge-1.webp",
      kutoka: "MWANZA",
      kuenda: "DAR",
      origin: "20",
      destination: "2",
      price: "56000",
      width: "100%",
    },

    {
      background: "https://tiketika.online/img/landmarks/mbeya-roundabout-1.webp",
      kutoka: "MWANZA",
      kuenda: "MBEYA",
      origin: "20",
      destination: "16",
      price: "32000",
      width: "100%",
    },

    {
      background: "https://tiketika.online/img/landmarks/dodoma-nyerere-statue-1.webp",
      kutoka: "DAR",
      kuenda: "DODOMA",
      origin: "2",
      destination: "3",
      price: "24000",
      width: "100%",
    },

    {
      background: "https://tiketika.online/img/landmarks/arusha-town-monument-2.webp",
      kutoka: "DAR",
      kuenda: "ARUSHA",
      origin: "2",
      destination: "1",
      price: "32000",
      width: "100%",
    },

    {
      background: "https://tiketika.online/img/landmarks/mwanza-rocks-1.webp",
      kutoka: "DAR",
      kuenda: "KIGOMA",
      origin: "2",
      destination: "9",
      price: "32000",
      width: "100%",
    },

    {
      background: "https://tiketika.online/img/landmarks/tanga-roundabout-1.webp",
      kutoka: "DAR",
      kuenda: "TANGA",
      origin: "2",
      destination: "27",
      price: "58000",
      width: "100%",
    },
  ];
  
  // an array of spots
  const spots = [
    {
      background: "https://tiketika.online/img/entertainment/tips-mikocheni/tips-mikocheni-1.jpg",
      mahali: "Tips Lounge - Miko",
      entertainmentID: "4",
      price: "12000",
      width: "100%",
    },

    {
      background: "https://tiketika.online/img/entertainment/kidimbwi-beach/kidimbwi-beach-scenery-1.jpeg",
      mahali: "Kidimbwi Beach",
      entertainmentID: "3",
      price: "8000",
      width: "100%",
    },

    {
      background: "https://tiketika.online/img/entertainment/tips-coco-beach/tips-coco-beach-1.jpg",
      mahali: "Tips Lounge - Coco",
      entertainmentID: "5",
      price: "9000",
      width: "100%",
    },
  ];


  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'reference':
        setReference(value);
        break;
      default:
        break;
    }
  };
  
  async function getBus(origin, destination, date) {
    try {
      const response = await Tiketika.post("/search/?type=bus", {
        origin, destination, date, 
      });

      const content = response.data;
      if (content.buses.length === 0) {
        dispatch(setAvailableBuses(null));
      } 
      else {
        dispatch(setAvailableBuses(content.buses));
        dispatch(setChosenBus([]));
        dispatch(setBookedSeat([]));
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <Grid container className="px-20 mt-4 pt-4 bg-[#F1F1F1]">
        <Grid item xs={12} sm={3} className="flex flex-wrap">
          <img src='https://tiketika.online/img/assets/tiketika-logo.png' alt='Tiketika' style={{ width: 80, height: 80 }} />
          <Regular text="196 Mwai Kibaki Road, Dar es Salaam" color={'#333'} align={'left'} />
          <Regular text={<>support@34.199.255.58<br />+255 700 000 000</>} color={'#2BA147'} align={'left'} />
        </Grid>
        <Grid item xs={12} sm={3} className="flex flex-wrap" style={{ flexDirection: 'column' }}>
          <Bold text={lugha.busPopular} color={'#000'} align={'left'} />
          {routes.map((route, index) => 
            index <= 3 && (
              <Link to={"/buses"} onClick={() => getBus(route.origin, route.destination, moment().format('YYYY-MM-DD'))}>
                <Regular key={index} text={WordCapitalizer(route.kutoka) + ' to ' + WordCapitalizer(route.kuenda)} color={'#333'} align={'left'} />
              </Link>
            )
          )}
        </Grid>
        <Grid item xs={12} sm={3} className="flex flex-wrap" style={{ flexDirection: 'column' }}>
          <Bold text={lugha.eventPopular} color={'#000'} align={'left'} />
          {spots.map((spot, index) => 
            index <= 3 && (
              <Link to={"/hot-spot/" + spot.entertainmentID}>
                <Regular key={index} text={spot.mahali} color={'#333'} align={'left'} />
              </Link>
            )
          )}
        </Grid>
        <Grid item xs={12} sm={3} className="flex flex-wrap" style={{ flexDirection: 'column' }}>
          <Bold text={lugha.entertainmentPopular} color={'#000'} align={'left'} />
          {spots.map((spot, index) => 
            index <= 3 && (
              <Link to={"/hot-spot/" + spot.entertainmentID}>
                <Regular key={index} text={spot.mahali} color={'#333'} align={'left'} />
              </Link>
            )
          )}
        </Grid>
      </Grid>
      <div className={classes.footer}>
        {/* Div to display contacts and terms and services */}
        <div className={classes.right}>
          <Button onClick={handleOpen}>
            <Regular text={lugha.contactUs} color={'#333'} />
          </Button>
          <Button onClick={handleSupport}>
            <Regular text={lugha.support} color={'#333'} />
          </Button>
          <Link to="/terms">
            <Regular text={lugha.terms} color={'#333'} />
          </Link>
          <Link to="/privacy-policy">
            <Regular text={lugha.privacy} color={'#333'} />
          </Link>
        </div>

        {/* Social Links */}
        <div className="flex">
          <a href="/facebook">
            <img
              style={{ width: 25, height: 25, marginRight: 10 }}
              src="https://img.icons8.com/ios-filled/50/2BA147/facebook-new.png"
            />
          </a>
          <a href="/twitter">
            <img
              style={{ width: 25, height: 25, marginRight: 10 }}
              src="https://img.icons8.com/ios-filled/50/2BA147/twitter.png"
            />
          </a>
          <a href="/instagram">
            <img
              style={{ width: 25, height: 25 }}
              src="https://img.icons8.com/ios-filled/50/2BA147/instagram-new.png"
            />
          </a>
        </div>

        <div>
          {/* Owner Logo */}

          <a
            style={{ color: "#2BA147", textDecoration: "none" }}
            target="_blank" rel="noreferrer"
            href="https://mmconnectafrica.com/?utm_source=Tiketika"
          >
            <Regular text={lugha.powered} color={'#333'} />
            <Bold text={"MM Connect Africa"} />
          </a>

          <br />

          <a
            style={{ color: "#333", textDecoration: "none" }}
            target="_blank" rel="noreferrer"
            href="https://tiketika.online/"
          >
            <Light text={"Copyright © " + moment().format('Y') + " Tiketika"} fontSize={'0.8rem'} color={'#333'} />
          </a>
        </div>
      </div>

      {/* Contact Us */}
      <div id="basicModal">
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="Contact Form"
          aria-describedby="Reach out to us for support"
        >
          <Box className={classes.modal}>
            <form style={{ flexDirection: "row" }}>
              <Bold text={lugha.contactUs} />
              <Regular text={<>{lugha.call} +255 684 990 286 / +255 747 939 306</>} />

              {/* <div>
                <input
                  type="text"
                  className={classes.basic}
                  name="name"
                  placeholder={lugha.name}
                />
              </div>
              <div>
                <input
                  type="email"
                  className={classes.basic}
                  name="email"
                  placeholder={lugha.email}
                />
              </div>
              <div>
                <input
                  type="text"
                  className={classes.basic}
                  name="message"
                  placeholder={lugha.message}
                />
              </div>
              <div>
                <Button onClick={() => alert("login")}>
                  <Primary text={lugha.sendMessage} />
                </Button>
              </div> */}
            </form>
          </Box>
        </Modal>
      </div>

      {/* Support */}
      <div id="basicModal">
        <Modal
          open={support}
          onClose={handleSupportClose}
          aria-labelledby="Support"
          aria-describedby="Reach out to us for support"
        >
          <Box className={classes.modal}>
            <form style={{ flexDirection: "row" }}> 
            {/* I removed onSubmit={handleSubmit} and instead use the Link component so the Ticket modal remains unused for now - 01122023*/}
              <Bold text={lugha.supportMessage} />

                <FInput
                  width={'100%'}
                  type={"text"}
                  placeholder={lugha.reference}
                  onChange={handleChange}
                  name="reference"
                  required
                />

                <Submit text={lugha.search} pathTo={'tickets/' + reference} />
            </form>
          </Box>
        </Modal>
      </div>
    </>
  );
}

export default FooterNav;
