import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  basic: {
    padding: 15,
    borderRadius: 15,
    width: 456,
    border: "none",
    outline: "none",
    margin: "5px 8px",
    fontFamily: "Euclid-Bold",
    boxShadow: "0px 1px 9px rgba(0, 0, 0, 0.24)",
    fontSize: 15,
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "65vw",
    },
  },
}));

function HInput({
  options,
  placeholder,
  disabled,
  onfocus,
  onblur,
  onchange,
  autofocus,
  value,
  required,
  colored,
}) {
  const classes = useStyles();
  let HOTEL = options.find((hotel) => hotel.hotelID == value);
console.log(value)
  if (HOTEL) {
    HOTEL = HOTEL.hotel;
  }
  return (
    //select
    <div>
      <label>
        <span
          style={{
            fontSize: 12,
            textAlign: "left",
            fontFamily: "Euclid-Bold",
            color: colored ? "#fff" : "#2BA147",
          }}
        >
          {placeholder}
        </span>{" "}
        <br />
        <select
          disabled={disabled}
          onFocus={onfocus}
          onBlur={onblur}
          // className={classes.basic}
          className="py-4 px-6 border-none rounded-xl w-full outline-none text-sm mb-2"
          onChange={onchange}
          autoFocus={autofocus}
          required={required}
          style={{
            color: colored ? "#fff" : "#2BA147",
            background: colored ? "#252525" : "#fff",
            fontFamily: "Euclid-Bold",
          }}
        >
          <option value="" disabled selected>
            {" "}
            {/* {value ? value : placeholder}{" "} */}
            {value ? HOTEL : placeholder}
          </option>
          {options?.map((option, index) => {
            if (option.value) {
              return (
                <option key={index} value={option.value}>
                  {option.value}
                </option>
              );
            } else if (option.location) {
              return (
                <option key={index} value={option.hotelID}>
                  {option.hotel}
                </option>
              );
            }
          })}
        </select>
      </label>
    </div>
  );
}

export default HInput;

const styles = {
  select: {
    padding: 20,
    borderRadius: 50,
    width: 180,
    background: "#fff",
    border: "none",
    outline: "none",
    margin: 5,
    fontFamily: "Euclid-Bold",
    color: "#2BA147",
    boxShadow: "0px 1px 9px rgba(0, 0, 0, 0.07)",
    fontSize: 20,
  },
};
