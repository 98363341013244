import React, { useState, useEffect } from "react";
import Nav from "../components/Main/Nav";
import { useParams } from "react-router-dom";
import HotelInfo from "../components/Main/HotelInfo";
import FooterNav from "../components/Main/FooterNav";

function HotelView() {

  const { hotelID } = useParams()
  return (
    <div style={styles.container}>
      {/* Navigation */}
      <Nav colored={true} />
      <HotelInfo id={hotelID} />  
      <FooterNav />
    </div>
  );
}

export default HotelView;

const styles = {
  container: {
    // backgroundColor: 'red',
  },

  tabs: {
    position: "absolute",
    top: 85,
  },
};
