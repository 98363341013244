import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

  basic: {
    padding: 15,
    borderRadius: 15,
    width: 209,
    border: "none",
    outline: "none",
    margin: '5px 8px',
    fontFamily: "Euclid-Bold",
    boxShadow: "0px 1px 9px rgba(0, 0, 0, 0.24)",
    fontSize: 15,
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down('sm')]: {
      width: '65vw',
    },

  },

}));


var english = false
if(window.sessionStorage.getItem('language') == 'EN'){
  english = true
}
else{
  english = false
}

function GInput({ options, placeholder, disabled, onfocus, onblur, onchange, autofocus, value, required, colored }) {
  const classes = useStyles();
  return (
    //select
    <div>
      <label>
        <span style={{ fontSize: 12, textAlign: 'left', fontFamily: "Euclid-Bold", color: colored ? '#fff' : '#2BA147' }}>{placeholder}</span> <br />
        <select
          disabled={disabled}
          onFocus={onfocus}
          onBlur={onblur}
          className={classes.basic}
          onChange={onchange}
          autoFocus={autofocus}
          required={required}
          style={{
            color: colored ? '#fff' : '#2BA147',
            background: colored ? '#252525' : '#fff',
          }}
        >
          <option value="" disabled selected> {value ? value : placeholder} </option>

          {options?.map((option, index) => {
            if (option.value) {
              return <option key={index} value={option.value}>{option.value}</option>
            } else if (option.genreID) {
              return <option key={index} value={option.genreID}>{english ? option.englishTitle : option.swahiliTitle}</option>
            }
          })}

        </select>
      </label>
    </div>
  )
}

export default GInput

const styles = {
  select: {
    padding: 20,
    borderRadius: 50,
    width: 180,
    background: "#fff",
    border: "none",
    outline: "none",
    margin: 5,
    fontFamily: "Euclid-Bold",
    color: "#2BA147",
    boxShadow: "0px 1px 9px rgba(0, 0, 0, 0.07)",
    fontSize: 20
  }
}