import React from "react";
import Nav from "../components/Main/Nav";
import Tickets from "../components/Tickets";
import FooterNav from "../components/Main/FooterNav";

const TicketsScreen = () => {
  return (
    <div>
      <Nav  colored={true}/>
      <Tickets />
      <FooterNav />
    </div>
  );
};

export default TicketsScreen;
