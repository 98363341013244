import React from "react";
import Bold from "../Text/Bold";
import Secondary from "../Buttons/Secondary";
import Primary from "../Buttons/Primary";
import Regular from "../Text/Regular";
import { Link } from "react-router-dom";

function ActionFooter({ text, action, clickAction }) {
  return (
    <div
      style={{
        position: "fixed",
        bottom: 0,
        left: 0,  //To overcome the fact that the ActionFooter is within the EventListing and EntertainmentListing column
        backgroundColor: "#fff",
        width: "100%",
        padding: 15,
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
        zIndex: 5,
        boxShadow: "0px 1px 9px rgba(0, 0, 0, 0.07)",
      }}
    >
      <div style={{ paddingRight: "5%" }}>
        {clickAction ? (
          <Primary text={text} action={clickAction} />
        ) : (
          <Link to={action}>
            <Primary text={text} />
          </Link>
        )}
      </div>
    </div>
  );
}

export default ActionFooter;
