// a component to render a bus listing in the main page
import React, { useEffect, useState } from "react";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from "@material-ui/core/styles";
import InstagramIcon from '@mui/icons-material/Instagram';
import Primary from "../Buttons/Primary";
import Secondary from "../Buttons/Secondary";
import Bold from "../Text/Bold";
import Semi from "../Text/Semi";
import CircularProgress from '@mui/material/CircularProgress';
import ActionFooter from "./ActionFooter";
import Checkout from "./Checkout";
import {  useSelector } from "react-redux";
import { selectCheckoutDetails, } from "../../slices/NavSlice";
import { NumberFormatter } from "../fx/fx";
import { Dialog, DialogActions, DialogContent, Paper } from "@material-ui/core";
import Regular from "../Text/Regular";
import { Divider, Skeleton } from "@mui/material";
import Submit from "../Buttons/Submit";
import Tiketika from "../Axios/AxiosConfig";
import parse from 'html-react-parser';

function TourInfo({id}) {
  
  //declare lugha
  const lugha = JSON.parse(window.sessionStorage.getItem("lugha"));  
  
  async function getTour() {
    try {
      const response = await Tiketika.get("/tour/?id=" + id);
      if (response.data) {
        console.log(response.data);
        setPackages(response.data.packages)
        setTour(response.data.tour)
      }
    } catch (error) {
      console.log("error asxsa", error);
    }
  }

  const [open, setOpen] = useState(false);
  const [openResponseModal, setOpenResponseModal] = useState(false);
  const [message, setMessage] = useState(false);
  const [status, setStatus] = useState(false);
  const [tour, setTour] = useState();
  const [packages, setPackages] = useState();
  const [itinerary, setItinerary] = useState();
  // state to store total menu price for items in a particular venue
  const [totalTourPrice, setTotalTourPrice] = useState(0);
  const [itemsCount, setItemsCount] = useState([]);
  const [checkoutForm, setCheckoutForm] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenResponseModal = () => setOpenResponseModal(true);
  const handleCloseResponseModal = () => {
    setMessage(false)
    setOpenResponseModal(false);
  }

  const checkoutDetails = useSelector(selectCheckoutDetails);

  // Trigger save and USSD push for payment
  async function submitDetailsProcessPayment() {
    const { name, phone, email, payPhone } = checkoutDetails

    if(itemsCount){
      let items = consolidatePackages(itemsCount)
      let amount = totalTourPrice
      
      if(payPhone){
        handleOpenResponseModal()        
        try {
          const response = await Tiketika.post('/tour/', { name, phone, email, payPhone, items, amount, headers:  { "Access-Control-Allow-Origin": "*" } });
          if (response.data) {
            console.log(response.data);
            const { message, status } = response.data
            if(message && status){
              setMessage(message)
              setStatus(status)
            }
          }
        }
        catch (error) {
          console.log("Error", error);
        }
      }
      else{
        setMessage('Please enter the phone number for payment')
        handleOpenResponseModal()
      }
    }
    else{
      setMessage('Please select the number of packages')
      handleOpenResponseModal()
    }
  }
  
  // function to send checkout details and form details to api
  const CompleteCheckout = () => {    
    //Trigger payment via AzamPay's GW
    submitDetailsProcessPayment()
  }

  useEffect(() => {
    getTour()
  },[id])

  // useEffect total price for all items in itemsCount
  useEffect(() => {
    let total = 0;
    itemsCount.forEach((item) => {
      total += parseInt(item.package.price) * parseInt(item.quantity);
    });
    setTotalTourPrice(total);
  }, [itemsCount]);

  const CartCount = ({ item, tourID }) => {
    var count = 0;
    itemsCount.map((thing) => {
      if (thing.package.packageID === item.packageID && thing.package.tourID === tourID) {
        count++;
        return count;
      }
    });

    return <Semi text={count} />;
  };

  // delete item from itemsCount array
  const deleteItem = (item, tourID) => {
    const newItemsCount = itemsCount.filter((thing) => {
      return thing.package.packageID == item.packageID && thing.tourID == tourID;
    });

    const index = itemsCount.indexOf(newItemsCount[0]);
    itemsCount.splice(index, 1);
    setItemsCount([...itemsCount]);
  };

  const showItinerary = (itinerary) => {
    setItinerary(itinerary)
    handleOpen();
  }

  const consolidatePackages = (items) => {
    let inputArray = items.map(({ package: { packageID, price }, quantity }) => ({ package: { packageID, price }, quantity }));

    return inputArray.reduce((accumulator, current) => {
      const existingPackage = accumulator.find(item => item.package.packageID === current.package.packageID);
  
      if (existingPackage) {
        existingPackage.quantity += current.quantity;
      } else {
        accumulator.push(current);
      }
  
      return accumulator;
    }, []);
  }

  return (
    <>
      <div
        style={{
          // ...centerize,
          // justifyContent: "space-between",
          marginBottom: 25,
          // paddingTop: '2rem',
        }}
      >
    
        {/* Response Dialog */}
        <Dialog
          open={openResponseModal}
          onClose={handleCloseResponseModal}
          aria-labelledby={'Booking Response'}
          aria-describedby={'Booking Response'}
          maxWidth="sm"
        >
          <DialogContent>
            <Grid container spacing={1}>
              {message !== false ? 
              <Semi text={message} fontSize={'1.2rem'} color={status === '00' ? "#333" : "#333"} />
              : 
              <CircularProgress />              
              }
            </Grid>
            <DialogActions>
              {message !== false &&
                <Secondary text="Close" action={() => { handleCloseResponseModal() }} />
              }
            </DialogActions>
          </DialogContent>
        </Dialog>

        {tour ? 
        <Grid container spacing={2} className="p-10">
          <Grid item xs={12} sm={7}>
            {/* Description and details */}
            <Paper elevation={4} style={{ padding: '10px 20px', textAlign: 'left' }}>
              <div className="mb-1 pb-2 pt-1">
                <Bold text={tour.tour} fontSize={'1.2rem'} color={"#2BA147"} />
                <Divider className="pt-1 mb-1" />
              </div>
              
              {/* Itinerary */}
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby={lugha.itinerary}
                aria-describedby={lugha.itinerary}
                maxWidth="md"
                fullWidth
              >
                <DialogContent>
                  {/* List the package items grouped by type */}
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={12} style={{ textAlign: 'left' }}>
                        <Regular text={itinerary ? parse(itinerary) : ''} color={'#000'} />
                    </Grid>
                  </Grid>
                  <DialogActions>
                    <Secondary
                      text="Close"
                      action={() => { handleClose(); }}
                    />
                  </DialogActions>
                </DialogContent>
              </Dialog>
              
              <Regular
                text={lugha.lugha === 'swahili' ? parse(tour.maelezo) : parse(tour.description)}
                fontSize={'1rem'}
                color={"#333"}
                align={'left'}
              />
              <div className="bottom mt-4">
                <Grid container>
                  <Grid item xs={12} sm={3}>
                    <Bold
                      text={'Follow'}
                      fontSize={'0.8rem'}
                      color={"#333"}
                      align={'left'}
                    />
                    <br />

                    <a href={tour.instagram && tour.instagram} target="_blank" rel="noopener noreferrer">
                      <InstagramIcon />
                    </a>
                  </Grid>

                  {/* Itinerary Button */}
                  <Grid item xs={12} sm={5}>
                  </Grid>
                  
                  {/* Blank */}
                  <Grid item xs={12} sm={4} />

                </Grid>
              </div>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={5}>
            {/* List of Tables available for reservation */}
            <Paper elevation={4} style={{ padding: '20px' }}>
              {packages &&
                <>
                  {packages.filter(pack => pack.packageTypeID !== '0').map((item, i) => {
                    var tourID = item.tourID; //I know, I know. But if somehow we're managing multiple location offerings in one info page, this doesn't consume significant resources
                    var price = item.price;
                    var description = item.description;
                    var type = item.packageType
                    if(lugha.lugha === 'swahili'){
                      description = item.maelezo;
                      type = item.ainaYaBurudani
                    }

                    return (
                      <>
                        {i === 0 ? 
                          <div className="mb-1 pb-2">
                            <Bold color={'#2BA147'} fontSize={'1.2rem'} text={type} />
                            <Divider />
                          </div>
                        :
                        ''
                        }
                        

                        <Grid container>
                          <Grid item xs={6} md={6}>
                            <div>
                              <Bold text={item.package} fontSize={'1.2rem'} />
                              <br />
                              <Semi text={'Tsh. '+ NumberFormatter(price)} />
                              <br />
                              <Submit
                                text={lugha.view +' '+ lugha.itinerary}
                                action={() => showItinerary(description)}
                                inverse
                                size={'narrow'}
                              />
                            </div>
                          </Grid>

                          <Grid item xs={6} md={6}>
                            {/* Cart Counter */}
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                                alignItems: "center",
                              }}
                            >
                              <Primary
                                text={"-"}
                                action={() => {
                                  deleteItem(item, tourID);
                                }}
                              />

                              <CartCount item={item} tourID={tourID} />

                              <Primary
                                text={"+"}
                                action={() => {
                                  // add item to items array

                                  var newItem = {
                                    package: item,
                                    quantity: 1,
                                    // tourID: tourID,
                                    // price: price,
                                  };

                                  setItemsCount([...itemsCount, newItem]);
                                }}
                              />
                            </div>
                          </Grid>
                          
                          <Grid item xs={12} md={12} style={{ textAlign: 'left' }}>
                              <Regular text={parse(description.substring(0, 190) + '...')} color={'#000'} />
                          </Grid>

                        <div style={styles.separator}></div>
                        </Grid>
                      </>
                    );
                  })}
                  </> 
              }
              
            </Paper>
          </Grid>
        </Grid>
        :
        <Grid container spacing={2} className="p-10">
          <Grid item xs={12} sm={7}>
            <Paper elevation={4} style={{ padding: '0 20px' }}>
              <Skeleton width={200} height={50} animation={'wave'} />
              <Skeleton width={'auto'} height={300} animation={'wave'} />
            </Paper>
          </Grid>

          <Grid item xs={12} sm={5}>
            <Paper elevation={4} style={{ padding: '10px 40px' }}>
              <Skeleton width={'auto'} height={80} animation={'wave'} />
              <Skeleton width={'auto'} height={80} animation={'wave'} />
              <Skeleton width={'auto'} height={80} animation={'wave'} />
            </Paper>
          </Grid>
        </Grid>
        }
      </div>

      {itemsCount.length > 0 && (
        <ActionFooter
          text={lugha.checkout}
          clickAction={() => setCheckoutForm(true)}
        />
      )}

      {checkoutForm && (
        <Checkout
          total={totalTourPrice}
          text={lugha.payNow}
          type='tour'
          currency='Tsh'
          closeAction={() => {
            // dispatch(setCheckoutDetails([]));
            setCheckoutForm(false);
          }}
          items={itemsCount}
          details={[
            {
              type: "text",
              placeholder: "Full Name",
              name: "fullname",
            },
            {
              type: "phone",
              placeholder: "Phone Number",
              name: "phone",
            },
            {
              type: "email",
              placeholder: "Email",
              name: "email",
            },
          ]}
          action={(e) => {
            e.preventDefault();
            CompleteCheckout();
          }}
        />
      )}
    </>
  );
}

export default TourInfo;

const styles = {
  card: {
    width: 300,
    margin: 80,
    boxShadow: "0px 1px 9px rgba(0, 0, 0, 0.07)",
    display: "flex",
    justifyContent: "center",
  },
  eventDetails: {
    textAlign: "left",
    alignItems: "left",
    flex: 2,
  },
  separator: {
    width: "100%",
    height: 1,
    backgroundColor: "#e0e0e0",
    margin: 10,
  },
  priceDetails: {
    padding: 20,
    backgroundColor: "#f5f5f5",
    textAlign: "center",
    width: "20%",
    borderRadius: 30,
  },

  anem: {
    display: "flex",
    alignItems: "center",
    margin: 5,
  },

  price: {
    fontSize: 30,
    fontWeight: "bold",
    color: "#2BA147",
  },
};
