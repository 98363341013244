import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Bold from '../Text/Bold';
import Semi from '../Text/Semi';

import { selectEntertainmentForm } from '../../slices/NavSlice';
import { useDispatch, useSelector } from 'react-redux';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import TourListing from './TourListing';
import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import TourFilter from './TourFilter';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}



export default function TourOptions() {

    const dispatch = useDispatch();
    const theme = useTheme();
    const lugha = JSON.parse(window.sessionStorage.getItem("lugha"))
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const [value, setValue] = useState(0);

    return (
        <Box sx={{ width: '100vw', }}>
            {/* {matches ? (<></>) : (
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    
                </Box>
            )} */}

            <div>
                <TabPanel value={value} index={value}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={1}/>
                        {/* <Grid item xs={12} sm={3}>
                            <TourFilter />
                        </Grid> */}

                        <Grid item xs={12} sm={10}>
                            <TourListing />
                        </Grid>

                        <Grid item xs={12} sm={1}/>
                    </Grid>
                </TabPanel>
            </div>


        </Box>
    );
}
