// a component to render a bus listing in the main page
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from "@material-ui/core/styles";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import PlaceIcon from '@mui/icons-material/Place';
import Primary from "../Buttons/Primary";
import Secondary from "../Buttons/Secondary";
import Bold from "../Text/Bold";
import Semi from "../Text/Semi";
import Light from "../Text/Light";
import Modal from "@mui/material/Modal";
import ActionFooter from "./ActionFooter";
import Checkout from "./Checkout";

import { useDispatch, useSelector } from "react-redux";
import {
  setHomeBg,
  selectCheckoutDetails,
  setCheckoutDetails,
  selectAvailableHotels,
  selectHotelForm,
} from "../../slices/NavSlice";
import { NumberFormatter } from "../fx/fx";
import moment from "moment/moment";
import { Dialog, DialogActions, DialogContent, Paper } from "@material-ui/core";
import Regular from "../Text/Regular";
import { CardActionArea } from "@mui/material";
import Submit from "../Buttons/Submit";
import parse from 'html-react-parser';
import CalendarIcon from '@mui/icons-material/EventAvailable';
import MapIcon from '@mui/icons-material/Map';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "60%",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 20,
    boxShadow: "0px 1px 9px rgba(0, 0, 0, 0.07)",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "80%",
    },
    margin: 15,
    padding: 15,
  },
  rootMenu: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    width: "80%",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 20,
    flexDirection: "column",
    boxShadow: "0px 1px 9px rgba(0, 0, 0, 0.07)",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "80%",
      transform: "translate(-53%, -50%)",
    },
    padding: 15,
  },
  details: {
    width: "80%",
    margin: 10,
    padding: 5,
    textAlign: 'left',
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: 0,
    },
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    height: 150,
    width: 200,
    paddingRight: 10,
    borderRadius: 10,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: 0,
      height: 150,
      borderRadius: 10,
    },
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
}));

function HotelListing() {
  //declare lugha
  const lugha = JSON.parse(window.sessionStorage.getItem("lugha"));
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const availableHotels = useSelector(selectAvailableHotels);
  console.log(availableHotels)

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const hotelGlobalState = useSelector(selectHotelForm);
  const checkoutDetails = useSelector(selectCheckoutDetails);

  // state to store total menu price for items in a particular venue
  const [totalTourPrice, setTotalTourPrice] = useState(0);
  const [itemsCount, setItemsCount] = useState([]);
  const centerize = { display: 'flex', alignItems: 'center', flexDirection: 'column' }

  // checkout toggle
  const [checkoutForm, setCheckoutForm] = useState(false);

  // useEffect total price for all items in itemsCount
  useEffect(() => {
    let total = 0;
    itemsCount.forEach((item) => {
      console.log(item)
      total += parseInt(item.package.price) * parseInt(item.quantity);
    });
    setTotalTourPrice(total);
  }, [itemsCount]);


  return (
    <>
      <div
        style={{
          ...centerize,
          justifyContent: "space-between",
          marginBottom: 25,
          // paddingTop: '2rem',
        }}
      >
        <Grid container spacing={2}>
          {availableHotels && availableHotels.length ?
            availableHotels.map((item, id) => {
              const { hotelID, hotel, path, address, region } = item
                      
              return (
                <Grid item xs={12} sm={6}>
                  <>
                    <Card>
                      <CardMedia
                        sx={{ height: 140 }}
                        image={"https://tiketika.online/img/hotels/" + path}
                        title="Hotel Poster"
                      />
                      <CardContent>
                        <Bold text={hotel} fontSize={'1.2rem'} />

                        <div className={classes.details}>
                          <MapIcon style={{color: '#2BA147'}} /><Light text={address} fontSize={'.8rem'} /> <br />
                          <PlaceIcon style={{color: '#2BA147'}} /><Light text={region} fontSize={'.8rem'} /> <br />
                        </div>

                        {/* <Regular text={parse(description.substring(0, 100) + '...')} color={'#000'} /> */}
                        
                      </CardContent>
                      <CardActions style={{ justifyContent: "right", }}>
                          <Submit
                            text={<><Semi text={lugha.view} /></>} //<ListIcon /> 
                            size={'small'}
                            inverse
                            id={hotelID}
                            action={() => {
                              // setShowPackages(hotelID);
                            }}
                            pathTo={"/hotel/" + hotelID}
                          />
                      </CardActions>
                    </Card>
                  </>
                </Grid>
              );
            })
          : 
            (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  height: 300,
                }}
              >
                <Bold text="No Hotels Available" fontSize={20} />
              </div>
            )
          }
        </Grid>
      </div>

      {itemsCount.length > 0 && (
        <ActionFooter
          text={lugha.checkout}
          clickAction={() => setCheckoutForm(true)}
        />
      )}

      {checkoutForm && (
        <Checkout
          total={totalTourPrice}
          text={lugha.payNow}
          type='hotel'
          currency='Tsh'
          closeAction={() => {
            // dispatch(setCheckoutDetails([]));
            setCheckoutForm(false);
          }}
          items={itemsCount}
          action={(e) => {
            e.preventDefault();
          }}
        />
      )}
    </>
  );
}

export default HotelListing;
