import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import ClockIcon from '@mui/icons-material/AccessTime';
import { MobileTimePicker } from "@mui/x-date-pickers";

const useStyles = makeStyles((theme) => ({
  basic: {
    padding: 15,
    borderRadius: 15,
    width: 180,
    border: "none",
    outline: "none",
    margin: "5px 8px",
    fontFamily: "Euclid-Bold",
    boxShadow: "0px 1px 9px rgba(0, 0, 0, 0.24)",
    fontSize: 15,
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "59vw",
    },
  },
  root: {
    backgroundColor: "red",
    "& .MuiOutlinedInput-root ": {
      border: "none !important",
    },
  },
}));

function TimePicker({
  placeholder,
  disabled,
  onchange,
  value,
  required,
  colored,
}) {
  const classes = useStyles();

  return (
    <>
      <MobileTimePicker
        ampm={false}
        inputFormat="HH:mm"
        disableMaskedInput={true}
        placeholder={placeholder}
        label={placeholder}
        disabled={disabled}
        onChange={onchange}
        value={value}
        required={required}
        className="py-4 px-6 border-2 rounded-xl w-full outline-none text-sm bg-[#252525] shadow-none"
        closeOnSelect={true}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" style={{ cursor: "pointer" }}>
                  <ClockIcon
                    style={{ color: "#fff", backgroundColor: "#252525" }}
                  />
                </InputAdornment>
              ),
              style: { boxShadow: "none", border: 'none' },
            }}
            InputLabelProps={{ style: { color: '#ffffff' } }}
          />
        )}
      />
    </>
  );
}

export default TimePicker;
