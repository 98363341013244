import React from 'react'

function Bold({text, color, fontSize, padding, textShadow}) {
  return (
    <span 
        style={{
            padding: padding ? padding : '5 0',
            margin: 2,
            fontFamily: "Euclid-Light",
            fontSize: fontSize ? fontSize : 15, 
            color: color,
            textShadow: textShadow ? textShadow : '',
        }}
        className="tracking-wider"
    > 
        {text} 
    </span>
  )
}

export default Bold