import { configureStore } from '@reduxjs/toolkit'
import NavSlice from './slices/NavSlice'

import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

// Import the transformer creator
const expireReducer = require('redux-persist-expire');

const persistConfig = {
  key: 'root',
  storage,
  // Add transformer to the reducer
  transforms: [
    expireReducer('nav', {
      expireSeconds: 60,
      expiredState: {
        bookedSeat: [],
        busForm: [],
        restForm : [],
        hotelForm: [],
        availableBuses: [],
        availableRests: [],
        availableHotels: [],
        chosenBus: null,
        homeBg: 0,
        checkoutDetails: [],
        
      },
    }),
  ],
}

const persistedReducer = persistReducer(persistConfig, NavSlice)

export const store = configureStore({
  reducer: {nav: persistedReducer},
  middleware: [thunk]
})

export const persistor = persistStore(store)