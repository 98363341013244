import React from "react";

function Secondary({ text, action }) {
  return (
    // <button style={styles} onClick={action}>
    //   {text}
    // </button>

    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      class="block rounded-md bg-white px-5 py-2.5 text-sm font-medium text-[#2BA147] border-[#2BA147] transition hover:bg-[#2BA147] hover:text-white hover:border hover:border-white"
      style={{ cursor: "pointer", fontFamily: "Euclid-Bold" }}
      onClick={action}
    >
      {text}
    </a>
  );
}

export default Secondary;

const styles = {
  paddingLeft: 20,
  paddingRight: 20,
  paddingTop: 10,
  paddingBottom: 10,
  borderRadius: 5,
  background: "#fff",
  border: "none",
  margin: 5,
  fontFamily: "Euclid-Bold",
  color: "#2BA147",
  boxShadow: "-1px 2px 2px rgba(0, 0, 0, 0.57)",
};

