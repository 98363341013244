import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import CalendarIcon from "@mui/icons-material/Today";

const useStyles = makeStyles((theme) => ({
  basic: {
    padding: 15,
    borderRadius: 15,
    width: 180,
    border: "none",
    outline: "none",
    margin: "5px 8px",
    fontFamily: "Euclid-Bold",
    boxShadow: "0px 1px 9px rgba(0, 0, 0, 0.24)",
    fontSize: 15,
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "59vw",
    },
  },
  root: {
    backgroundColor: "red",
    "& .MuiOutlinedInput-root ": {
      border: "none !important",
    },
  },
}));

function DatePicker({
  placeholder,
  disabled,
  onchange,
  value,
  required,
  color,
  labelColor,
}) {
  const classes = useStyles();

  return (
    <>
      <MobileDatePicker
        inputFormat="Do MMMM YYYY"
        disableMaskedInput={true}
        placeholder={placeholder}
        label={placeholder}
        disabled={disabled}
        onChange={onchange}
        value={value}
        required={required}
        // className="py-4 px-6 border-2 rounded-xl w-full outline-none text-sm shadow-none"
        closeOnSelect={true}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" style={{ cursor: "pointer" }}>
                  <CalendarIcon
                    style={{ color: labelColor ? labelColor : "#FFFFFF" }}
                  />
                </InputAdornment>
              ),
              style: { color: color ? color : "#FFFFFF", border: labelColor ? `1px solid ${labelColor}` : '1px solid #FFF' }
            }}
            InputLabelProps={{ style: { color: labelColor ? labelColor : '#FFFFFF', background: labelColor && labelColor === '#FFF' ? '#333' : '#FFFFFF', padding: '0 2px' } }}
          />
        )}
      />
    </>
  );
}

export default DatePicker;
