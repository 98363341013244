import airports from './airports.json'

const popularAirports = [
    {
        "iata": "ABV",
        "airport": "Nnamdi Azikiwe International Airport",
        "city": "Abuja",
        "country": "Nigeria"
    },
    {
        "iata": "ACE",
        "airport": "Lanzarote Airport",
        "city": "Lanzarote",
        "country": "Spain"
    },
    {
        "iata": "ADD",
        "airport": "Bole International Airport",
        "city": "Addis Ababa",
        "country": "Ethiopia"
    },
    {
        "iata": "ALG",
        "airport": "Houari Boumediene Airport",
        "city": "Algiers",
        "country": "Algeria"
    },
    {
        "iata": "AMS",
        "airport": "Amsterdam Airport Schiphol",
        "city": "Amsterdam",
        "country": "Netherlands"
    },
    {
        "iata": "ARK",
        "airport": "Arusha Airport",
        "city": "Arusha",
        "country": "Tanzania"
    },
    {
        "iata": "ATL",
        "airport": "Hartsfield-Jackson Atlanta International Airport",
        "city": "Atlanta",
        "country": "USA"
    },
    {
        "iata": "BKK",
        "airport": "Suvarnabhumi Airport",
        "city": "Racha Thewa",
        "country": "Thailand"
    },
    {
        "iata": "BKZ",
        "airport": "Bukoba Airport",
        "city": "Bukoba",
        "country": "Tanzania"
    },
    {
        "iata": "CAI",
        "airport": "Cairo International Airport",
        "city": "Cairo",
        "country": "Egypt"
    },
    {
        "iata": "CAN",
        "airport": "Guangzhou Baiyun International Airport",
        "city": "Guangzhou",
        "country": "China"
    },
    {
        "iata": "CDG",
        "airport": "Charles de Gaulle International Airport",
        "city": "Paris",
        "country": "France"
    },
    {
        "iata": "CGK",
        "airport": "Soekarno-Hatta International Airport",
        "city": "Tangerang",
        "country": "Indonesia"
    },
    {
        "iata": "CLT",
        "airport": "Charlotte/Douglas International Airport",
        "city": "North Carolina",
        "country": "USA"
    },
    {
        "iata": "CMN",
        "airport": "Mohammed V International Airport",
        "city": "Casablanca",
        "country": "Morocco"
    },
    {
        "iata": "CPT",
        "airport": "Cape Town International Airport",
        "city": "Cape Town",
        "country": "South Africa"
    },
    {
        "iata": "DAR",
        "airport": "Julius Nyerere International Airport",
        "city": "Dar es Salaam",
        "country": "Tanzania"
    },
    {
        "iata": "DEN",
        "airport": "Denver International Airport",
        "city": "Denver",
        "country": "USA"
    },
    {
        "iata": "DFW",
        "airport": "Dallas-Fort Worth International Airport",
        "city": "Dallas",
        "country": "USA"
    },
    {
        "iata": "DOH",
        "airport": "Hamad International Airport",
        "city": "Doha",
        "country": "Qatar"
    },
    {
        "iata": "DUR",
        "airport": "King Shaka International Airport",
        "city": "Durban",
        "country": "South Africa"
    },
    {
        "iata": "DXB",
        "airport": "Dubai International Airport",
        "city": "Dubai",
        "country": "Dubai"
    },
    {
        "iata": "EBB",
        "airport": "Entebbe International Airport",
        "city": "Entebbe",
        "country": "Uganda"
    },
    {
        "iata": "FNC",
        "airport": "Madeira Airport",
        "city": "Santa Cruz, Madeira",
        "country": "Portugal"
    },
    {
        "iata": "FRA",
        "airport": "Frankfurt Airport",
        "city": "Frankfurt",
        "country": "Germany"
    },
    {
        "iata": "FUE",
        "airport": "Fuerteventura Airport",
        "city": "Puerto del Rosario",
        "country": "Spain"
    },
    {
        "iata": "HKG",
        "airport": "Hong Kong International Airport",
        "city": "Hong Kong",
        "country": "China"
    },
    {
        "iata": "HND",
        "airport": "Tokyo International Airport",
        "city": "Tokyo",
        "country": "Japan"
    },
    {
        "iata": "HRG",
        "airport": "Hurghada International Airport",
        "city": "Hurghada",
        "country": "Egypt"
    },
    {
        "iata": "IAH",
        "airport": "George Bush Intercontinental Airport",
        "city": "Texas",
        "country": "USA"
    },
    {
        "iata": "ICN",
        "airport": "Incheon International Airport",
        "city": "Incheon",
        "country": "South Korea"
    },
    {
        "iata": "IST",
        "airport": "Istanbul Airport",
        "city": "Istanbul",
        "country": "Turkey"
    },
    {
        "iata": "JFK",
        "airport": "John F. Kennedy International Airport",
        "city": "New York",
        "country": "USA"
    },
    {
        "iata": "JNB",
        "airport": "OR Tambo International Airport",
        "city": "Johannesburg",
        "country": "South Africa"
    },
    {
        "iata": "JRO",
        "airport": "Kilimanjaro International Airport",
        "city": "Kilimanjaro",
        "country": "Tanzania"
    },
    {
        "iata": "KUL",
        "airport": "Kuala Lumpur International Airport",
        "city": "Kuala Lumpur",
        "country": "Malaysia"
    },
    {
        "iata": "LAS",
        "airport": "Harry Reid International Airport",
        "city": "Las Vegas",
        "country": "USA"
    },
    {
        "iata": "LAX",
        "airport": "Los Angeles International Airport",
        "city": "Los Angeles",
        "country": "USA"
    },
    {
        "iata": "LHR",
        "airport": "London Heathrow Airport",
        "city": "London",
        "country": "United Kingdom"
    },
    {
        "iata": "LOS",
        "airport": "Murtala Muhammed International Airport",
        "city": "Lagos",
        "country": "Nigeria"
    },
    {
        "iata": "LPA",
        "airport": "Gran Canaria Airport",
        "city": "Gran Canaria",
        "country": "Spain"
    },
    {
        "iata": "MAD",
        "airport": "Madrid–Barajas Airport",
        "city": "Madrid",
        "country": "Spain"
    },
    {
        "iata": "MBA",
        "airport": "Moi International Airport",
        "city": "Mombasa",
        "country": "Kenya"
    },
    {
        "iata": "MEW",
        "airport": "Mweka Airport",
        "city": "Mweka",
        "country": "DR Congo"
    },
    {
        "iata": "MIA",
        "airport": "Miami International Airport",
        "city": "Miami",
        "country": "USA"
    },
    {
        "iata": "MUC",
        "airport": "Munich Airport",
        "city": "Munich",
        "country": "Germany"
    },
    {
        "iata": "MWN",
        "airport": "Mwadui Airport",
        "city": "Shinyanga",
        "country": "Tanzania"
    },
    {
        "iata": "MWZ",
        "airport": "Mwanza International Airport",
        "city": "Mwanza",
        "country": "Tanzania"
    },
    {
        "iata": "NBO",
        "airport": "Jomo Kenyatta International Airport",
        "city": "Nairobi",
        "country": "Kenya"
    },
    {
        "iata": "ORD",
        "airport": "Chicago O'Hare International Airport",
        "city": "Chicago",
        "country": "USA"
    },
    {
        "iata": "PEK",
        "airport": "Beijing Capital International Airport",
        "city": "Beijing",
        "country": "China"
    },
    {
        "iata": "PHX",
        "airport": "Sky Harbor International Airport",
        "city": "Arizona",
        "country": "USA"
    },
    {
        "iata": "PKX",
        "airport": "Beijing Daxing International Airport",
        "city": "Beijing",
        "country": "China"
    },
    {
        "iata": "PVG",
        "airport": "Shanghai Pudong International Airport",
        "city": "Shanghai",
        "country": "China"
    },
    {
        "iata": "RAK",
        "airport": "Marrakesh Menara Airport",
        "city": "Marrakesh",
        "country": "Morocco"
    },
    {
        "iata": "SFO",
        "airport": "San Francisco International Airport",
        "city": "San Francisco",
        "country": "USA"
    },
    {
        "iata": "SIN",
        "airport": "Singapore Changi Airport",
        "city": "Singapore",
        "country": "Singapore"
    },
    {
        "iata": "SSH",
        "airport": "Sharm el-Sheikh International Airport",
        "city": "Sharm el-Sheikh",
        "country": "Egypt"
    },
    {
        "iata": "TFN",
        "airport": "Tenerife North Airport",
        "city": "San Cristóbal de La Laguna",
        "country": "Spain"
    },
    {
        "iata": "TFS",
        "airport": "Tenerife South Airport",
        "city": "Santa Cruz de Tenerife",
        "country": "Spain"
    },
    {
        "iata": "TKQ",
        "airport": "Kigoma Airport",
        "city": "Kigoma",
        "country": "Tanzania"
    },
    {
        "iata": "TUN",
        "airport": "Tunis–Carthage International Airport",
        "city": "Tunis",
        "country": "Tunisia"
    },
    {
        "iata": "ZNZ",
        "airport": "Abeid Amani Karume International Airport",
        "city": "Zanzibar",
        "country": "Tanzania"
    },
    {
        "iata": "ZRH",
        "airport": "Zurich Airport",
        "city": "Zurich",
        "country": "Switzerland"
    }
]

export { airports, popularAirports }