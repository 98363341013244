import React from 'react'

function Semi({text, color, fontSize}) {
  return (
    <span 
        style={{
            padding: 5,
            margin: 2,
            fontFamily: "Euclid-Medium",
            fontSize: fontSize ? fontSize : 14, color: color
        }}
    > 
        {text} 
    </span>
  )
}

export default Semi