import * as React from "react";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/system";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

const NumberInput = React.forwardRef(function CustomNumberInput(props, ref) {
    
    const inputStyle = {
        minWidth: "-webkit-fill-available",
        textAlign: "center!important"
    };
    
    return (
        <TextField
            type="number"
            style={inputStyle}
            InputProps={{
                style: inputStyle,
                inputProps: { min: 0, max: 9 },
                startAdornment: (
                <StyledButton className="decrement" onClick={props.onDecrement}>
                    <RemoveIcon fontSize="small" />
                </StyledButton>
                ),
                endAdornment: (
                <StyledButton className="increment" onClick={props.onIncrement}>
                    <AddIcon fontSize="small" />
                </StyledButton>
                ),
            }}
            {...props}
            ref={ref}
        />
    );
});

export default NumberInput;

const StyledButton = styled("button")(
  ({ theme }) => `
  /* Your button styles */
`
);
