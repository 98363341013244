import React, { useState, useEffect } from "react";
import Nav from "./../components/Main/Nav";
import BusForm from "./../components/Forms/BusForm";
import DateTabs from "./../components/Main/DateTabx";
import FooterNav from "../components/Main/FooterNav";

function BusListings() {
  return (
    <div style={styles.container}>
      {/* Navigation */}
      <Nav colored={true} />

      {/* Tabs switcher */}

      <div className="flex flex-col justify-center items-center">
        <BusForm />
        <DateTabs />
        <FooterNav />
      </div>
    </div>
  );
}

export default BusListings;

const styles = {
  container: {
    // backgroundColor: 'red',
  },

  tabs: {
    position: "absolute",
    top: 85,
  },
};
