import React from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  foreignInput: {
    padding: 10,
    paddingLeft: 15,
    borderRadius: 15,
    background: "#FAF7F0",
    border: "1px solid #E5E5E5",
    outline: "none",
    margin: 5,
    width: "50%",
    fontFamily: "Euclid-Bold",
    color: "#555",
    fontSize: 15,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginBottom: "5%",
    },
  },

  foreignInputSelect: {
    padding: 10,
    paddingLeft: 15,
    borderRadius: 20,
    background: "#2F9F48",
    border: "1px solid #E5E5E5",
    outline: "none",
    margin: 5,
    width: "55%",
    fontFamily: "Euclid-Bold",
    color: "#555",
    fonSize: 20,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginBottom: "5%",
    },
  },
}));

function FInput({
  type,
  placeholder,
  disabled,
  onfocus,
  onblur,
  onChange,
  autofocus,
  width,
  value,
  name,
  id,
  required,
  isSelect,
  options,
}) {
  const classes = useStyles();

  if (isSelect) {
    return (
      //select
      <div>
        <select
          disabled={disabled}
          onFocus={onfocus}
          onBlur={onblur}
          // className={classes.foreignInputSelect}
          class="rounded-lg border-gray-300 p-4 pr-12 text-sm shadow-sm my-1"
          onChange={onChange}
          autoFocus={autofocus}
          required={required}
          name={name}
          id={id}
          style={{ width, fontFamily: "Euclid-Regular" }}
        >
          <option value="" disabled selected>
            {" "}
            {value ? value : placeholder}{" "}
          </option>

          {options?.map((option, index) => {
            if (option.value) {
              return (
                <option key={index} value={option.value}>
                  {option.value}
                </option>
              );
            } else if (option.location) {
              return (
                <option key={index} value={option.location}>
                  {option.location}
                </option>
              );
            }
          })}
        </select>
      </div>
    );
  } else {
    return (
      <input
        type={type}
        class="rounded-lg border-gray-300 p-4 pr-12 text-sm shadow-sm my-1"
        placeholder={placeholder}
        disabled={disabled}
        onFocus={onfocus}
        onBlur={onblur}
        onChange={onChange}
        name={name}
        id={id}
        value={value}
        required={required}
        style={{ width, fontFamily: "Euclid-Regular" }}
      />
      // <input
      //   type={type}
      //   placeholder={placeholder}
      //   disabled={disabled}
      //   className={classes.foreignInput}
      //   onFocus={onfocus}
      //   onBlur={onblur}
      //   onChange={onChange}
      //   name={name}
      //   id={id}
      //   value={value}
      //   required={required}
      // ></input>
    );
  }
}

export default FInput;

const styles = {
  padding: 10,
  paddingLeft: 15,
  borderRadius: 20,
  background: "#FAF7F0",
  border: "1px solid #E5E5E5",
  outline: "none",
  margin: 5,
  fontFamily: "Euclid-Bold",
  color: "#2BA147",
  fonSize: 20,
};
