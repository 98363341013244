import React, { useState, useEffect } from "react";
import Nav from "../components/Main/Nav";
import EventForm from "../components/Forms/EventForm";
import EventsView from "../components/Main/EventOptions";
import { useParams } from "react-router-dom";
import EntertainmentInfo from "../components/Main/EntertainmentInfo";
import FooterNav from "../components/Main/FooterNav";

function EntertainmentView() {

  const { entertainmentID } = useParams()
  return (
    <div style={styles.container}>
      {/* Navigation */}
      <Nav colored={true} />
      <EntertainmentInfo id={entertainmentID} />  
      <FooterNav />
    </div>
  );
}

export default EntertainmentView;

const styles = {
  container: {
    // backgroundColor: 'red',
  },

  tabs: {
    position: "absolute",
    top: 85,
  },
};
