/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  basic: {
    padding: 20,
    borderRadius: 15,
    background: "#2F9F48",
    border: "none",
    margin: 5,
    fontFamily: "Euclid-Bold",
    color: "#fff",
    boxShadow: "0px 1px 9px rgba(0, 0, 0, 0.07)",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "60vw",
    },
  },
}));

function Submit({ text, action, iconName, pathTo, size, inverse }) {
  const classes = useStyles();

  if (pathTo) {
    return (
      <Link to={pathTo}>
        <button
          type="submit"
          className={`block rounded-md font-medium transition hover:bg-transparent hover:text-[#2BA147] hover:border hover:border-[#2BA147] w-full 
                      ${size === "small" ? 'px-6 py-4 text-sm' : size === "narrow" ? 'px-6 py-1 text-xs' : 'px-8 py-4 text-lg '}
                      ${inverse ? 'text-[#2BA147] bg-[#DFF6E4] ' : 'text-white bg-[#2BA147] '}`}
          onClick={action}
          style={{ fontFamily: "Euclid-Bold" }}
        >
          {text}{" "} <FontAwesomeIcon icon={iconName} />
          {/* <FontAwesomeIcon icon={faArrowRight} style={{ marginLeft: 25 }} /> */}
        </button>
      </Link>
    );
  } else {
    return (
      <button 
      className={`block rounded-md font-medium transition hover:bg-transparent hover:text-[#2BA147] hover:border hover:border-[#2BA147] w-full 
                  ${size === "small" ? 'px-6 py-4 text-sm' : size === "narrow" ? 'px-6 py-1 text-xs' : 'px-8 py-4 text-lg '}
                  ${inverse ? 'text-[#2BA147] bg-[#DFF6E4] ' : 'text-white bg-[#2BA147] '}`}
      onClick={action}>
        {text}{" "}
        <FontAwesomeIcon icon={iconName} />
      </button>
    );
  }
}

export default Submit;

const styles = {
  padding: 20,
  borderRadius: 50,
  background: "#2BA147",
  border: "none",
  margin: 5,
  fontFamily: "Euclid-Bold",
  color: "#fff",
  boxShadow: "0px 1px 9px rgba(0, 0, 0, 0.07)",
};
