// a component to render a bus listing in the main page
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from "@material-ui/core/styles";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import PlaceIcon from '@mui/icons-material/Place';
import ListIcon from '@mui/icons-material/ListAlt';
import InstagramIcon from '@mui/icons-material/Instagram';
import Typography from '@mui/material/Typography';
import { useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Primary from "../Buttons/Primary";
import Secondary from "../Buttons/Secondary";
import Bold from "../Text/Bold";
import Semi from "../Text/Semi";
import Light from "../Text/Light";
import Modal from "@mui/material/Modal";
import ActionFooter from "./ActionFooter";
import Checkout from "./Checkout";

import { useDispatch, useSelector } from "react-redux";
import {
  setHomeBg,
  selectCheckoutDetails,
  setCheckoutDetails,
  selectAvailableEntertainment,
  selectEntertainmentForm,
} from "../../slices/NavSlice";
import { NumberFormatter } from "../fx/fx";
import moment from "moment/moment";
import { Dialog, DialogActions, DialogContent, Paper } from "@material-ui/core";
import Regular from "../Text/Regular";
import { CardActionArea } from "@mui/material";
import Submit from "../Buttons/Submit";
import parse from 'html-react-parser';
import ClockIcon from '@mui/icons-material/AccessTime';
import MapIcon from '@mui/icons-material/Map';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "60%",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 20,
    boxShadow: "0px 1px 9px rgba(0, 0, 0, 0.07)",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "80%",
    },
    margin: 15,
    padding: 15,
  },
  rootMenu: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    width: "80%",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 20,
    flexDirection: "column",
    boxShadow: "0px 1px 9px rgba(0, 0, 0, 0.07)",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "80%",
      transform: "translate(-53%, -50%)",
    },
    padding: 15,
  },
  details: {
    width: "80%",
    margin: 10,
    padding: 5,
    textAlign: 'left',
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: 0,
    },
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    height: 150,
    width: 200,
    paddingRight: 10,
    borderRadius: 10,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: 0,
      height: 150,
      borderRadius: 10,
    },
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
}));

function EntertainmentListing() {
  //declare lugha
  const lugha = JSON.parse(window.sessionStorage.getItem("lugha"));
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const availableEntertainment = useSelector(selectAvailableEntertainment);
  // console.log(availableEntertainment)

  const [showPackages, setShowPackages] = useState();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const entertainmentGlobalState = useSelector(selectEntertainmentForm);
  const checkoutDetails = useSelector(selectCheckoutDetails);

  // state to store total menu price for items in a particular venue
  const [totalEntertainmentPrice, setTotalEntertainmentPrice] = useState(0);
  const [itemsCount, setItemsCount] = useState([]);
  const centerize = { display: 'flex', alignItems: 'center', flexDirection: 'column' }

  // checkout toggle
  const [checkoutForm, setCheckoutForm] = useState(false);

  // useEffect total price for all items in itemsCount
  useEffect(() => {
    let total = 0;
    itemsCount.forEach((item) => {
      console.log(item)
      total += parseInt(item.package.price) * parseInt(item.quantity);
    });
    setTotalEntertainmentPrice(total);
  }, [itemsCount]);

  const CartCount = ({ item, entertainmentID }) => {
    var count = 0;
    itemsCount.map((thing) => {
      if (thing.package.packageID === item.packageID && thing.package.entertainmentID === entertainmentID) {
        count++;
        return count;
      }
    });

    return <Semi text={count} />;
  };

  // delete item from itemsCount array
  const deleteItem = (item, entertainmentID) => {
    const newItemsCount = itemsCount.filter((thing) => {
      return thing.package.packageID == item.packageID && thing.entertainmentID == entertainmentID;
    });

    const index = itemsCount.indexOf(newItemsCount[0]);
    itemsCount.splice(index, 1);
    setItemsCount([...itemsCount]);
  };

  return (
    <>
      <div
        style={{
          ...centerize,
          justifyContent: "space-between",
          marginBottom: 25,
          // paddingTop: '2rem',
        }}
      >
        <Grid container spacing={2}>
          {availableEntertainment && availableEntertainment.length ?
            availableEntertainment.map((entertainment, id) => {
              const { entertainmentID, entertainment: entertainmentName, packages, squarePath, address, region, start, end } = entertainment
                          
              var description = entertainment.description
              if(lugha.lugha === 'swahili'){
                description = entertainment.maelezo
              }

              return (
                <Grid item xs={12} sm={6}>
                  <>
                    <Card>
                      <CardMedia
                        sx={{ height: 140 }}
                        image={"https://tiketika.online/img/entertainment/" + squarePath}
                        title="Entertainment Poster"
                      />
                      <CardContent>
                        <Bold text={entertainmentName} fontSize={'1.2rem'} />

                        <div className={classes.details}>
                          <MapIcon style={{color: '#2BA147'}} /><Light text={address} fontSize={'.8rem'} /> <br />
                          <ClockIcon style={{color: '#2BA147'}} /><Light text={start +' - '+ end} fontSize={'.8rem'} /> <br />
                        </div>

                        {/* <Regular text={parse(description.substring(0, 100) + '...')} color={'#000'} /> */}
                        
                      </CardContent>
                      <CardActions style={{ justifyContent: "right", }}>
                          <Submit
                            text={<><Semi text={lugha.reservation} /></>} //<ListIcon /> 
                            size={'small'}
                            inverse
                            id={entertainmentID}
                            action={() => {
                              // setShowPackages(entertainmentID);
                            }}
                            pathTo={"/hot-spot/" + entertainmentID}
                          />
                      </CardActions>
                    </Card>

                    {parseInt(showPackages) === parseInt(entertainmentID) && (
                      <Dialog
                          open={true}
                          onClose={handleClose}
                          aria-labelledby="dialog-title"
                          aria-describedby="dialog-description"
                          maxWidth="lg"
                          fullWidth
                      >
                        <DialogContent>
                          {/* List the package items grouped by type */}
                          <Grid container spacing={1}>
                          {/* Total menu price */}
                            <Grid item xs={12} sm={3} />
                            <Grid item xs={12} sm={6}>
                              <div style={{ textAlign:'center' }}>
                                <Bold text={lugha.total} fontSize={'1.2rem'} />
                                <br />
                                <Semi text={"Tsh. " + NumberFormatter(totalEntertainmentPrice)} />
                              </div>
                              <div style={styles.separator}></div>

                            </Grid>
                            <Grid item xs={12} sm={3} />

                            <Grid item xs={12} sm={6} md={3}>
                              <Paper elevation={2} style={{ padding: 12 }}>
                                {packages.filter(pack => pack.packageTypeID === '1').map((item, i) => {
                                  var price = item.price;
                                  var description = item.description;
                                  var type = item.packageType
                                  if(lugha.lugha === 'swahili'){
                                    description = item.maelezo;
                                    type = item.ainaYaBurudani
                                  }

                                  return (
                                    <>
                                      {i === 0 ? 
                                        <Bold color={'#2BA147'} text={type} />
                                      :
                                      ''
                                      }
                                      <Grid container>
                                        <Grid item xs={6} md={6}>
                                          <div>
                                            <Bold text={item.package} /><br />
                                            <Semi text={'Tsh. '+ NumberFormatter(price)} />
                                            {/* <Semi text={lugha.price +': Tsh. '+ NumberFormatter(price)} /> */}
                                          </div>
                                        </Grid>

                                        <Grid item xs={6} md={6}>
                                          {/* Cart Counter */}
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              justifyContent: "flex-end",
                                              alignItems: "center",
                                            }}
                                          >
                                            <Primary
                                              text={"-"}
                                              action={() => {
                                                deleteItem(item, entertainmentID);
                                              }}
                                            />

                                            <CartCount item={item} entertainmentID={entertainmentID} />

                                            <Primary
                                              text={"+"}
                                              action={() => {
                                                // add item to items array

                                                var newItem = {
                                                  package: item,
                                                  quantity: 1,
                                                  // entertainmentID: entertainmentID,
                                                  // price: price,
                                                };

                                                setItemsCount([...itemsCount, newItem]);
                                              }}
                                            />
                                          </div>
                                        </Grid>
                                        
                                        <Grid item xs={12} md={12}>
                                            <Regular text={description} color={'#000'} />
                                        </Grid>

                                      <div style={styles.separator}></div>
                                      </Grid>
                                    </>
                                  );
                                })}
                              </Paper>
                            </Grid>

                            <Grid item xs={12} sm={6} md={3}>
                              <Paper elevation={2} style={{ padding: 12 }}>
                                {packages.filter(pack => pack.packageTypeID === '2').map((item, i) => {
                                  var price = item.price;
                                  var description = item.description;
                                  var type = item.packageType
                                  if(lugha.lugha === 'swahili'){
                                    description = item.maelezo;
                                    type = item.ainaYaBurudani
                                  }

                                  return (
                                    <>
                                      {i === 0 ? 
                                        <Bold color={'#2BA147'} text={type} />
                                      :
                                      ''
                                      }
                                      <Grid container>
                                        <Grid item xs={6} md={6}>
                                          <div>
                                            <Bold text={item.package} /><br />
                                            <Semi text={'Tsh. '+ NumberFormatter(price)} />
                                            {/* <Semi text={lugha.price +': Tsh. '+ NumberFormatter(price)} /> */}
                                          </div>
                                        </Grid>

                                        <Grid item xs={6} md={6}>
                                          {/* Cart Counter */}
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              justifyContent: "flex-end",
                                              alignItems: "center",
                                            }}
                                          >
                                            <Primary
                                              text={"-"}
                                              action={() => {
                                                deleteItem(item, entertainmentID);
                                              }}
                                            />

                                            <CartCount item={item} entertainmentID={entertainmentID} />

                                            <Primary
                                              text={"+"}
                                              action={() => {
                                                // add item to items array

                                                var newItem = {
                                                  package: item,
                                                  quantity: 1,
                                                  // entertainmentID: entertainmentID,
                                                  // price: price,
                                                };

                                                setItemsCount([...itemsCount, newItem]);
                                              }}
                                            />
                                          </div>
                                        </Grid>

                                      <div style={styles.separator}></div>
                                      </Grid>
                                    </>
                                  );
                                })}
                              </Paper>
                            </Grid>

                            <Grid item xs={12} sm={6} md={3}>
                              <Paper elevation={2} style={{ padding: 12 }}>
                                {packages.filter(pack => pack.packageTypeID === '3').map((item, i) => {
                                  var price = item.price;
                                  var description = item.description;
                                  var type = item.packageType
                                  if(lugha.lugha === 'swahili'){
                                    description = item.maelezo;
                                    type = item.ainaYaBurudani
                                  }

                                  return (
                                    <>
                                      {i === 0 ? 
                                        <Bold color={'#2BA147'} text={type} />
                                      :
                                      ''
                                      }
                                      <Grid container>
                                        <Grid item xs={6} md={6}>
                                          <div>
                                            <Bold text={item.package} /><br />
                                            <Semi text={'Tsh. '+ NumberFormatter(price)} />
                                            {/* <Semi text={lugha.price +': Tsh. '+ NumberFormatter(price)} /> */}
                                          </div>
                                        </Grid>

                                        <Grid item xs={6} md={6}>
                                          {/* Cart Counter */}
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              justifyContent: "flex-end",
                                              alignItems: "center",
                                            }}
                                          >
                                            <Primary
                                              text={"-"}
                                              action={() => {
                                                deleteItem(item, entertainmentID);
                                              }}
                                            />

                                            <CartCount item={item} entertainmentID={entertainmentID} />

                                            <Primary
                                              text={"+"}
                                              action={() => {
                                                // add item to items array

                                                var newItem = {
                                                  package: item,
                                                  quantity: 1,
                                                  // entertainmentID: entertainmentID,
                                                  // price: price,
                                                };

                                                setItemsCount([...itemsCount, newItem]);
                                              }}
                                            />
                                          </div>
                                        </Grid>

                                      <div style={styles.separator}></div>
                                      </Grid>
                                    </>
                                  );
                                })}
                              </Paper>
                            </Grid>

                            <Grid item xs={12} sm={6} md={3}>
                              <Paper elevation={2} style={{ padding: 12 }}>
                                {packages.filter(pack => pack.packageTypeID === '4').map((item, i) => {
                                  var price = item.price;
                                  var description = item.description;
                                  var type = item.packageType
                                  if(lugha.lugha === 'swahili'){
                                    description = item.maelezo;
                                    type = item.ainaYaBurudani
                                  }

                                  return (
                                    <>
                                      {i === 0 ? 
                                        <Bold color={'#2BA147'} text={type} />
                                      :
                                      ''
                                      }
                                      <Grid container>
                                        <Grid item xs={6} md={6}>
                                          <div>
                                            <Bold text={item.package} /><br />
                                            <Semi text={'Tsh. '+ NumberFormatter(price)} />
                                            {/* <Semi text={lugha.price +': Tsh. '+ NumberFormatter(price)} /> */}
                                          </div>
                                        </Grid>

                                        <Grid item xs={6} md={6}>
                                          {/* Cart Counter */}
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              justifyContent: "flex-end",
                                              alignItems: "center",
                                            }}
                                          >
                                            <Primary
                                              text={"-"}
                                              action={() => {
                                                deleteItem(item, entertainmentID);
                                              }}
                                            />

                                            <CartCount item={item} entertainmentID={entertainmentID} />

                                            <Primary
                                              text={"+"}
                                              action={() => {
                                                // add item to items array

                                                var newItem = {
                                                  package: item,
                                                  quantity: 1,
                                                  // entertainmentID: entertainmentID,
                                                  // price: price,
                                                };

                                                setItemsCount([...itemsCount, newItem]);
                                              }}
                                            />
                                          </div>
                                        </Grid>

                                      <div style={styles.separator}></div>
                                      </Grid>
                                    </>
                                  );
                                })}
                              </Paper>
                            </Grid>
                          </Grid>
                          <DialogActions>
                            <Secondary
                              text="Close"
                              action={() => {
                                setShowPackages(0);
                              }}
                            />
                          </DialogActions>
                        </DialogContent>
                      </Dialog>
                    )}
                  </>
                </Grid>
              );
            })
          : 
            (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  height: 300,
                }}
              >
                <Bold text="No Entertainment Available" fontSize={20} />
              </div>
            )
          }
        </Grid>
      </div>

      {itemsCount.length > 0 && (
        <ActionFooter
          text={lugha.checkout}
          clickAction={() => setCheckoutForm(true)}
        />
      )}

      {checkoutForm && (
        <Checkout
          total={totalEntertainmentPrice}
          text={lugha.payNow}
          type='entertainment'
          currency='Tsh'
          closeAction={() => {
            // dispatch(setCheckoutDetails([]));
            setCheckoutForm(false);
          }}
          items={itemsCount}
          action={(e) => {
            e.preventDefault();
          }}
        />
      )}
    </>
  );
}

export default EntertainmentListing;

const styles = {
  card: {
    width: 300,
    margin: 80,
    boxShadow: "0px 1px 9px rgba(0, 0, 0, 0.07)",
    display: "flex",
    justifyContent: "center",
  },
  eventDetails: {
    textAlign: "left",
    alignItems: "left",
    flex: 2,
  },
  separator: {
    width: "100%",
    height: 1,
    backgroundColor: "#e0e0e0",
    margin: 10,
  },
  priceDetails: {
    padding: 20,
    backgroundColor: "#f5f5f5",
    textAlign: "center",
    width: "20%",
    borderRadius: 30,
  },

  anem: {
    display: "flex",
    alignItems: "center",
    margin: 5,
  },

  price: {
    fontSize: 30,
    fontWeight: "bold",
    color: "#2BA147",
  },
};
