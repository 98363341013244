import React, { useState, useEffect } from "react";
import Nav from "../components/Main/Nav";
import TourForm from "../components/Forms/TourForm";
import TourOptions from "../components/Main/TourOptions";
import FooterNav from "../components/Main/FooterNav";

function TourListings() {
  return (
    <div style={styles.container}>
      {/* Navigation */}
      <Nav colored={true} />

      <div className="flex flex-col justify-center items-center">
        <TourForm />
        <TourOptions />
        <FooterNav />
      </div>
    </div>
  );
}

export default TourListings;

const styles = {
  container: {
    // backgroundColor: 'red',
  },

  tabs: {
    position: "absolute",
    top: 85,
  },
};
