import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  basic: {
    padding: 15,
    borderRadius: 15,
    width: 209,
    border: "none",
    outline: "none",
    margin: "5px 8px",
    fontFamily: "Euclid-Bold",
    boxShadow: "0px 1px 9px rgba(0, 0, 0, 0.24)",
    fontSize: 15,
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "65vw",
    },
  },
}));

function LocationInput({
  options,
  placeholder,
  disabled,
  onfocus,
  onblur,
  onchange,
  autofocus,
  value,
  required,
  colored,
}) {
  const classes = useStyles();
  let location = options.find((loc) => parseInt(loc.locationID) === parseInt(value));

  return (
    <div className="">
      <label>
        <p
          style={{
            fontSize: 12,
            textAlign: "left",
            marginBottom: 2,
            paddingLeft: 2,
            fontFamily: "Euclid-Bold",
            color: colored ? "#fff" : "#2BA147",
          }}
          className="pt-3 pb-1"
        >
          {placeholder}
        </p>
        <select
          disabled={disabled}
          onFocus={onfocus}
          onBlur={onblur}
          // className={classes.basic}
          className="py-4 px-6 border-none rounded-xl w-full outline-none text-sm"
          onChange={onchange}
          autoFocus={autofocus}
          required={required}
          style={{
            color: colored ? "#fff" : "#2BA147",
            background: colored ? "#252525" : "#fff",
            fontFamily: "Euclid-Bold",
            boxShadow: "none",
          }}
        >
          <option value="" disabled selected>
            {location ? location.location : placeholder}
          </option>
          {options?.map((option, index) => {
            return (
              <option key={index} value={option.locationID}>
                {option.location}
              </option>
            );
          })}
        </select>
      </label>
    </div>
  );
}

export default LocationInput;

const styles = {
  select: {
    padding: 20,
    borderRadius: 50,
    width: 180,
    background: "#fff",
    border: "none",
    outline: "none",
    margin: 5,
    fontFamily: "Euclid-Bold",
    color: "#2BA147",
    boxShadow: "0px 1px 9px rgba(0, 0, 0, 0.07)",
    fontSize: 20,
  },
};
